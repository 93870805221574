import { useState, createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  Node,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useUpdateNodeInternals,
} from "react-flow-renderer";
import {
  resetAiInitiation,
  resetNodesLoaderState,
  setCurrentPage,
} from "store/actions/targetingAI";
import TargetAIPlaygroundBeta from ".";
import { getSearchParams } from "utils/commonFunctions";
import { setActiveBrand, setBrandRoles } from "store/actions/adaccount";
import { mixPanelAdEvent } from "utils/mixpanel";
import { AdAccountState } from "store/types/adaccount";
import {
  FB_EDGES_LIST,
  FB_NODES_LIST,
  FB_NODE_TYPES,
  changePosition as changePositionFacebook,
  resolveDimentionToPixel,
} from "./utils/facebook";
import {
  GS_EDGES_LIST,
  GS_NODES_LIST,
  GS_NODE_TYPES,
} from "./utils/googleSearch";

import AIInitiationNode from "./ReactFlowComponents/AIInitiationNode";
import {
  getBrandAdAccounts,
  setSelectedBrandAdaccounts,
} from "../../store/actions/adaccount";
import { useAuth0 } from "@auth0/auth0-react";
import { getAgency, setSelectedAgency } from "store/actions/common";
import { TAIGSearchMixPanel } from "./GoogleSearch/utils";
import useFocusToNode from "./useFocusToNode";
import { cloneDeep, max } from "lodash";
import { getMyRolesAPI } from "Pages/ManageAgency/UserRoles/API/getMyRoles";

export const TaiContext = createContext({
  popoverFlag: false,
  setPopoverFlag: () => {},
  modalProps: {},
  setModalProps: () => {},
}) as any;

export function TaiContextProviderInner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { user } = useAuth0();
  const { selectedBrand, brands } = adaccount;
  const [selectedPlatform, setSelectedPlatform] = useState("Select Platform");

  const currentPage = useSelector((state: any) => state.taiCommon.currentPage);
  const [custom, setCustom] = useState([]);
  const [popoverFlag, setPopoverFlag] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [keywordsJsonTai, setKeywordsJsonTai] = useState(null);
  const [aiGroupSelection, setAIGroupSelection] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [publishModalData, setPublishModalData] = useState<any>([]);
  const [nodeTypes, setNodeTypes] = useState({
    aiInitiationNode: AIInitiationNode,
  });
  const [edgesList, setEdgesList] = useState<any>([]);
  const [callibrationData, updateCalibrationData] = useState(true);
  const [cohortType, setCohortType] = useState("INTEREST");
  const focusToNode = useFocusToNode();
  const reactFlow = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const [nodesList, setNodesList] = useState<any>({
    aiInitiationParent: {
      id: "aiInitiationParent",
      type: "input",
      data: { page: 1 },
      position: { x: 20, y: 100 },
      className: "light",
      style: {
        display: "flex",
        width: 296,
        height: 518,
        border: "1px solid #DDDDDD",
        borderRadius: "0.625rem",
        paddingLeft: "20px",
      },
      sourcePosition: "right",
      draggable: false,
    },
    aiInitiation: {
      id: "aiInitiation",
      type: "aiInitiationNode",
      position: { x: 10, y: 0 },
      data: {
        index: 1,
        page: 1,
        position: aiGroupSelection ? { x: 80, y: 240 } : "",
        id: "aiInitiation",
        onNext: () => {
          if (window.location.pathname.includes("googlesearch")) {
            setNodeTypes({ ...nodeTypes, ...GS_NODE_TYPES });
            setNodesList({
              ...nodesList,
              ...GS_NODES_LIST,
            });
            setEdgesList(GS_EDGES_LIST);
            setNodes([
              nodesList["aiInitiationParent"],
              nodesList["aiInitiation"],
              GS_NODES_LIST["loaderCalibrationExpanded"],
            ]);
            setEdges([GS_EDGES_LIST["aiGroupToLoaderCalibrationExpanded"]]);
            setAIGroupSelection(true);
          } else if (window.location.pathname.includes("facebook")) {
            setNodeTypes({ ...nodeTypes, ...FB_NODE_TYPES });
            setNodesList({
              ...nodesList,
              ...FB_NODES_LIST,
            });
            setEdgesList(FB_EDGES_LIST);
            setNodes([
              nodesList["aiInitiationParent"],
              nodesList["aiInitiation"],
              FB_NODES_LIST["loaderExpanded"],
            ]);
            setEdges([FB_EDGES_LIST["aiGroupToLoaderExpanded"]]);
            setAIGroupSelection(true);
            setCohortType("INTEREST");
            dispatch(resetNodesLoaderState());
            changePositionFacebook(1, setNodes, {} as any);
            focusToNode("loaderExpanded");
          }
        },
      },
      parentNode: "aiInitiationParent",
      draggable: false,
      style: {
        width: 280,
        justifyContent: "center",
        alignItems: "center",
      },
      connectable: "false",
    },
  });
  const [checkedCohorts, setCheckedCohorts] = useState([]);
  const [shownClusterIDs, setShownClusterIDs] = useState([]);
  const [shownClusterData, setShownClusterData] = useState([]);
  const [showMerged, setShowMerged] = useState(false);
  const [cohortsJSON, setCohortsJSON] = useState("");
  const [showSettingsIconHeader, setShowSettingsIconHeader] =
    useState<boolean>(true);

  const initialProps = {
    text: "Are you sure you want to leave this screen. The changes you have\nmade will not be saved",
    exitButtonText: "Exit",
    stayButtonText: "Stay Here",
    onExitClick: () => {
      dispatch(resetAiInitiation());
      dispatch(
        setActiveBrand({
          id: getSearchParams("brand_id"),
        })
      );
      dispatch(setCurrentPage(1));
      navigate("/singlePlatform" + window.location.search);
    },
    onStayClick: () => setShowModal(false),
  };

  const checkStepAndComplete = (
    steps: any[],
    currentPageVal: number,
    onExitClickCallback: Function
  ) => {
    const activePageLocal = localStorage.getItem("currentPage");
    const activePageLocalVal = activePageLocal
      ? parseInt(activePageLocal, 10)
      : 1;
    if (activePageLocalVal && activePageLocalVal > currentPageVal) {
      setModalProps({
        ...modalProps,
        text: "Are you sure you want to make edits ? \nPreviously generated results will be lost",
        exitButtonText: "Yes",
        stayButtonText: "Cancel",
        onStayClick: () => {
          setShowModal(false);
        },
        onExitClick: () => {
          updateCalibrationData(!callibrationData);
          onExitClickCallback();
          setModalProps(initialProps);
          setShowModal(false);
          dispatch(setCurrentPage(currentPageVal + 1));
        },
      });
      setShowModal(true);
    } else {
      onExitClickCallback();
      dispatch(setCurrentPage(currentPageVal + 1));
    }
    return true;
  };

  const setPopover = (val: boolean) => {
    setNodes((nds: any) => {
      return nds.map((node: any) => {
        if (
          ![
            "keywordSetupCollapsed",
            "BasicSetupParentCollapsed",
            "nodeCalibrationCollapsed",
          ].includes(node.id)
        ) {
          return node;
        }
        return {
          ...node,
          data: {
            ...node.data,
            popover: val,
          },
        };
      });
    });
  };

  const changePublishModal = (data: any) => {
    setPublishModalData(data);
    setShowPublishModal(true);
  };

  const startAgainFlow = () => {
    window.location.reload();
  };

  useEffect(() => {
    dispatch(
      getBrandAdAccounts(
        { brandId: selectedBrand.id },
        (adaccRes: any, adaccError: boolean) => {
          if (!adaccError || adaccRes.data.length > 0) {
            adaccRes.data.map((adcc: any) => {
              adcc.name = adcc.adAccountId;
              adcc.id = adcc.adAccountId;
            });
            dispatch(setSelectedBrandAdaccounts(adaccRes.data));
          } else {
            console.log("getAdAccounts ->", adaccError);
            dispatch(setSelectedBrandAdaccounts([]));
          }
        }
      )
    );
    if (!selectedBrand.name) {
      const brandId = getSearchParams("brand_id");
      let brand = brands.find((brand: any) => brand.id === brandId);
      dispatch(setActiveBrand(brand as any));
    }
    setNodes([nodesList["aiInitiationParent"], nodesList["aiInitiation"]]);
  }, []);

  useEffect(() => {
    if (selectedBrand?.id) {
      getMyRolesAPI(selectedBrand?.id, selectedBrand?.agencyId).then(
        (data: any) => {
          dispatch(setBrandRoles(data?.data?.userRoles || []));
        }
      );
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (user && selectedBrand?.agencyId) {
      dispatch(
        getAgency(
          { agencyId: selectedBrand?.agencyId, user },
          (response: any, error: boolean) => {
            if (!error) {
              dispatch(setSelectedAgency(response?.data));
              TAIGSearchMixPanel(
                "Page View - Dashboard",
                selectedBrand?.name,
                response?.data?.name,
                {
                  username: user?.name,
                  email: user?.email,
                }
              );
            } else {
              console.log(error);
            }
          }
        )
      );
    }
  }, [selectedBrand, user]);

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  useEffect(()=>{
    setNodesList({
      ...nodesList,
      aiInitiationParent:{
         ...nodesList.aiInitiationParent,
         style:{
          ...nodesList.aiInitiationParent.style,
          height:selectedPlatform === 'Facebook' ? '512px' : '460px'
         }
      }
    })
  },[selectedPlatform,setSelectedPlatform]);
  return (
    <TaiContext.Provider
      value={{
        popoverFlag,
        setPopoverFlag,
        modalProps,
        setModalProps,
        showModal,
        setShowModal,
        initialProps,
        checkStepAndComplete,
        nodes,
        setNodes,
        onNodesChange,
        edges,
        setEdges,
        onEdgesChange,
        setPopover,
        nodesList,
        edgesList,
        showPublishModal,
        setShowPublishModal,
        publishModalData,
        setPublishModalData,
        changePublishModal,
        startAgainFlow,
        nodeTypes,
        selectedPlatform,
        setSelectedPlatform,
        keywordsJsonTai,
        setKeywordsJsonTai,
        updateCalibrationData,
        callibrationData,
        custom,
        setCustom,
        showSettingsIconHeader,
        setShowSettingsIconHeader,
        checkedCohorts,
        setCheckedCohorts,
        shownClusterIDs,
        setShownClusterIDs,
        cohortsJSON,
        setCohortsJSON,
        showMerged,
        setShowMerged,
        setCohortType,
        cohortType,
        shownClusterData,
        setShownClusterData,
      }}
    >
      <TargetAIPlaygroundBeta />
    </TaiContext.Provider>
  );
}

export function TaiContextProvider() {
  return (
    <ReactFlowProvider>
      <TaiContextProviderInner />
    </ReactFlowProvider>
  );
}

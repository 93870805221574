import { has, isEmpty, merge } from "lodash";
import { generation_type } from "utils/constants/creativeAI";
import {
  APIGenerationType,
  GenerationTypeConvertEnum,
} from "../shared/SharedTypes";

// TODO: to be replaced with better algo

const hasLever = (levers: any, lever: any) => {
  if (!has(levers, lever)) {
    levers[lever] = {};
  }
};

// to merge headline and description levers into headline/description
export const mergeData = (data: APIGenerationType[]) => {
  const headlineLevers = data.find(
    (g) => g.generation_type === "headline"
  )?.levers;
  const descriptionLevers = data.find(
    (g) => g.generation_type === "description"
  )?.levers;

  descriptionLevers?.forEach((descriptionLever) => {
    if (headlineLevers) {
      const headlineLeverIndex = headlineLevers.findIndex(
        (h) => descriptionLever.lever_name === h.lever_name
      );

      if (headlineLeverIndex > -1) {
        descriptionLever.fields.forEach((descriptionField) => {
          const headlineFieldIndex = headlineLevers[
            headlineLeverIndex
          ].fields.findIndex(
            (headlineField) =>
              headlineField.field_name === descriptionField.field_name
          );
          if (headlineFieldIndex > -1) {
            headlineLevers[headlineLeverIndex].fields[headlineFieldIndex] = {
              ...headlineLevers[headlineLeverIndex].fields[headlineFieldIndex],
              origin: "both",
            };
          } else {
            headlineLevers[headlineLeverIndex].fields.push({
              ...descriptionField,
              origin: "description",
            });
          }
        });
      } else {
        headlineLevers?.push(descriptionLever);
      }
    }
  });

  return headlineLevers;
};

// TODO: merge expandData and expandGeneratedData
// to expand headline/description form values into headline and description form values
export const expandData = (
  headlineDescription: any,
  headlineDescriptionLevers: any,
  isBU: boolean = false
) => {
  let formattedData: any = {
    headline: {},
    description: {},
  };
  if (isBU) {
    formattedData["superUserSettings"] = {};
  }
  for (let lever in headlineDescription) {
    for (let field in headlineDescription[lever]) {
      const headlineDescriptionLever = headlineDescriptionLevers
        ?.find((l: any) => l.lever_name === lever)
        ?.fields.find((f: any) => f.field_name === field);
      const origin = headlineDescriptionLever?.origin;
      const is_dependent = headlineDescriptionLever?.is_dependent;
      const value = headlineDescription[lever][field];
      if (isBU && is_dependent) {
        formattedData.superUserSettings[field] = value;
      }
      switch (origin) {
        case "both":
          hasLever(formattedData.description, lever);
          hasLever(formattedData.headline, lever);
          formattedData.description[lever][field] = value;
          formattedData.headline[lever][field] = value;
          break;

        case "description":
          hasLever(formattedData.description, lever);
          formattedData.description[lever][field] = value;
          break;

        default:
          hasLever(formattedData.headline, lever);
          formattedData.headline[lever][field] = value;
          break;
      }
      if (field === "isChecked") {
        hasLever(formattedData.description, lever);
        formattedData.description[lever][field] = value;
      }
    }
  }
  return formattedData;
};

export const generateConfigurePayload = (
  data: any,
  generations: any,
  supportType: "generate" | "bundle",
  adType: "link_data" | "video_data",
  brand_id: string,
  adId: string,
  adAccountId: string | any,
  noOfRecommendations: number = 12
) => {
  let generationsData: any = {};
  let formattedData: any = {};
  const bu = {
    bu_name: data.businessUnit,
    bu_detail: data.businessUnitDetail,
    brand_name: data.brandName,
    interest_keyword: data.interestKeyword,
    brand_id,
  };
  const payload: any = { input_data: {} };
  for (let generationType of generation_type) {
    generationsData[generationType] =
      data[generationType] || data[GenerationTypeConvertEnum[generationType]];
    const defaultLevers = generations.find(
      (generation: any) => generation.generation_type === generationType
    )?.levers;
    formattedData[generationType] = {};
    for (let lever in generationsData[generationType]) {
      const defaultLever = defaultLevers?.find(
        (defaultLever: any) => defaultLever.lever_name === lever
      );
      const defaultFields = defaultLever?.fields;
      if (
        (defaultLever?.is_bundle_supported && supportType === "bundle") ||
        (defaultLever?.is_generate_supported && supportType === "generate")
      ) {
        if (
          generationsData[generationType][lever].isChecked === undefined ||
          generationsData[generationType][lever].isChecked ||
          supportType === "bundle"
        ) {
          formattedData[generationType][lever] = {};
          formattedData[generationType][lever]["business_unit"] = {
            default: {
              ...bu,
              ...(generationType == "primary_text"
                ? { ad_account_id: adAccountId, ad_id: adId }
                : {}),
            },
          };
          for (let field in generationsData[generationType][lever]) {
            if (
              field !== "isChecked" &&
              defaultFields?.find(
                (defaultField: any) => defaultField.field_name === field
              )
            ) {
              let value = generationsData[generationType][lever][field];
              if (value && typeof value === "object" && !Array.isArray(value)) {
                value = value.value;
              }
              if (field.startsWith("bu_")) {
                formattedData[generationType][lever]["business_unit"][field] =
                  value;
              } else {
                formattedData[generationType][lever][field] = value;
              }
            }
          }
          formattedData[generationType]["total_no_outputs"] =
            noOfRecommendations;
        }
      }
    }
    if (!isEmpty(formattedData[generationType])) {
      payload.input_data[generationType] = formattedData[generationType];
    }
  }

  return payload;
};

export const getFormValues = (data: any, isConfigure: boolean = false) => {
  const formValues: any = {
    brandName: data?.brandName,
    interestKeyword: data?.interestKeyword,
    businessUnitId: data?.businessUnitId,
  };
  if (!isConfigure) {
    formValues["headlineDescription"] = merge(
      data?.CreativeAiData?.description,
      data?.CreativeAiData?.headline
    );
  }
  generation_type.forEach((type) => {
    if (isConfigure || (type !== "headline" && type !== "description")) {
      formValues[isConfigure ? type : GenerationTypeConvertEnum[type]] =
        data?.CreativeAiData[GenerationTypeConvertEnum[type]];
    }
  });
  return formValues;
};

export const getBUFormValues = (
  data: any,
  isBU: boolean = false,
  isConfigure: boolean = false
) => {
  const formValues: any = {
    brandName: data?.brandName,
    interestKeyword: data?.interestKeyword,
    businessUnit: data?.businessUnit,
    businessUnitDetail: data?.businessUnitDetail,
    status: data?.status === "readyToUse",
  };
  if (isBU) {
    formValues["businessUnit"] = data?.businessUnit;
    formValues["businessUnitDetail"] = data?.businessUnitDetail;
  } else {
    formValues["businessUnitId"] = data?.id;
  }
  if (!isConfigure) {
    formValues["headlineDescription"] = merge(
      data?.description,
      data?.headline
    );
  }
  generation_type.forEach((type) => {
    if (isConfigure || (type !== "headline" && type !== "description")) {
      formValues[isConfigure ? type : GenerationTypeConvertEnum[type]] =
        data[GenerationTypeConvertEnum[type]];
    }
  });
  return formValues;
};

export const getMediaStudioFormValues = (data: any) => {
  const payload: any = {
    adIds: data?.MediaStudioAds?.map((obj: any) => obj.adId),
    tag: data?.tag,
    frames: data?.frames,
    usageType: data?.usageType,
    videoBlenderFile: data?.videoBlenderFile,
  };
  return payload;
};

import { Box, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { ReactComponent as Close } from "../../../../../assets/svg/Ic_Close.svg";
import { ReactComponent as More } from "../../../../../assets/svg/Ic_More.svg";
import { ReactComponent as Like } from "../../../../../assets/svg/Ic_Like.svg";
import { ReactComponent as Comment } from "../../../../../assets/svg/Ic_Comment.svg";
import { ReactComponent as Share } from "../../../../../assets/svg/Ic_Share.svg";
import { ReactComponent as Global } from "../../../../../assets/svg/Global.svg";
import SuccessGif from "../../../../../assets/Gif/Loaded.gif";

import { useState, useRef, useEffect } from "react";
import TruncateText from "./TextTruncate.jsx";
import {
  StyledAvatar,
  StyledAvatarContainer,
  StyledCTAButton,
  StyledCTAButtonContainer,
  StyledFacebookIcon,
  StyledFooter,
  StyledFooterItem,
  StyledHeadline,
  StyledPreviewHeader,
  StyledProfileContainer,
  StyledProfileName,
  StyledDescription,
  StyledSeeMore,
  StyledPrimaryText,
  StyledProfile,
  StyleDivider,
  StyledPreviewFrame,
  StyledDisplayURL,
  GlobalIconContainer,
  HeaderIconContainer,
  SponsoredContainer,
  PreviewFrameContainer,
  StyledMidSectionContainer,
  StyledMediaSection,
} from "./styled";
import ErrorLoadingScreen from "../../CreateVariants/Billboard/ErrorLoadingScreen";
import BlurComponent from "../../CreateVariants/Billboard/BlurComponent";

export interface PreviewFrameProps {
  displayURL: string;
  previewType: "image" | "video";
  profileAvatar: string;
  profileName: string;
  mediaURL: string;
  setMediaURL?: (string: string) => void;
  recommendationData: {
    primary_text: string;
    description: string;
    headline: string;
  };
  CTA_button:
    | "SHOP_NOW"
    | "LEARN_MORE"
    | "APPLY_NOW"
    | "GET_SHOWTIMES"
    | "BOOK_NOW"
    | "CONTACT_US"
    | "DOWNLOAD"
    | "GET_OFFER"
    | "GET_QUOTE"
    | "LISTEN_NOW"
    | "ORDER_NOW"
    | "GET_ACCESS"
    | "GET_ACCESS"
    | "REQUEST_TIME"
    | "SEE_MENU"
    | "SIGN_UP"
    | "WATCH_MORE"
    | "SUBSCRIBE";
  origin: "homescreen" | "editor";
  item?: any;
}

const CTAbuttons = {
  SHOP_NOW: "Shop Now",
  LEARN_MORE: "Learn More",
  APPLY_NOW: "Apply Now",
  GET_SHOWTIMES: "Get Showtimes",
  BOOK_NOW: "Book Now",
  CONTACT_US: "Contact Us",
  DOWNLOAD: "Download",
  GET_OFFER: "Get Offer",
  GET_QUOTE: "Get Quote",
  LISTEN_NOW: "Listen Now",
  ORDER_NOW: "Order Now",
  GET_ACCESS: "Get Access",
  REQUEST_TIME: "Request Time",
  SEE_MENU: "See Menu",
  SIGN_UP: "Sign Up",
  WATCH_MORE: "Watch More",
  SUBSCRIBE: "Subscribe",
};

const InHousePreview = ({
  displayURL,
  previewType,
  profileAvatar,
  profileName,
  recommendationData,
  mediaURL,
  setMediaURL,
  CTA_button,
  item,
  origin,
}: PreviewFrameProps) => {
  const [modal, showModal] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [shouldTransform, setTransform] = useState(false);
  const [transformValue, setTransformValue] = useState(0);
  const [truncateComponent, setTruncateComponent] = useState();
  const headlineRef = useRef<HTMLDivElement>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const frameContainerRef = useRef(null);
  const mainFrameContainerRef = useRef(null);
  const primaryRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      let element = headlineRef.current;
      element!.clientHeight <= 18 && previewType === "image"
        ? setShowDesc(true)
        : setShowDesc(false);
    }, 1000);
  });

  const willTransform = () => {
    const node1: any = mainFrameContainerRef?.current;
    const node2: any = frameContainerRef.current;
    let containerHeight1 = node1?.clientHeight;
    let containerHeight2 = node2?.clientHeight;
    if (Math.floor(containerHeight2) >= containerHeight1) {
      setTransformValue(containerHeight1 / containerHeight2);
      setTransform(true);
    } else {
      setTransformValue(1);
      setTransform(false);
    }
  };

  function handleSeeMore() {
    setSeeMore(true);
  }

  function getDisplayURL(displayURL: string) {
    let resURL = "";
    try {
      resURL = new URL(displayURL).hostname.toString().replace("www.", "");
    } catch (error) {
      // console.log(error);
    }

    return resURL;
  }

  useEffect(() => {
    if (previewType === "image" && origin === "homescreen") {
      setImageLoading(true);
    }
  }, []);

  useEffect(() => {
    if (previewType === "image" && origin === "editor") {
      setImageLoading(true);
    }
  }, [mediaURL]);

  useEffect(() => {
    if (seeMore) {
      willTransform();
    }
  }, [recommendationData.primary_text, seeMore]);

  return (
    <StyledPreviewFrame ref={mainFrameContainerRef} origin={origin}>
      <PreviewFrameContainer
        id="previewFrameContainer"
        style={{
          transform: `${shouldTransform ? `scale(${transformValue})` : ""}`,
        }}
        ref={frameContainerRef}
      >
        <StyledPreviewHeader>
          <StyledAvatarContainer>
            <StyledAvatar src={profileAvatar} alt="" />
          </StyledAvatarContainer>
          <StyledProfileContainer>
            <StyledProfile>
              <StyledProfileName>{profileName}</StyledProfileName>
              <SponsoredContainer>
                <div
                  style={{
                    marginRight: "2px",
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  Sponsored
                </div>
                <div style={{ marginRight: "2px" }}>.</div>
                <GlobalIconContainer>
                  <Global height="10px" width="11px" />
                </GlobalIconContainer>
              </SponsoredContainer>
            </StyledProfile>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <HeaderIconContainer>
                <More />
              </HeaderIconContainer>
              <HeaderIconContainer style={{ marginLeft: "16px" }}>
                <Close />
              </HeaderIconContainer>
            </div>
          </StyledProfileContainer>
        </StyledPreviewHeader>
        <StyledPrimaryText id="primaryText">
          {seeMore || recommendationData.primary_text?.length === 0 ? (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {recommendationData.primary_text}
            </div>
          ) : (
            <TruncateText
              line={3}
              element="span"
              truncateText="                  "
              text={recommendationData.primary_text}
              textTruncateChild={
                <StyledSeeMore href="#" onClick={handleSeeMore}>
                  &nbsp;...see more
                </StyledSeeMore>
              }
            />
          )}
        </StyledPrimaryText>
        <StyledMediaSection>
          {/* 
          
          Not incorporate the error screen as of now
          <ErrorLoadingScreen /> 

          */}

          {(item?.variantType === "billboard" ||
            item?.variantType === "animator") &&
          !mediaURL ? (
            <BlurComponent
              willTransform={willTransform}
              setMediaURL={setMediaURL}
              item={item}
            />
          ) : (
            <Box position="relative">
              {previewType === "image" ? (
                <img
                  height="auto"
                  key={item?.id ?? "new-item"}
                  width="100%"
                  onClick={() => showModal(true)}
                  style={{ cursor: "zoom-in" }}
                  src={mediaURL}
                  alt=""
                  onLoad={() => {
                    willTransform();
                    setImageLoading(false);
                  }}
                />
              ) : (
                <div>
                  <video
                    key={mediaURL}
                    controls
                    style={{ width: "100%" }}
                    id="video"
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                    onLoadedData={() => {
                      willTransform();
                    }}
                  >
                    <source src={mediaURL} type="video/mp4"></source>
                  </video>
                </div>
              )}
              {previewType === "image" && imageLoading && mediaURL && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    minHeight: "140px",
                    height: "100%",
                    width: "100%",
                    background: "rgba(0, 0, 0, 0.7)",
                    backdropFilter: "blur(5px)",
                  }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress size="40px" />
                  </Box>
                </Box>
              )}
            </Box>
          )}
          <StyledMidSectionContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 2.5 }}>
                {displayURL?.toString().length ? (
                  <StyledDisplayURL>
                    {getDisplayURL(displayURL)}
                  </StyledDisplayURL>
                ) : (
                  <StyledDisplayURL></StyledDisplayURL>
                )}
                <StyledHeadline id="headline" ref={headlineRef}>
                  {recommendationData.headline}
                </StyledHeadline>
                {showDesc && (
                  <StyledDescription>
                    {recommendationData.description}
                  </StyledDescription>
                )}
              </div>
              <StyledCTAButtonContainer>
                <StyledCTAButton>{CTAbuttons[CTA_button]}</StyledCTAButton>
              </StyledCTAButtonContainer>
            </div>
          </StyledMidSectionContainer>
        </StyledMediaSection>
        <StyleDivider id="divider"></StyleDivider>
        <StyledFooter>
          <StyledFooterItem>
            <StyledFacebookIcon>
              <Like />
            </StyledFacebookIcon>
            <div>Like</div>
          </StyledFooterItem>
          <StyledFooterItem>
            <StyledFacebookIcon>
              <Comment />
            </StyledFacebookIcon>
            <div>Comment</div>
          </StyledFooterItem>
          <StyledFooterItem>
            <StyledFacebookIcon>
              <Share />
            </StyledFacebookIcon>
            <div>Share</div>
          </StyledFooterItem>
        </StyledFooter>
      </PreviewFrameContainer>
      {showModal && (
        <Dialog
          open={modal}
          onClose={() => showModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEscapeKeyDown={false}
          fullScreen={false}
        >
          <DialogContent>
            <div>
              <img
                height="auto"
                width="100%"
                onClick={() => showModal(true)}
                style={{ cursor: "zoom-in" }}
                src={mediaURL}
                alt=""
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
    </StyledPreviewFrame>
  );
};

export default InHousePreview;

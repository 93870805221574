import { ReactComponent as Spends } from "../assets/spends.svg";
import { ReactComponent as Results } from "../assets/results.svg";
import { ReactComponent as CPR } from "../assets/cpr.svg";
import { ReactComponent as RelevanceScore } from "../assets/relevanceScore.svg";
import { ReactComponent as PotentialAudience } from "../assets/potentialAudience.svg";
import { AgencyAssets } from "store/types/common";
export const TABSHEADING = [
  { value: "summary", label: "Summary" },
  { value: "configuration", label: "Configuration" },
];

export const LIVE_CARD_LABELS = ["Spends", "Results", "CPR"];
export const DRAFTED_CARD_LABELS = ["Potential Audience", "Relevance Score"];

export const LIVE_CARD_SVGS = [<Spends />, <Results />, <CPR />];
export const DRAFTED_CARD_SVGS = [<PotentialAudience />, <RelevanceScore />];

export const getNoDataProps = (agencyAssets: AgencyAssets) => ({
  live: {
    heading: "You haven't Published any cohort yet",
    subHeading:
      "Start publishing cohorts, click on create new audience to start generating cohorts",
  },
  drafted: {
    heading: "You haven't Saved any cohort yet",
    subHeading:
      "Start saving cohorts, click on create new audience to start generating cohorts",
  },
  recommended: {
    heading: `${agencyAssets?.title || 'Pixis'} hasn't recommended any cohort yet`,
    subHeading: "Click on create new audience to start generating cohorts",
  },
});

export const COHORT_TYPES = ["Direct (Knowledge Graph)", "Insights Direct", "Insights Lateral", "Direct", "Lateral", "Merged", "Insights Driven"];

export const NUMBER_OF_MONTHS = 6;
export const BRAND_NAME_SORT_DEFAULT = "all";
export const BRAND_WEBSITE_SORT_DEFAULT = "all";
export const SELECTED_COHORT_TYPES = "all";
export const YOUTUBE_SUB_TABS = ["Videos", "Channels", "Audience Segments"];
export const YOUTUBE_SUB_TAB_VALUES = ["video", "channel", "audience"];

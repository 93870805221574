import { Typography } from "@mui/material";
import { useEffect } from "react";
import { getSearchParams } from "utils/commonFunctions";

export default function Redirection() {
  // doesn't change on depending on different envs, hence defined a constant instead of env variables
  const REDIRECTIONMAPPING = {
    analytics_localhost: "http://localhost:3000/managesources",
    analytics_tms_dev: "https://analytics-tms-dev.pixis.ai/managesources",
    analytics_beta: "https://analytics-beta.pixis.ai/managesources",
    analytics_feature: "https://analytics-feature.pixis.ai/",
  } as const;
  const searchParams = getSearchParams("state");
  useEffect(() => {
    if (
      typeof searchParams === "string" &&
      Object.keys(REDIRECTIONMAPPING).includes(searchParams)
    ) {
      window.location.href =
        REDIRECTIONMAPPING[searchParams as keyof typeof REDIRECTIONMAPPING];
    }
  }, []);

  return <Typography p={"1rem"}>Redirecting...</Typography>;
}

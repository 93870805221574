import HttpService from "HttpService/HttpService";
import { CROSS_PLATFORM_URL } from "utils/path";

export const getMyRolesAPI = async (brandId?: string, agencyId?: string) => {
  let response = {
    success: false,
    message: "",
    data: {},
  };

  try {
    // let urlParams = `/v4/api/me/roles${brandId ? "?brandId=" + brandId : ""}`;
    let urlParams = `/v4/api/me/role-permissions?serviceId=CORE${
      brandId ? `&brandId=${brandId}` : ""
    }${agencyId ? `&agencyId=${agencyId}` : ""}`;
    response = await HttpService.get(CROSS_PLATFORM_URL, urlParams).then(
      (data: any) => {
        return data.data;
      }
    );
    return response;
  } catch (error) {
    console.log("LL: getMyRolesAPI -> error", error);
  }
  return response;
};

export const getContextInfoAPI = async () => {
  let response = {
    success: false,
    message: "",
    data: {},
  };

  try {
    // let urlParams = `/v4/api/me/roles${brandId ? "?brandId=" + brandId : ""}`;
    let urlParams = `/v4/api/user-management/allowed-context-types?serviceId=CORE`;
    response = await HttpService.get(CROSS_PLATFORM_URL, urlParams).then(
      (data: any) => {
        return data.data;
      }
    );
    return response;
  } catch (error) {
    console.log("LL: getMyRolesAPI -> error", error);
  }
  return response;
};

import React, { useEffect, useMemo, useState } from "react";
import OpenCAISetup from "../OpenCAISetup/OpenCAISetup";
import TechnicalIssue from "../TechnicalIssue/TechnicalIssue";
import {
  StyledHomeDivider,
  StyledHomeScreenBox,
  StyledHomeScreenFilterBox,
} from "./styled";
import { Box } from "@mui/material";
import ViewAdSetDetailsModal from "./ViewAdSetDetailsModal/ViewAdSetDetailsModal";
import {
  DropDown,
  DynamicFilterBoxWithMemo,
  LightTooltip,
} from "components/CreativeAI_Revamp/shared";
import { DynamicFilterItemType } from "components/CreativeAI_Revamp/shared/DynamicFilterBox/types";
import { ReactComponent as Error } from "../../../../assets/cai/svg/error.svg";
import { useHomeScreen } from "./hooks/useHomeScreen";
import HomeScreenBody from "./HomeScreenBody/HomeScreenBody";
import { getFiltersUpdated } from "store/actions/CreativeAI_Revamp/facebook.actions";
import { useDispatch } from "react-redux";
import { useCaiFbMixPanel } from "./hooks/useCaiFbMixPanel";
import { collator } from "utils/strings";

export default function HomeScreen({
  selectedTabValue,
}: {
  selectedTabValue: "home" | "log";
}) {
  const dynamicFilterArray: DynamicFilterItemType[] = useMemo(
    () => [
      {
        name: "Ad Status",
        type: "radio",
        adKey: "status",
        options: [
          {
            value: "ACTIVE",
            desc: "ON",
          },
          {
            value: "PAUSED",
            desc: "OFF",
          },
        ],
      },
      {
        name: "Ad Type",
        type: "check",
        adKey: "adType",
        options: [
          {
            value: "SINGLE_IMAGE_AD",
            desc: "Image",
          },
          {
            value: "VIDEO_AD",
            desc: "Video",
          },
          // {
          //   value: "CAROUSEL_AD",
          //   desc: "Carousel",
          // },
        ],
      },
      {
        name: "Media Studio",
        type: "radio",
        adKey: "isMediaStudioAvailable",
        options: [
          {
            value: true,
            desc: "Available",
          },
          {
            value: false,
            desc: "Not Available",
          },
        ],
        width: "12.375em",
      },
      {
        name: "Publishing Source",
        type: "check",
        adKey: "publishingSource",
        options: [
          {
            value: "PIXIS",
            desc: "Pixis Creative AI",
          },
          {
            value: "OTHERS",
            desc: "Other Sources",
          },
        ],
        width: "12.375em",
      },
      {
        name: "Ad Variant Review Status",
        type: "check",
        adKey: "draftVariants",
        variantKey: "reviewStatus",
        options: [
          {
            value: "approved",
            desc: "Approved",
          },
          {
            value: "rejected",
            desc: "Rejected",
          },
          {
            value: "under_review",
            desc: "In Review",
          },
          {
            value: "not_raised",
            desc: "Not Raised",
          },
        ],
        width: "15em",
      },
      {
        name: "Ad Variant Type",
        type: "radio",
        adKey: "billboardVariants",
        variantKey: "variantType",
        options: [
          // {
          //   value: "image",
          //   desc: "Image Ad",
          // },
          // {
          //   value: "video",
          //   desc: "Video Ad",
          // },
          {
            value: "billboard",
            desc: "Billboard Ad",
          },
        ],
        width: "12.375em",
      },
    ],
    []
  );
  const [searchText, setSearchText] = useState("");
  const [adSetSearch, setadSetSearch] = useState("");

  const trailingComponent = (item: any) => {
    return item?.adCount >= 50 ? (
      <LightTooltip title="The ad limit of 50 has been reached">
        <Error />
      </LightTooltip>
    ) : undefined;
  };
  const {
    selectedAd,
    adAccountList,
    adAccountsSelection,
    setAdAccountSelection,
    adSetList,
    adSetSelection,
    setAdsetSelection,
    adsList,
    setAdSelection,
    adsSelection,
  } = useHomeScreen();
  const { mixPanelAdAccount, mixPanelAdset, mixPanelAds } = useCaiFbMixPanel();
  const displayAdsList = useMemo(() => {
    if (searchText.length > 0) {
      return adsList.data.filter(
        (option: any) =>
          option.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          option.id.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      );
    } else {
      return adsList.data;
    }
  }, [searchText, adsList.data]);

  const displayAdsetList = useMemo(() => {
    if (adSetSearch.length > 0) {
      return adSetList.data.filter(
        (option) =>
          option.name?.toLowerCase().indexOf(adSetSearch.toLowerCase()) > -1 ||
          option.adsetId.toLowerCase().indexOf(adSetSearch.toLowerCase()) > -1
      );
    } else {
      return adSetList.data;
    }
  }, [adSetSearch, adSetList.data]);

  const dispatch = useDispatch();

  return (
    <StyledHomeScreenBox id="home" hidden={selectedTabValue !== "home"}>
      <StyledHomeScreenFilterBox>
        <Box display="flex" gap=".75em">
          <DropDown
            title="Ad Account"
            options={adAccountList.data.sort((a, b) =>
              collator.compare(a.name.trim(), b.name.trim())
            )}
            selection={adAccountsSelection?.selection}
            handlePropChange={(items) => {
              setAdAccountSelection(items);
              mixPanelAdAccount(items);
            }}
            isLoading={
              adAccountList.isLoading ||
              adAccountsSelection?.selection === undefined
            }
            isFailed={adAccountList.isFailed}
            titleKey="name"
            subTitleKey="adAccountId"
            key="ad-accounts"
          />
          <DropDown
            title="Ad Set"
            options={displayAdsetList}
            selection={adSetSelection?.selection}
            handlePropChange={(items) => {
              setAdsetSelection(items);
              mixPanelAdset(items);
            }}
            onSearch={(value) => setadSetSearch(value ?? "")}
            trailingComponent={trailingComponent}
            titleKey="name"
            subTitleKey="adsetId"
            isLoading={
              adSetList.isLoading || adSetSelection?.selection === undefined
            }
            isFailed={adSetList.isFailed}
            key="adset"
            onClose={() => {
              setadSetSearch("");
            }}
            paperWidth="17.75em"
          />
          <DropDown
            title="Reference Ad"
            options={displayAdsList?.sort((a: any, b: any) =>
              collator.compare(a?.name?.trim(), b?.name?.trim())
            )}
            selection={adsSelection.selectedAds}
            handlePropChange={(items, allSelectClicked) => {
              console.log(items);
              const selectedIndex = displayAdsList.findIndex(
                (item: any) => selectedAd?.id === item?.id
              );
              if (allSelectClicked) {
                setAdSelection([displayAdsList[selectedIndex ?? 0]]);
              } else {
                setAdSelection(items);
              }
              mixPanelAds(items);
            }}
            onSearch={(value) => setSearchText(value ?? "")}
            titleKey="name"
            subTitleKey="id"
            isLoading={
              adsList.isLoading || adsSelection.selectedAds.length === 0
            }
            isFailed={adsList.isFailed}
            multiple
            key="ads"
            onClose={() => {
              setSearchText("");
            }}
            paperWidth="17.75em"
            showTop
            titleSXProps={{
              height: "28px",
              lineHeight: "14px",
            }}
          />
        </Box>
        <StyledHomeDivider />
        <DynamicFilterBoxWithMemo
          dynamicFilterArray={dynamicFilterArray}
          handleFilterChange={(filterState: any) => {
            dispatch(getFiltersUpdated(filterState));
          }}
        />
        <StyledHomeDivider />
        <ViewAdSetDetailsModal />
      </StyledHomeScreenFilterBox>
      <HomeScreenBody />
    </StyledHomeScreenBox>
  );
}

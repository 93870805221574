import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import CustomizedSwitch from "../../components/shared/Switch/CustomizedSwitch";
import {
  StyledActionDebuggerHead,
  StyledTitlePara,
  StyledPhasesContent,
  StyledPeriodPara,
  StyledPeriodSpan,
  StyledComparePara,
  StyledRightContent,
  StyledBtnContainer,
  StyledOverFlow,
  StyledActionDebuggerBody,
  StyledSummaryBoardBox,
  StyledChartBox,
  StyledCardBox,
} from "./styled";

import { ReactComponent as ActionDebDropdown } from "../../assets/svg/ActionDebDropdown.svg";
import { ReactComponent as DownArrowBlack } from "../../assets/svg/dropdown_arrow.svg";
import { ReactComponent as DownArrowGrey } from "../../assets/svg/actionDebuggerDownArrow.svg";

import RadioMenu from "../../components/shared/Dropdown/RadioMenu";
import ActionDebuggerChart from "../../components/ActionDebugger/ActionDebuggerChart/ActionDebuggerChart";
import SummaryBoard from "../../components/ActionDebugger/SummaryBoard/SummaryBoard";
import ActionDebuggerDateRange from "../../components/ActionDebugger/ActionDebuggerDateRange/ActionDebuggerDateRange";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMetrics,
  getElementMetrics,
  getMetricGraphData,
  setCompareType,
  setPreviousPeriod,
  toggleCompareFlag,
  setElementMetrics,
  setMetricMapper,
  setMetricLoader,
  setAllMetrics,
  setMetricGraphData,
  setMetricOldGraphData,
  setGraphMetrics,
  updateElementAttributionWindow,
  setAttributionWindowLoader,
  setCurrentPeriod,
} from "../../store/actions/ActionDebugger/ActionDebugger";
import {
  ActionDebuggerInitStateType,
  AllMetricsType,
  LooseObject,
} from "../../store/types/ActionDebugger/ActionDebugger";
import {
  calculatePaddedDate,
  calculatePreviousPeriod,
  isActionDebuggerOn,
} from "../../components/ActionDebugger/shared/utils/common.utils";
import ActionDebuggerChartSkeleton from "../../components/ActionDebugger/ActionDebuggerChart/ActionDebuggerChartSkeleton/ActionDebuggerChartSkeleton";
import { ActionDebuggerHelperInitState } from "../../store/types/ActionDebugger/ActionDebuggerHelper";
import { ddMMNDisplayDate, yyyyMMDDFormat } from "../../utils/date.util";
import { addDays, sub } from "date-fns";
import ActionDebuggerEmptyChart from "../../components/ActionDebugger/ActionDebuggerChart/ActionDebuggerEmptyChart/ActionDebuggerEmptyChart";
import { mixPanelAdEvent } from "utils/mixpanel";
import { useAuth0 } from "@auth0/auth0-react";
import { AdAccountState } from "store/types/adaccount";

const ActionDebugger: React.FC = () => {
  const [previousMenu, setPreviousMenu] = useState(null);
  const [attributionMenu, setAttributionMenu] = useState(null);
  const [graphCurrentData, setGraphCurrentData] = useState({});
  const [graphOldData, setGraphOldData] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [isProcessingGraphData, setIsProcessingGraphData] = useState(false);
  const periodArray = ["Period", "Month", "Year"];
  const [tooltipExtraInfo, setTooltipExtraInfo] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    isCompare,
    compareType,
    currentPeriod,
    allMetrics,
    elementMetrics,
    previousPeriod,
    metricGraphData,
    metricOldGraphData,
    metricMapper,
    additionalDateInfo,
    metricLoader,
    currentGraphLoading,
    previousGraphLoading,
    attributionWindow,
    isDefault,
    graphMetrics,
    attributionWindowType,
    attributionLoader,
  } = useSelector(
    (state: { ActionDebuggerReducer: ActionDebuggerInitStateType }) =>
      state.ActionDebuggerReducer
  );
  const { user } = useAuth0();
  const {
    aiGroupLoading,
    adAccountLoading,
    adSetLoading,
    selectedCampaignLoading,
    selectedAdAccount,
    selectedCampaign,
    selectedAdSet,
    selectedAIGroup,
  } = useSelector(
    (state: { ActionDebuggerHelperReducer: ActionDebuggerHelperInitState }) =>
      state.ActionDebuggerHelperReducer
  );
  const { selectedBrand, brandSources } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  /// putting here

  const transformGraphData = (
    localElementMetrics: AllMetricsType[],
    metricGraphData: any,
    metricOldGraphData: any
  ) => {
    const { paddedDay, differenceInCalenderDay } = additionalDateInfo;
    const period = differenceInCalenderDay - 1 + 2 * paddedDay;
    let { startDate: paddedCurrentStartDate } = calculatePaddedDate(
      currentPeriod,
      paddedDay
    );
    let { startDate: paddedPreviousStartDate } = calculatePaddedDate(
      previousPeriod,
      paddedDay
    );
    let transformedCurrentData: any = {};
    let transformedPreviousData: any = {};
    let currentDateIterator = 0;
    let previousDateIterator = 0;
    let localTooltipExtraInfo: any = {};

    for (let day = 0; day <= period; day++) {
      if (
        metricGraphData[currentDateIterator]?.date_start &&
        ddMMNDisplayDate(paddedCurrentStartDate) ===
          ddMMNDisplayDate(metricGraphData[currentDateIterator]?.date_start)
      ) {
        // eslint-disable-next-line no-loop-func
        localElementMetrics.forEach(
          ({ id: metricId, metricFormat }: AllMetricsType, index: number) => {
            const data = metricGraphData[currentDateIterator][metricId] ?? null;
            const phase = metricGraphData[currentDateIterator]?.phase;

            if (transformedCurrentData[metricId]) {
              if (
                !transformedCurrentData[metricId].find(
                  (value: any) => value[0] === +paddedCurrentStartDate
                )
              ) {
                transformedCurrentData[metricId].push([
                  +paddedCurrentStartDate,
                  data !== null
                    ? metricFormat === "Percentage" &&
                      selectedAdAccount?.platform?.name
                        .toLowerCase()
                        .includes("google")
                      ? Number(Number(data * 100)?.toFixed(2))
                      : Number(Number(data)?.toFixed(2))
                    : null,
                ]);
              }
            } else {
              transformedCurrentData[metricId] = [];
              transformedCurrentData[metricId].push([
                +paddedCurrentStartDate,
                data !== null
                  ? metricFormat === "Percentage" &&
                    selectedAdAccount?.platform?.name
                      .toLowerCase()
                      .includes("google")
                    ? Number(Number(data * 100)?.toFixed(2))
                    : Number(Number(data)?.toFixed(2))
                  : null,
              ]);
            }
            if (!localTooltipExtraInfo[day]) {
              localTooltipExtraInfo[day] = {
                phase,
                // currency,
              };
            }
            if (
              metricGraphData[currentDateIterator][metricId] &&
              elementMetrics[index].isEmpty
            ) {
              let newMetric = [...elementMetrics];
              newMetric[index].isEmpty = false;
              dispatch(setElementMetrics(newMetric));
              // elementMetrics[index].isEmpty = false;
            }
          }
        );
        currentDateIterator += 1;
      } else {
        // eslint-disable-next-line no-loop-func
        localElementMetrics.forEach(({ id: metricId }: AllMetricsType) => {
          if (transformedCurrentData[metricId]) {
            if (
              !transformedCurrentData[metricId].find(
                (value: any) => value[0] === +paddedCurrentStartDate
              )
            ) {
              transformedCurrentData[metricId].push([
                +paddedCurrentStartDate,
                null,
              ]);
            }
          } else {
            transformedCurrentData[metricId] = [];
            transformedCurrentData[metricId].push([
              +paddedCurrentStartDate,
              null,
            ]);
          }
        });
      }

      // if (isCompare) {
      if (
        metricOldGraphData[previousDateIterator]?.date_start &&
        ddMMNDisplayDate(paddedPreviousStartDate) ===
          ddMMNDisplayDate(metricOldGraphData[previousDateIterator]?.date_start)
      ) {
        // eslint-disable-next-line no-loop-func
        localElementMetrics.forEach(
          ({ id: metricId, metricFormat }: AllMetricsType) => {
            const data =
              metricOldGraphData[previousDateIterator][metricId] ?? null;
            const phase = metricOldGraphData[previousDateIterator]?.phase;

            if (transformedPreviousData[metricId]) {
              if (
                !transformedPreviousData[metricId].find(
                  (value: any) => value[0] === +paddedCurrentStartDate
                )
              ) {
                transformedPreviousData[metricId].push([
                  +paddedCurrentStartDate,
                  data !== null
                    ? metricFormat === "Percentage" &&
                      selectedAdAccount?.platform?.name
                        .toLowerCase()
                        .includes("google")
                      ? Number(Number(data * 100)?.toFixed(2))
                      : Number(Number(data)?.toFixed(2))
                    : null,
                ]);
              }
            } else {
              transformedPreviousData[metricId] = [];
              transformedPreviousData[metricId].push([
                +paddedCurrentStartDate,
                data !== null
                  ? metricFormat === "Percentage" &&
                    selectedAdAccount?.platform?.name
                      .toLowerCase()
                      .includes("google")
                    ? Number(Number(data * 100)?.toFixed(2))
                    : Number(Number(data)?.toFixed(2))
                  : null,
              ]);
            }
            if (!localTooltipExtraInfo[day]) {
              localTooltipExtraInfo[day] = {
                phase,
                // currency,
              };
            }
          }
        );
        previousDateIterator += 1;
      } else {
        // eslint-disable-next-line no-loop-func
        localElementMetrics.forEach(({ id: metricId }: AllMetricsType) => {
          if (transformedPreviousData[metricId]) {
            if (
              !transformedPreviousData[metricId].find(
                (value: any) => value[0] === +paddedCurrentStartDate
              )
            ) {
              transformedPreviousData[metricId].push([
                +paddedCurrentStartDate,
                null,
              ]);
            }
          } else {
            transformedPreviousData[metricId] = [];
            transformedPreviousData[metricId].push([
              +paddedCurrentStartDate,
              null,
            ]);
          }
        });
        // }
      }

      paddedPreviousStartDate = addDays(paddedPreviousStartDate, 1);
      paddedCurrentStartDate = addDays(paddedCurrentStartDate, 1);
    }
    if (isDefault) {
      let newmetric = elementMetrics.filter((metric) => !metric.isEmpty);
      dispatch(setGraphMetrics(newmetric));
    }
    return {
      transformedCurrentData,
      transformedPreviousData,
      localTooltipExtraInfo,
    };
  };

  useEffect(() => {
    if (
      elementMetrics.length > 0 &&
      !currentGraphLoading &&
      !previousGraphLoading
    ) {
      setIsProcessingGraphData(true);
      const {
        transformedCurrentData,
        transformedPreviousData,
        localTooltipExtraInfo,
      } = transformGraphData(
        elementMetrics.map((each: any) => {
          each.isEmpty = true;
          return each;
        }),
        metricGraphData,
        metricOldGraphData
      );
      setGraphOldData(transformedPreviousData); // need to changes name
      setGraphCurrentData(transformedCurrentData);
      setTooltipExtraInfo(localTooltipExtraInfo);
      setIsProcessingGraphData(false);
      dispatch(setMetricLoader(false));
    }
  }, [metricMapper, metricOldGraphData, metricGraphData]);
  //// end

  const dispatch = useDispatch();
  const periods = [
    {
      color: "#0869fb",
      name: `Current ${periodArray[compareType]}`,
    },
    {
      color: "#FDA92B",
      name: `Previous ${periodArray[compareType]}`,
    },
  ];

  const handleClick = (e: any) => {
    setPreviousMenu(e?.currentTarget);
  };

  const handleAttributionClick = (e: any) => {
    setAttributionMenu(e?.currentTarget);
  };

  const handleAttributionClose = () => {
    setAttributionMenu(null);
  };

  const handelClose = () => {
    setPreviousMenu(null);
  };

  const onChange = (value: any) => {
    const compareType: number = +value;

    dispatch(setCompareType(compareType));
    dispatch(
      setPreviousPeriod(
        calculatePreviousPeriod(compareType, currentPeriod, "Calender")
      )
    );
  };

  const toggleCompare = () => {
    mixPanelAdEvent("AD - Compare Toggled", {
      module_name: "Action Debugger",
      username: user?.name,
      agency_name: selectedBrand?.agency_name,
      agency_id: selectedBrand?.agencyId,
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      ad_account_id: selectedAdAccount?.selectedAdAcc?.id,
      ad_account_name: selectedAdAccount?.selectedAdAcc?.name,
      ai_group_name: selectedAIGroup?.name,
      toggle: isCompare ? "Off" : "On",
      compare_period_type: "Previous " + periodArray[compareType].toLowerCase(),
      platform_name: selectedAdAccount?.platform?.name,
    });
    dispatch(toggleCompareFlag(!isCompare));
  };

  const onAttributionWindowChange = (value: any) => {
    let newMetric = [...elementMetrics];
    newMetric.map((metric) => {
      metric.isEmpty = true;
    });
    dispatch({
      type: "SET_CHANGE_METRIC_FINISH",
      payload: false,
    });
    dispatch(setElementMetrics(newMetric));

    let payload: any = {
      attributionWindow: value,
    };
    if (value) {
      dispatch(setAttributionWindowLoader(true));
      dispatch(
        updateElementAttributionWindow({
          elementId: selectedAdAccount?.selectedAdAcc?.elementId,
          payload: payload,
        })
      );
    }
  };

  useEffect(() => {
    if (selectedAdAccount?.selectedAdAcc?.elementId) {
      dispatch(setAllMetrics([]));
      dispatch(
        getAllMetrics({
          elementId: selectedAdAccount?.selectedAdAcc?.elementId,
        })
      );
    }
  }, [selectedAdAccount]);

  useEffect(() => {
    if (allMetrics.length > 0) {
      let newmapper: LooseObject = {};
      for (const metric of allMetrics) {
        newmapper[metric.id] = {
          name: metric.name,
          shortName: metric.shortName,
          growth: metric.growth,
          metricFormat: metric.metricFormat,
        };
      }
      dispatch(setMetricMapper(newmapper));
      dispatch(setMetricLoader(false));
    } else {
      dispatch(setMetricLoader(false));
    }
  }, [allMetrics]);

  const getCurrentGraphData = () => {
    let currentPadedDays = calculatePaddedDate(
      currentPeriod,
      additionalDateInfo.paddedDay
    );
    dispatch(
      getMetricGraphData({
        typeOfData: "SET_METRIC_GRAPH_DATA",
        elementId: selectedAdAccount?.selectedAdAcc?.elementId,
        start_date: yyyyMMDDFormat(currentPadedDays.startDate),
        end_date: yyyyMMDDFormat(currentPadedDays.endDate),
        adsetIds:
          selectedAdAccount?.platform?.name &&
          (selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") ||
            selectedAdAccount?.platform.name
              ?.toLowerCase()
              .includes("tiktok"))
            ? selectedAdSet
            : [],
        campaignIds:
          selectedAdAccount?.platform?.name &&
          !selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") &&
          !selectedAdAccount?.platform.name?.toLowerCase().includes("tiktok") 
            ? selectedCampaign
            : [],
        loaderAction: "SET_METRIC_GRAPH_DATA_LOADER",
        attributionWindow:
          selectedAdAccount?.platform?.name &&
          selectedAdAccount?.platform.name?.toLowerCase().includes("facebook")
            ? attributionWindow
            : "",
      })
    );
  };

  const getPreviousGraphData = () => {
    let previousPadedDays = calculatePaddedDate(
      previousPeriod,
      additionalDateInfo.paddedDay
    );
    dispatch(
      getMetricGraphData({
        typeOfData: "SET_METRIC_OLD_GRAPH_DATA",
        elementId: selectedAdAccount?.selectedAdAcc?.elementId,
        start_date: yyyyMMDDFormat(previousPadedDays.startDate),
        end_date: yyyyMMDDFormat(previousPadedDays.endDate),
        adsetIds:
          selectedAdAccount?.platform?.name &&
          (selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") ||
            selectedAdAccount?.platform.name
              ?.toLowerCase()
              .includes("tiktok"))
            ? selectedAdSet
            : [],
        campaignIds:
          selectedAdAccount?.platform?.name &&
          !selectedAdAccount?.platform.name
            ?.toLowerCase()
            .includes("facebook") &&
          !selectedAdAccount?.platform.name?.toLowerCase().includes("tiktok") 
            ? selectedCampaign
            : [],
        loaderAction: "SET_METRIC_OLD_GRAPH_DATA_LOADER",
        attributionWindow:
          selectedAdAccount?.platform?.name &&
          selectedAdAccount?.platform.name?.toLowerCase().includes("facebook")
            ? attributionWindow
            : "",
      })
    );
  };

  useEffect(() => {
    if (
      currentPeriod.editedBy === "Calender" &&
      selectedAdAccount?.selectedAdAcc?.elementId &&
      isInitialized
    ) {
      getCurrentGraphData();
    }
  }, [currentPeriod]);

  useEffect(() => {
    if (
      previousPeriod.editedBy === "Calender" &&
      selectedAdAccount?.selectedAdAcc?.elementId &&
      isInitialized
    ) {
      getPreviousGraphData();
    }
  }, [previousPeriod]);

  useEffect(() => {
    let platformValue = (selectedAdAccount?.platform?.name as string) ?? "";
    platformValue = platformValue.toLowerCase();
    if (
      platformValue.includes("facebook") &&
      selectedAdSet.length > 0 &&
      attributionWindow !== ""
    ) {
      getCurrentGraphData();
      getPreviousGraphData();
      setIsInitialized(true);
    }
  }, [selectedAdSet, attributionWindow]);

  useEffect(() => {
    let platformValue = (selectedAdAccount?.platform?.name as string) ?? "";
    platformValue = platformValue.toLowerCase();
    if (
      (platformValue.includes("tiktok") ) &&
      selectedAdSet.length > 0
    ) {
      getCurrentGraphData();
      getPreviousGraphData();
      setIsInitialized(true);
    }
  }, [selectedAdSet]);

  useEffect(() => {
    let platformValue = (selectedAdAccount?.platform?.name as string) ?? "";
    platformValue = platformValue.toLowerCase();

    if (
      (platformValue.includes("google") || platformValue.includes("apple")|| platformValue.includes("dv360")) &&
      selectedCampaign?.length > 0
    ) {
      getCurrentGraphData();  
      getPreviousGraphData();
      setIsInitialized(true);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (
      yyyyMMDDFormat(currentPeriod?.startDate) !==
      yyyyMMDDFormat(sub(new Date(), { days: 14 }))
    ) {
      dispatch(
        setCurrentPeriod({
          startDate: sub(new Date(), { days: 14 }),
          endDate: new Date(),
          editedBy: "Calender",
        })
      );
      dispatch(
        setPreviousPeriod({
          startDate: sub(new Date(), { days: 29 }),
          endDate: sub(new Date(), { days: 15 }),
          editedBy: "Calender",
        })
      );
    }
    if (selectedAdAccount?.selectedAdAcc?.elementId) {
      dispatch(setElementMetrics([]));
      dispatch(setMetricLoader(true));
      dispatch(
        getElementMetrics({
          elementId: selectedAdAccount?.selectedAdAcc?.elementId,
        })
      );
    }
  }, [selectedAdAccount]);
  useEffect(() => {
    if (selectedAdSet.length === 0 && selectedCampaign?.length === 0) {
      dispatch(setMetricGraphData([]));
      dispatch(setMetricOldGraphData([]));
    }
  }, [selectedAdSet, selectedCampaign]);

  useEffect(() => {
    if (!selectedAIGroup?.id) {
      setGraphOldData([]);
      setGraphCurrentData([]);
      dispatch(setMetricGraphData([]));
      dispatch(setMetricOldGraphData([]));
    } else if (!selectedAdAccount?.selectedAdAcc?.elementId) {
      setGraphOldData([]);
      setGraphCurrentData([]);
      dispatch(setMetricGraphData([]));
      dispatch(setMetricOldGraphData([]));
    } else if (
      selectedAdAccount?.platform?.name &&
      (!selectedAdAccount.platform.name.toLowerCase().includes("google") ||
        !selectedAdAccount.platform.name.toLowerCase().includes("facebook") ||
        !selectedAdAccount.platform.name.toLowerCase().includes("tiktok"))
    ) {
      setGraphOldData([]);
      setGraphCurrentData([]);
      dispatch(setMetricGraphData([]));
      dispatch(setMetricOldGraphData([]));
    }
  }, [selectedAIGroup, selectedAdAccount]);

  return (
    <StyledOverFlow>
      <StyledCardBox>
        <StyledActionDebuggerHead>
          <StyledTitlePara>Action Impact Analysis</StyledTitlePara>
          <StyledRightContent>
            {isCompare && (
              <StyledPhasesContent>
                {periods.map((item) => (
                  <StyledPeriodPara>
                    <StyledPeriodSpan color={item.color} />
                    {item.name}
                  </StyledPeriodPara>
                ))}
              </StyledPhasesContent>
            )}
            {selectedAdAccount?.platform?.name &&
              (selectedAdAccount?.platform?.name as string)
                .toLowerCase()
                .includes("facebook") && (
                <RadioMenu
                  show={attributionMenu}
                  onHide={handleAttributionClose}
                  menuData={attributionWindowType}
                  contentStyle={{
                    width: "15.625em",
                    margin: "-.6em 0em",
                  }}
                  handelClick={onAttributionWindowChange}
                  selectedField={attributionWindow}
                >
                  <StyledBtnContainer>
                    <Button
                      variant="contained"
                      sx={{
                        padding: "0.8em 1em",
                        borderRadius: "0.5em !important",
                        justifyContent: "space-between",
                        width: "14em",
                      }}
                      onClick={(e) => handleAttributionClick(e)}
                      className="radio-dropdown-btn"
                    >
                      <div className="text-left">
                        <div
                          style={{
                            fontSize: "0.75em",
                            lineHeight: "1em",
                            color: "#000000",
                            textTransform: "lowercase",
                          }}
                        >
                          {
                            attributionWindowType.find(
                              (each: any) => each.value === attributionWindow
                            )?.label
                          }
                        </div>
                        <div
                          style={{
                            fontSize: "0.625em",
                            lineHeight: "0.75em",
                            color: "#999999",
                            marginTop: "0.6em",
                          }}
                        >
                          Attribution Window
                        </div>
                      </div>
                      <DownArrowGrey />
                    </Button>
                  </StyledBtnContainer>
                </RadioMenu>
              )}
            <ActionDebuggerDateRange />
            <Box display="flex" alignItems="center" mt="-.5em" mr="1em">
              <StyledComparePara>Compare </StyledComparePara>
              <CustomizedSwitch checked={isCompare} onClick={toggleCompare} />
            </Box>
            {isCompare && (
              <RadioMenu
                show={previousMenu}
                onHide={handelClose}
                menuData={[
                  { label: "Previous period", value: 0 },
                  { label: "Previous month", value: 1 },
                  { label: "Previous year", value: 2 },
                ]}
                contentStyle={{
                  width: "15.625em",
                  margin: "-.6em 0em",
                }}
                handelClick={onChange}
                selectedField={compareType}
              >
                <StyledBtnContainer>
                  <Button
                    variant="contained"
                    sx={{
                      padding: "1em 1.8em 1em 1.8em",
                    }}
                    onClick={(e) => handleClick(e)}
                    className="radio-dropdown-btn"
                  >
                    <ActionDebDropdown /> &nbsp; Previous{" "}
                    {periodArray[compareType].toLowerCase()} &nbsp;
                    <DownArrowBlack />
                  </Button>
                </StyledBtnContainer>
              </RadioMenu>
            )}
          </StyledRightContent>
        </StyledActionDebuggerHead>

        <StyledActionDebuggerBody>
          <StyledChartBox>
            {aiGroupLoading ||
            adAccountLoading ||
            adSetLoading ||
            metricLoader ||
            currentGraphLoading ||
            previousGraphLoading ||
            isProcessingGraphData ||
            selectedCampaignLoading ||
            attributionLoader ? (
              <ActionDebuggerChartSkeleton />
            ) : (metricGraphData.length > 0 || metricOldGraphData.length > 0) &&
              selectedAdAccount?.platform?.name &&
              isActionDebuggerOn(
                (selectedAdAccount.platform.name as string)?.toLowerCase()
              ) ? (
              <ActionDebuggerChart
                allMetrics={allMetrics}
                metricMapper={metricMapper}
                graphCurrentData={graphCurrentData}
                graphOldData={graphOldData}
                dateRange={dateRange}
                elementMetrics={isDefault ? graphMetrics : elementMetrics}
                transformGraphData={transformGraphData}
                setGraphCurrentData={setGraphCurrentData}
                setGraphOldData={setGraphOldData}
                tooltipExtraInfo={tooltipExtraInfo}
                isDefault={isDefault}
                attributionWindow={attributionWindow}
                brandSources={brandSources}
              />
            ) : (
              <ActionDebuggerEmptyChart />
            )}
          </StyledChartBox>
          <StyledSummaryBoardBox>
            <SummaryBoard
              parentLoading={
                aiGroupLoading ||
                adAccountLoading ||
                adSetLoading ||
                selectedCampaignLoading ||
                attributionLoader
              }
            />
          </StyledSummaryBoardBox>
        </StyledActionDebuggerBody>
      </StyledCardBox>
      {/* {selectedAdAccount?.platform?.name &&
        (selectedAdAccount?.platform?.name as string)
          .toLowerCase()
          .includes("facebook") && (
          <Box display="flex" alignItems="center" pt="1.3em">
            <WarningGrey
              height="1em"
              width="1em"
              style={{
                marginTop: "-.2em",
              }}
            />
            <StyledAttributionTitleText>
              Attribution Window:
            </StyledAttributionTitleText>
            <StyledAttributionSummaryText>
              {attributionWindow}
            </StyledAttributionSummaryText>
          </Box>
        )} */}
    </StyledOverFlow>
  );
};

export default React.memo(ActionDebugger);

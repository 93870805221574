import React, { useEffect } from "react";
import styled from "styled-components";
import { Box } from "@mui/system";
import { CircularProgress, Skeleton } from "@mui/material";
import { ReactComponent as DownArrow } from "../../../../assets/svg/dropdown_arrow.svg";
import { ReactComponent as UpArrow } from "../../../../assets/svg/up_dropdown_arrow.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/svg/gsai-check.svg";

import { useGsaiDropdowns } from "./hooks";
import Sort from "./Sort";
import Tooltip from "components/shared/Tooltip/CustomizedTooltip";
import FilterMultiSelect, {
  listProps,
} from "components/shared/FilterMultiSelect/FilterMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { GsaiState } from "store/types/CreativeAI/GsaiPlayground";

const Dropdowns = styled.div`
  margin-top: 22px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
`;

const OptionContainer = styled.div`
  display: flex;
  margin-left: 8px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 5px;
    margin-left: 2px;
  }

  .selected-value {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px;
    background: #fff;
    border-radius: 8px;
    width: 266px;
    cursor: pointer;
    gap: 16px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      color: #000000;
      width: 100%;
    }
  }

  div.list-container {
    z-index: 2023;
    position: absolute;
    top: calc(100% + 8px);
    width: 267px;
    max-height: 179px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #e7e9f1;
    box-shadow: 1px 2px 11px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    div.footer {
      box-shadow: 0px -2px 4px rgba(199, 199, 199, 0.25);
      background: #ffffff;
      height: 27px;
      text-align: right;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      min-height: 27px;
      width: 100%;
      padding: 5px 16px;

      button {
        border: none;
        background: #fff;
        cursor: pointer;
        color: #0869fb;
      }
    }

    ul {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      list-style-type: none;
      margin: 0;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;

      li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #313131;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        cursor: pointer;

        div.radio {
          border: 1px solid #e7e9f1;
          border-radius: 100%;
          width: 12px;
          height: 12px;
          min-width: 12px;
          min-height: 12px;
        }

        div.checkbox {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid #e7e9f1;
          width: 20px;
          min-width: 20px;
          min-height: 20px;
          border-radius: 4px;
        }

        div.content {
          width: 100%;
        }

        &.active {
          div.radio {
            border: 4px solid #3f64fa;
            border-radius: 100%;
          }
          div.checkbox {
            border: 1px solid #3f64fa;
            background: #3f64fa;
          }
        }
      }
    }
  }
`;

type Props = {
  label: string;
  record: any;
  getComponent: any;
  selectedName: string;
};

function Dropdown({ label, record, getComponent, selectedName }: Props) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { data, isLoading } = record;

  function handleClose() {
    setIsOpen(false);
  }

  useEffect(() => {
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  return (
    <Container
      onClick={(event: any) => {
        event.stopPropagation();
      }}
    >
      <label>{label}</label>
      <div
        className="selected-value"
        onClick={(event: any) => {
          event.stopPropagation();
          setIsOpen((value) => !value);
        }}
      >
        {isLoading ? (
          <>
            <span>
              <Skeleton variant="text" />
            </span>
            <Box sx={{ display: "flex" }}>
              <CircularProgress
                style={{ width: "20px", height: "20px", color: "black" }}
              />
            </Box>
          </>
        ) : (
          <>
            {selectedName ? (
              <Tooltip
                textTruncate={22}
                title={selectedName}
                disableFocusListener
                disableTouchListener
                placement="top"
              >
                {selectedName}
              </Tooltip>
            ) : (
              <span>Search {label}</span>
            )}
            {isOpen ? <UpArrow /> : <DownArrow />}
          </>
        )}
      </div>
      {isOpen ? (
        <div className="list-container">
          <ul>
            {data &&
              data.map((item: any) => <>{getComponent(item, handleClose)}</>)}
          </ul>
        </div>
      ) : null}
    </Container>
  );
}

function getAiGroupComponent(selected: any, setAiGroups: any, aiGroups: any) {
  return (props: any, callback?: any) => {
    return (
      <li
        className={props.id === selected.id ? "active" : ""}
        key={selected.id}
        onClick={() => {
          setAiGroups({
            ...aiGroups,
            selected: props,
          });
          if (callback && typeof callback === "function") callback();
        }}
      >
        <div className="radio" />
        {props.name}
      </li>
    );
  };
}

function getAdAccountGroup(
  selected: any,
  handleClick: any,
  key: string,
  data: any
) {
  return (props: any, callback?: any) => {
    return (
      <li
        className={props[key] === selected[key] ? "active" : ""}
        key={selected.id}
        onClick={() => {
          handleClick({
            ...data,
            selected: props,
          });
          if (callback && typeof callback === "function") callback();
        }}
      >
        <div className="radio" />
        <div className="content">
          <Tooltip
            textTruncate={22}
            title={props.name}
            disableFocusListener
            disableTouchListener
            placement="top"
          >
            {props.name}
          </Tooltip>
          <div>{props[key]}</div>
        </div>
      </li>
    );
  };
}

function AdGroupDropdown({
  record,
  handleClick,
  selectedAdGroups,
  handleSave,
}: any) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { data, isLoading } = record;
  return (
    <Container
      onClick={(event: any) => {
        event.stopPropagation();
      }}
    >
      <label>Ad Group</label>
      <div
        className="selected-value"
        onClick={(event: any) => {
          event.stopPropagation();
          setIsOpen((value) => !value);
        }}
      >
        {isLoading ? (
          <>
            <span>
              <Skeleton variant="text" />
            </span>
            <Box sx={{ display: "flex" }}>
              <CircularProgress
                style={{ width: "20px", height: "20px", color: "black" }}
              />
            </Box>
          </>
        ) : (
          <>
            <span>
              {" "}
              {
                Object.values(selectedAdGroups).filter((ad) => ad === true)
                  .length
              }{" "}
              / {record?.data?.length} ad group selected
            </span>
            {isOpen ? <UpArrow /> : <DownArrow />}
          </>
        )}
      </div>
      {isOpen ? (
        <div className="list-container">
          <ul>
            {data.map((item: any) => (
              <li
                className={selectedAdGroups[item.id] ? "active" : ""}
                key={item.id}
                onClick={() => {
                  handleClick((state: any) => ({
                    ...state,
                    [item.id]: !state[item.id],
                  }));
                }}
              >
                <div className="checkbox">
                  {selectedAdGroups[item.id] ? <CheckIcon /> : null}
                </div>
                <div className="content">
                  <Tooltip
                    textTruncate={22}
                    title={item.label}
                    disableFocusListener
                    disableTouchListener
                    placement="top"
                  >
                    {item.label}
                  </Tooltip>
                  <div>{item.id}</div>
                </div>
              </li>
            ))}
          </ul>
          {data.length > 0 && (
            <div className="footer">
              <button
                onClick={() => {
                  handleSave();
                  setIsOpen(false);
                }}
              >
                Save
              </button>
            </div>
          )}
        </div>
      ) : null}
    </Container>
  );
}

function Components() {
  const {
    aiGroups,
    adAccounts,
    campaigns,
    adGroups,
    selectedAdGroups,
    setAiGroups,
    setAdAccounts,
    setCampaigns,
    setSelectedAdGroups,
    handleSave,
  } = useGsaiDropdowns();
  const dispatch = useDispatch();
  const { filteredAds } = useSelector(
    (state: { gsaiPlayground: GsaiState }) => state.gsaiPlayground
  );

  const [fields, setFields] = React.useState<listProps[]>([
    {
      key: "LIVE",
      value: false,
      label: "Live",
    },
    {
      key: "APPROVED",
      value: false,
      label: "Approved",
    },
    {
      key: "REJECTED",
      value: false,
      label: "Rejected",
    },
    {
      key: "UNDER_REVIEW",
      value: false,
      label: "In Review",
    },
  ]);

  return (
    <div className="flex justify-content-between align-items-end">
      <Dropdowns>
        <Dropdown
          label="AI Group"
          record={aiGroups}
          getComponent={getAiGroupComponent(
            aiGroups.selected,
            setAiGroups,
            aiGroups
          )}
          selectedName={aiGroups.selected?.name || ""}
        />
        <Dropdown
          label="Ad Account"
          record={adAccounts}
          getComponent={getAdAccountGroup(
            adAccounts.selected,
            setAdAccounts,
            "adAccountId",
            adAccounts
          )}
          selectedName={adAccounts.selected?.name || adAccounts.selected?.id}
        />
        <Dropdown
          label="Campaign"
          record={campaigns}
          getComponent={getAdAccountGroup(
            campaigns.selected,
            setCampaigns,
            "id",
            campaigns
          )}
          selectedName={campaigns.selected?.name || ""}
        />
        <AdGroupDropdown
          record={adGroups}
          selectedAdGroups={selectedAdGroups}
          handleClick={setSelectedAdGroups}
          handleSave={handleSave}
        />
      </Dropdowns>
      <OptionContainer>
        <FilterMultiSelect
          filterName="Filter Ads that are"
          fields={fields}
          handleSave={(data) => {
            let filteredMappedArr = data.reduce((acc: any, { value, key }) => {
              if (value) acc.push(key);
              return acc;
            }, []);
            setFields(data);
            let filterAds;
            if (filteredMappedArr.length === 0) {
              filterAds = filteredAds.map((f: any) => ({
                ...f,
                isVisible: true,
              }));
            } else {
              filterAds = filteredAds.map((f: any) => {
                if (filteredMappedArr.includes(f.reviewStatus)) {
                  return {
                    ...f,
                    isVisible: true,
                  };
                } else if (filteredMappedArr.includes("LIVE") && !f.isDraft) {
                  return {
                    ...f,
                    isVisible: true,
                  };
                } else {
                  return {
                    ...f,
                    isVisible: false,
                  };
                }
              });
            }
            dispatch({
              type: "UPDATE_GSAI_STORE",
              payload: {
                filteredAds: filterAds,
              },
            });
          }}
        />
        <Sort />
      </OptionContainer>
    </div>
  );
}

export default Components;

import { Box, styled } from "@mui/material";
import { ReactComponent as InfoError } from "../../../../../assets/cai/svg/info_error_warning.svg";

interface StyledTextSpaceType {
  selected: boolean;
}
export const StyledTextSpace = styled(Box)<StyledTextSpaceType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  border: ${({ selected }) => `1px solid ${selected ? "#0869FB" : "#efefef"}`};
  box-shadow: 0px 4.83018px 16.9056px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;
export const StyledVariantTag = styled(Box)`
  width: 2.6875em;
  height: 2.6875em;
  position: relative;
`;

export const StyledPerformanceText = styled(Box)<{
  color: string;
}>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.625em;
  line-height: 12px;
  color: ${({ color }) => color};
`;

export const StyledInfoError = styled(InfoError)`
  position: absolute;
  left: 30%;
  top: 37%;
  width: 40%;
  height: 40%;
`;

export const StyledSpeedoLoader = styled("img")`
  position: absolute;
  left: 27%;
  top: 35%;
  width: 45%;
  height: 45%;
`;

export const StyledSpeedoContent = styled("div")`
  position: absolute;
  left: 19%;
  top: 45%;
  display: flex;
  align-items: baseline;
`;

import { OptionType } from "components/CreativeAI/Facebook/CreateVariants/Editor/Editor.types";
import { DynamicFilterItemType } from "../../shared/DynamicFilterBox/types";

export const handlingCheck = ({
  value,
  filterState,
  index,
  checked,
}: {
  value: OptionType;
  filterState: DynamicFilterItemType[];
  index: number;
  checked: boolean;
}) => {
  const localFilterState = [...filterState];
  if (checked) {
    const allSelected = localFilterState[index].selected?.filter(
      (item: OptionType) => item?.value !== value?.value
    );
    localFilterState[index].selected =
      allSelected.length > 0 ? allSelected : undefined;
  } else {
    const selected = localFilterState[index].selected
      ? [...localFilterState[index].selected]
      : [];
    selected.push(value);
    localFilterState[index].selected = selected;
  }
  return localFilterState;
};

export const checkSupportedAdType = (createSpec: any) => {
  const { object_story_spec, asset_feed_spec } = createSpec;
  if (object_story_spec) {
    const { link_data, video_data } = object_story_spec;
    if (link_data) {
      const { child_attachments, collection_thumbnails } = link_data;
      if (child_attachments || collection_thumbnails) {
        return {
          isSupported: false,
        };
      } else {
        return { isSupported: true };
      }
    } else if (video_data) {
      const { child_attachments, collection_thumbnails } = video_data;
      if (child_attachments || collection_thumbnails) {
        return {
          isSupported: false,
        };
      } else {
        return { isSupported: true };
      }
    } else if (
      asset_feed_spec &&
      Object.keys(asset_feed_spec).length > 0 &&
      asset_feed_spec?.ad_formats?.[0] !== "CAROUSEL"
    ) {
      return { isSupported: true };
    } else {
      return {
        isSupported: false,
      };
    }
  }
  return {
    isSupported: false,
  };
};

export const copyVariantNameLogic = (payload: any) => {
  // let copyNumber = 1;
  let sliceString = payload?.settings?.variantName?.split(")");

  if (sliceString.length > 0 && sliceString[0].includes("copy")) {
    // copyNumber = parseInt(sliceString[0].match(/\d+/)[0]) + 1;
    sliceString = sliceString.splice(1).join("");
  }
  return {
    ...payload,
    settings: {
      ...payload?.settings,
      variantName: `(copy)${sliceString}`,
    },
  };
};

export const utgPlaceholders = [
  "Include a 20% off offer in the Ad copy and highlight the product’s high value.",
  "Keep it more quirky. Replace $2.99/MEAL with $4.99/MEAL. Make the primary text and the headline interrelated.",
  "The Ad copy should highlight the festive theme: New Year. Please don’t use the first person. Keep it generic. Add FOMO in the description.",
];

import { SupportedPlatforms } from "../hooks";
interface Props {
  platform: SupportedPlatforms;
  buttonText?: string;
  href: any;
}
function Option({
  platform,
  buttonText = "Connect",
  href,
}: Props): JSX.Element {
  return (
    <div className="content-option">
      <div className={`option-logo ${platform.key}`}>{platform.logo}</div>
      <span className="option-title">
        {platform.title === "Facebook" ? "Meta" : platform.title} Ads
      </span>
      <span className="option-message">
        Connect with your{" "}
        {platform.title === "Facebook" ? "Meta" : platform.title} account.
        <br /> To access all your ad accounts easily
      </span>
      <a href={href[platform.key]}>{buttonText}</a>
    </div>
  );
}

export default Option;

import { Box } from "@mui/material";
import React from "react";
import { StyledPerformanceText } from "./styled";
import { ReactComponent as CustomerIcon } from "../../../../../assets/cai/svg/customer.svg";
import { ReactComponent as BrandIcon } from "../../../../../assets/cai/svg/brand.svg";
import { ReactComponent as BenifitIcon } from "../../../../../assets/cai/svg/benifit.svg";
import { ReactComponent as OfferIcon } from "../../../../../assets/cai/svg/offer.svg";
import { PerformanceScoreType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";

export default function PerformanceScoreInfo({
  handlePerformancePopoverOpen,
  handlePerformancePopoverClose,
  infoItem,
}: {
  handlePerformancePopoverOpen: any;
  handlePerformancePopoverClose: any;
  infoItem: PerformanceScoreType;
}) {
  const performanceObjectMapping: any = {
    "Medium Performance": {
      textColor: "#F99F35",
      bgColor: "rgba(255, 240, 222, 0.8)",
      text: "MEDIUM",
    },
    "High Performance": {
      textColor: "#0869FB",
      bgColor: "#EDF4FF",
      text: "HIGH",
    },
    "Low Performance": {
      textColor: "#E84B47",
      bgColor: "#FFF0DECC",
      text: "LOW",
    },
    No_Text: {
      textColor: "#9798A1",
      bgColor: "#EAEAEC",
      text: "-",
    },
  };

  const isKeyHighlights =
    infoItem?.offer_label ||
    infoItem?.brand_centric ||
    infoItem?.benefit_label ||
    infoItem?.customer_centric;

  return (
    <Box
      display="flex"
      sx={{
        padding: isKeyHighlights
          ? "0.2em .2em 0.2em 1em"
          : "0.4em .5em 0.4em 1em",
        background:
          performanceObjectMapping?.[infoItem?.performance_labels]?.bgColor,
        borderRadius: "7px 0px",
      }}
      alignItems="center"
      alignSelf="flex-start"
      onMouseEnter={handlePerformancePopoverOpen}
      onMouseLeave={handlePerformancePopoverClose}
    >
      <StyledPerformanceText
        color={
          performanceObjectMapping?.[infoItem?.performance_labels]?.textColor
        }
      >
        Performance Score:{" "}
        {performanceObjectMapping?.[infoItem?.performance_labels]?.text}
      </StyledPerformanceText>

      {isKeyHighlights && (
        <Box
          display="flex"
          ml=".5em"
          sx={{
            background: "#FFFFFF",
            borderRadius: "6px 0px",
            gap: ".4em",
            padding: "0.2em .5em",
          }}
          alignItems="center"
        >
          {infoItem?.offer_label && <OfferIcon />}
          {infoItem?.brand_centric && <BrandIcon />}
          {infoItem?.benefit_label && <BenifitIcon />}
          {infoItem?.customer_centric && <CustomerIcon />}
        </Box>
      )}
    </Box>
  );
}

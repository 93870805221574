import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "./assests/close.svg";
import { ReactComponent as Filecloud } from "./assests/filecloud.svg";
import { ReactComponent as IconError } from "./assests/error-cross.svg";
import { ReactComponent as Info } from "./assests/info.svg";
import CSVFile from "./assests/csv/sample.csv";
import { AdAccountState } from "store/types/adaccount";
import {
  isViewerRole,
  isEditorRole,
  getRolesHierarchy,
} from "utils/commonFunctions";
import { RolesWrapper } from "utils/rolesWrapper";
import BackButton from "components/ManualActionButton/BackButton";
import isString from "lodash/isString";

interface FileDropModalProps {
  title:string,
  description:string,
  open: boolean;
  onClose: () => void;
  onFileUpload: (files: File[]) => void;
  onSubmit: (file: any) => void;
  fileUploaded: any;
  onBack?: () => void;
  isManualActionButton:boolean;
  renderErrorScreen?:(error:any)=>JSX.Element
}

function TriggerActionsFileUpload({
  title,
  description,
  open,
  onClose,
  onFileUpload,
  onSubmit,
  fileUploaded,
  onBack,
  isManualActionButton,
  renderErrorScreen
}: FileDropModalProps) {
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { submitManualActionState } = useSelector(
    (state: any) => state.manualActionReducer
  );
  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);

    const files = Array.from(e.dataTransfer.files);
    onFileUpload(files);
  };

  const handleSelectFiles = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onClearFileSelection = () => onFileUpload([]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 3,
          fontSize: "14px",
          borderRadius: "1em",
          width: "49.25em",
        },
      }}
      sx={{
        "& *": {
          fontFamily: "Inter",
        },
      }}
    >
      <Box>
        <Grid container mb={2}>
          <Grid item xs={11.25}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontFamily: "Inter",
                fontSize: "1.25em",
                fontWeight: 600,
                lineHeight: "2em",
                letterSpacing: "-0.01875em",
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={0.75}>
            <IconButton
              onClick={onClose}
              disableRipple
              disableTouchRipple
              sx={{ padding: "0" }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <h5
              style={{
                fontSize: "0.875em",
                fontWeight: "400",
                color: "#5C6674",
              }}
            >
              {description}
            </h5>
          </Grid>
        </Grid>

        <Box
          sx={{
            fontFamily: "Inter",
            fontSize: "0.875em",
            fontWeight: 400,
            lineHeight: "1.375em",
            letterSpacing: "0.00875em",
            display: "flex",
            alignItems: "center",
            mb: 1,
          }}
        >
          Upload CSV File
        </Box>
        <DialogContent
          className={`${isDragOver ? "dragOver" : ""}`}
          sx={{
            border: ".0625em dashed #C8CED7 ",
            borderRadius: "0.75em",
            textAlign: "center",
            padding: "1.25em",
            "&.dragOver": {
              border: ".125em dashed #1976D2",
            },
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            padding="1.25em 0"
          >
            {submitManualActionState?.loading === true && <CircularProgress />}
            {submitManualActionState?.loading === false && (
              <>
                <Filecloud />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="60%"
                  sx={{ mt: 1 }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "0.875em",
                      fontWeight: 400,
                      color: "#5C6674",
                      letterSpacing: "0.00875em",
                    }}
                  >
                    {fileUploaded?.[0]?.name ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: "0.2em",
                        }}
                      >
                        <span>{fileUploaded?.[0]?.name}</span>
                        <Button
                          sx={{
                            color: "var(--color-brand-primary, #0869FB)",
                            fontFamily: "Inter",
                            fontSize: ".875em",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "1.375em",
                            letterSpacing: ".0109em",
                            textTransform: "capitalize",
                          }}
                          onClick={onClearFileSelection}
                        >
                          Remove File
                        </Button>
                      </Box>
                    ) : (
                      <>
                        <RolesWrapper
                          disabled={
                            getRolesHierarchy(brandRoles) === "viewer" ||
                            getRolesHierarchy(brandRoles) === "editor"
                          }
                        >
                          <Button
                            sx={{
                              color: "#0869FB",
                              textTransform: "none",
                            }}
                            variant="text"
                            onClick={handleSelectFiles}
                            // disabled={
                            //   isViewerRole(brandRoles) ||
                            //   isEditorRole(brandRoles)
                            // }
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                onFileUpload(Array.from(e.target.files || []))
                              }
                              ref={fileInputRef}
                            />
                            Click to upload
                          </Button>
                        </RolesWrapper>
                        or drag and drop
                      </>
                    )}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
        {submitManualActionState?.loading === false &&
          submitManualActionState?.success === true && (
            <Box sx={{ fontSize: "0.75em", color: "#EF5854", mt: 1 }}>
              Recommendation Published. Please visit “Action Recommendation” to
              track status.
            </Box>
          )}
        {submitManualActionState?.loading === false &&
          submitManualActionState?.success === false && (!renderErrorScreen ? (
            <Box
              sx={{
                fontSize: "0.75em",
                color: "#fb0863",
                mt: 1,
                display: "flex",
                alignItems: "center",
                gap: "0.5em",
              }}
            >
              <IconError />
              {isString(submitManualActionState?.errorMessage)
                ? submitManualActionState.errorMessage
                : "Something went wrong. Please try again after sometime."}
            </Box>
          ) : renderErrorScreen(submitManualActionState?.errorMessage))}
        {isManualActionButton && <Grid
          container
          sx={{
            fontFamily: "Inter",
            fontSize: "0.75em",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "1.25em",
            letterSpacing: "0.0075em",
            mt: 1,
            color: "#5C6674",
          }}
        >
          <Grid item xs={8.5}>
            <a
              style={{
                color: "#0869FB",
                fontWeight: "500",
                textDecoration: "none",
              }}
              target={"_blank"}
              href="https://docs.google.com/document/d/16g1ZTtcMhPFot2MbG3-eZPBpiXYI0YeUNzZvwG066UI/edit"
              rel="noreferrer"
            >
              Click here
            </a>{" "}
            to know the steps to follow
          </Grid>
          <Grid item xs={3.5}>
            <a
              style={{
                color: "#0869FB",
                fontWeight: "500",
                textDecoration: "none",
              }}
              href={CSVFile}
              download="CSVFile.csv"
            >
              Download{" "}
            </a>{" "}
            sample input file
          </Grid>
        </Grid>}

        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            marginTop: "2em",
          }}
        >
          {onBack ? <BackButton onClick={onBack} /> : <div />}
          <RolesWrapper
            disabled={
              getRolesHierarchy(brandRoles) === "viewer" ||
              getRolesHierarchy(brandRoles) === "editor"
            }
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                background: "#0869FB",
                textTransform: "none",
                borderRadius: "0.5rem",
                py: 1,
                px: 2.5,
                fontWeight: 600,
                fontFamily: "Inter",
              }}
              onClick={onSubmit}
              // disabled={isViewerRole(brandRoles) || isEditorRole(brandRoles)}
            >
              Submit
            </Button>
          </RolesWrapper>
        </Box>
      </Box>
    </Dialog>
  );
}
export default TriggerActionsFileUpload;

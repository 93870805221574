import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, useReactFlow, Position } from "react-flow-renderer";
import Box from "@mui/material/Box";
import Stack from "@mui/system/Stack";
import LoaderContainer from "../../common/LoaderContainer";
import LoaderRightStrip from "../../common/LoaderRightStrip";

import { ReactComponent as CohortsAudience } from "../Assets/svgs/audienceExplorer/CohortsAudience.svg";
import { ReactComponent as ThreedAudienceAudience } from "../Assets/svgs/audienceExplorer/ThreedAudienceAudience.svg";
import { ReactComponent as AudienceAndQualityAudience } from "../Assets/svgs/audienceExplorer/AudienceAndQualityAudience.svg";
import { ReactComponent as Line } from "../Assets/svgs/str-line.svg";
import { ReactComponent as DividerLarge } from "../Assets/svgs/dividerLarge.svg";
import { mixPanelInputCommonFunction } from "../../utils/MixpanelInputCommonFunction";
import { usePolling } from "../../hooks/usePolling";
import { audienceExplorerData } from "../constants/loader";
import { TaiContext } from "Pages/TargetingAIPlaygroundBeta/TaiContextProvider";
import { setKeywordTuningJson, setPolling } from "../../../../store/actions/targetingAI";
import {
  getTargetingAICluster,
  setKeywordTuningData,
} from "../../../../store/actions/targetingAI";
import { getTAICohortICP } from "store/actions/targetingICP";
import { getJSONDataFromURL } from "utils/jsonFromURL";
import { CommonState } from "store/types/common";

export default function LoaderCalibration({
  data,
  isConnectable,
}: {
  data: any;
  isConnectable: boolean;
}) {
  const dispatch = useDispatch();
  const { setNodes, setEdges, cohortType } = useContext(TaiContext) as any;

  const keywordTuningData = useSelector((state: any) => state.keywordTuning);
  const polling = useSelector((state: any) => state.taiCommon.polling);
  const adaccount = useSelector((state: any) => state.adaccount);
  const { selectedBrand, selectedGroup, selectedAdAccount } = adaccount;
  const currentPage = useSelector((state: any) => state.taiCommon.currentPage);
  const loaderState = useSelector((state: any) => state.taiCommon.loader);
  const { TypeOfCohort } = useSelector(
    (state: any) => state.calibration.formData
  );
  const aiInitiation = useSelector((state: any) => state.aiInitiation.apiData);
  const { selectedAgency } = useSelector(
    (state: { common: CommonState }) => state.common
  );
  const aiInitiationFormData = useSelector(
    (state: any) => state.aiInitiation.formData
  );

  const [loadingId, setLoadingId] = useState(0);

  const { setStartPolling } = usePolling(2000, (error: boolean) => {
    if (error) {
      //error
      return;
    }
    if (polling.requestId !== "") {
      if (TypeOfCohort === "ICP") {
        dispatch(
          getTAICohortICP(polling.requestId, async (res: any, err: any) => {
            // let url =
            //   "https://targeting-ai.s3.ap-south-1.amazonaws.com/icp_fb_targetings_recms/v0_1_0/requests/04cd32c5-0ec9-41a6-8cb6-d6f2101b946c.json";
            // setStartPolling(false);
            // dispatch(setPolling({ name: "", requestId: "" }));
            // dispatch(setKeywordTuningData(url));
            const status = res?.data?.status_list[0].status;
            if (status === "failed") {
              setStartPolling(false);
              data.onClick("failed");
              dispatch(setPolling({ name: "", requestId: "" }));
            }
            if (!err && status === "completed") {
              setStartPolling(false);
              dispatch(setPolling({ name: "", requestId: "" }));
              dispatch(
                setKeywordTuningData(res.data.status_list[0].result?.json_url)
              );
            }
          })
        );
      } else {
        dispatch(
          getTargetingAICluster(
            polling.requestId,
            async (res: any, err: any) => {
              const status = res.data.message.status_list[0].status;
              if (status === "FAILED") {
                setStartPolling(false);
                data.onClick("FAILED");
                dispatch(setPolling({ name: "", requestId: "" }));
              }
              if (!err && status === "COMPLETED") {
                setStartPolling(false);
                dispatch(setPolling({ name: "", requestId: "" }));
                dispatch(
                  setKeywordTuningData(
                    res.data.message.status_list[0].result_file_url
                  )
                );
                dispatch(setKeywordTuningJson(res.data.message.status_list[0].result));
              }
            }
          )
        );
      }
    }
  });

  useEffect(() => {
    if (
      polling.name === "keywordTuning" ||
      polling.name === "icpKeywordTuning"
    ) {
      setStartPolling(true);
    }
  }, [polling, setStartPolling]);

  useEffect(() => {
    if (currentPage > data.index) {
      setLoadingId(5);
    } else {
      if (loadingId < 4 && currentPage === data.index) {
        setTimeout(() => {
          setLoadingId(loadingId + 1);
        }, 2000);
      } else if (Object.keys(keywordTuningData).length > 0 && loadingId === 4) {
        setLoadingId(5);
      }
    }
  }, [loadingId, currentPage, keywordTuningData]);

  useEffect(() => {
    if (Object.keys(keywordTuningData).length === 0) {
      setLoadingId(0);
    }
  }, [keywordTuningData]);

  const audienceExplorerContinueClicked = () => {
    data.loaderContinue(dispatch, loaderState, setNodes, setEdges);
    mixPanelInputCommonFunction(
      "TAI Audience Loader Clicked",
      selectedBrand?.name,
      selectedAgency?.name,
      {
        brand_id: selectedBrand?.id,
        agency_id: selectedBrand?.agencyId,
        ai_group_id: aiInitiationFormData?.selectedAiGroup?.id,
        ai_group_name: aiInitiationFormData?.selectedAiGroup?.name,
        ad_account_name: aiInitiation?.adAccount?.name,
        ad_account_id: aiInitiation?.adAccount?.id,
        tai_flow: cohortType,
      }
    );
  };
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div
        className="fixed inset-0 z-50 overflow-y-auto outline-none d-flex focus:outline-none w-100 h-100"
        style={{
          position: "absolute",
          zIndex: "100",
        }}
      >
        <LoaderContainer
          steps={audienceExplorerData.steps}
          loadingId={loadingId}
          continueProps={{
            onClick: audienceExplorerContinueClicked,
            disabled: loadingId < 5,
            className: currentPage === data.index ? "" : "d-none",
          }}
          loaderGifProps={{
            src: audienceExplorerData.gif,
            alt: "AudienceExplorer",
            style: { height: "17.5rem", width: "21rem" },
          }}
          closeProps={{
            className: currentPage > data.index ? "" : "d-none",
            onClick: () =>
              data.loaderContinue(dispatch, loaderState, setNodes, setEdges),
          }}
          header="Audience Explorer"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Line />
            <LoaderRightStrip
              items={[
                {
                  title: "Cohorts",
                  text: "Audiences are generated in the form of cohorts i.e. a collection of interest keywords",
                  svg: <CohortsAudience />,
                },
              ]}
            />

            <DividerLarge />

            <Stack spacing={10}>
              <LoaderRightStrip
                items={[
                  {
                    title: "3D Audience Visualiser",
                    text: "Visualise the audiences in a 3D spatial view and analyse the relevance between cohorts and keywords.",
                    svg: <ThreedAudienceAudience />,
                  },
                ]}
              />

              <LoaderRightStrip
                items={[
                  {
                    title: "Audiences & Quality Indicators",
                    text: "View audiences with their quality indicators like Performance Score, Relevance Score and Potential Audience Size.",
                    svg: <AudienceAndQualityAudience />,
                  },
                ]}
              />
            </Stack>
          </Box>
        </LoaderContainer>
      </div>
    </>
  );
}

import { takeLatest, call, takeEvery, put } from "redux-saga/effects";
import { BASE_URL, CROSS_PLATFORM_URL } from "../../utils/path";
import {
  crossPlatform,
  crossPlatformV2,
  groupV2API,
} from "../../MappedAPI/MappedAPI";
import HttpService from "../../HttpService/HttpService";
import {
  GET_OPEN_SEARCH_AI_DATA,
  SET_OPEN_SEARCH_AI_DATA,
  SubmitManualAction,
  SUBMIT_FORM_DATA_STATE,
  SUBMIT_MANUAL_ACTION_FORM_DATA,
  SUBMIT_FILE_MANUAL_ACTION,
  DownloadCSVForRecommendationsDV360,
  DOWNLOAD_CSV_RECOMMENDATION_FOR_DV360,
  SUBMIT_FILE_RECOMMENDATION_FOR_DV360,
  SubmitFileForRecommendationDV360,
} from "../types/manualaction";

function* submitManualActionData(action: any): any {
  const { user, formData, callback } = action.payload;
  console.log(user, formData);
  yield put({
    type: SUBMIT_FORM_DATA_STATE,
    payload: {
      loading: true,
      success: false,
      error: "",
    },
  });

  try {
    // const urlParams = `${crossPlatform}/brand/${brandId}/ai-group`;
    // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    // const { adsets: data } = yield getAdSetList.data;
    // yield put({ type: GET_COHORT, payload: adSet });
    const urlParams = `${crossPlatform}/manual-action`;
    const response = yield call(
      HttpService.post,
      // `https://8e49541d-036f-4b31-8170-15823ac667f6.mock.pstmn.io`,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      formData
    );
    console.log("congrates", response);
    if (response.data.success) {
      yield put({
        type: SUBMIT_FORM_DATA_STATE,
        payload: {
          loading: false,
          success: true,
          error: false,
        },
      });
      callback(response.data, null);
    } else {
      yield put({
        type: SUBMIT_FORM_DATA_STATE,
        payload: {
          loading: false,
          success: false,
          error: true,
          errorMessage: response.data.message,
        },
      });
      callback(response.data, response.data.message);
    }
  } catch (error: any) {
    console.log("function*submitManualActionData ~ error", error);
    yield put({
      type: SUBMIT_FORM_DATA_STATE,
      payload: {
        loading: false,
        success: false,
        error: true,
        errorMessage: error,
      },
    });
  }
}
function* openSearchAiData(action: any): any {
  yield put({
    type: "SET_OPEN_SEARCH_AI_DATA_STATE",
    payload: {
      loading: true,
      success: false,
      error: "",
    },
  });

  const { user, metaData } = action.payload;

  try {
    // const urlParams = `${crossPlatform}/brand/${brandId}/ai-group`;
    // const response = yield call(HttpService.get, CROSS_PLATFORM_URL, urlParams);
    // const { adsets: data } = yield getAdSetList.data;
    // yield put({ type: GET_COHORT, payload: adSet });
    const urlParams = `${crossPlatformV2}/manual-action/search`;
    const response = yield call(
      HttpService.post,
      // `https://8e49541d-036f-4b31-8170-15823ac667f6.mock.pstmn.io`,
      // `https://0781-2401-4900-41c1-60c2-8442-b814-5dd9-a3a8.in.ngrok.io`,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      metaData
    );
    console.log("congrates", response);
    if (response.status === 200) {
      yield put({ type: SET_OPEN_SEARCH_AI_DATA, payload: response.data.data });
      yield put({
        type: "SET_OPEN_SEARCH_AI_DATA_STATE",
        payload: {
          loading: false,
          success: true,
          error: "",
        },
      });
    }
  } catch (error: any) {
    alert("API Failed " + error?.message);
    yield put({
      type: "SET_OPEN_SEARCH_AI_DATA_STATE",
      payload: {
        loading: false,
        success: false,
        error: true,
      },
    });
    console.log("function*getGroups ~ error", error);
  }
}

function* submitFileManualAction(action: any): any {
  let {
    payload: { brandId, user, file, platformId, callback },
  } = action;
  let data = new FormData();
  data.append("file", file);

  yield put({
    type: SUBMIT_FORM_DATA_STATE,
    payload: {
      loading: true,
      success: false,
      error: "",
    },
  });

  try {
    const urlParams = `/v2/api/manual-action/bulk/${brandId}/${platformId}`;
    const response = yield call(
      HttpService.put,
      CROSS_PLATFORM_URL,
      urlParams,
      user,
      data
    );
    console.log("congrates", response);
    if (response.data.success) {
      yield put({
        type: SUBMIT_FORM_DATA_STATE,
        payload: {
          loading: false,
          success: true,
          error: false,
        },
      });
      callback?.(response.data, null);
    } else {
      yield put({
        type: SUBMIT_FORM_DATA_STATE,
        payload: {
          loading: false,
          success: false,
          error: true,
          errorMessage: response.data.message,
        },
      });
      callback?.(response.data, response.data.message);
    }
  } catch (error: any) {
    console.log("function*submitFileManualAction ~ error", error);
    const errorMessage = error?.response?.data?.message || error;
    yield put({
      type: SUBMIT_FORM_DATA_STATE,
      payload: {
        loading: false,
        success: false,
        error: true,
        errorMessage,
      },
    });
    callback?.(error?.response?.data, errorMessage);
  }
}

function* uploadCSVForDV360Recommendations(action:SubmitFileForRecommendationDV360):any{
  //Upload Logic to be added 
  let {payload:{file},callback} = action;
  let data = new FormData();
  data.append("file", file);
  yield put({
    type: SUBMIT_FORM_DATA_STATE,
    payload: {
      loading: true,
      success: false,
      error: "",
    },
  });
  try {
    const urlParams = `/v2/api/manual-action/dv360/upload`;
    const response = yield call(HttpService.post,BASE_URL,urlParams,"",data);
    if (response.data.success) {
      yield put({
        type: SUBMIT_FORM_DATA_STATE,
        payload: {
          loading: false,
          success: true,
          error: false,
        },
      });
      callback?.(response.data, null);
    } else {
      yield put({
        type: SUBMIT_FORM_DATA_STATE,
        payload: {
          loading: false,
          success: false,
          error: true,
          errorMessage: response.data.message,
        },
      });
      callback?.(response.data, response.data.message);
    }
  } catch (error:any) {
    const errorMessage = error?.response?.data?.message;
    yield put({
      type: SUBMIT_FORM_DATA_STATE,
      payload: {
        loading: false,
        success: false,
        error: true,
        errorMessage,
      },
    });
    callback?.(error?.response?.data, errorMessage); 
  }

} 

function* downloadCSVForDV360Recommendations(action:DownloadCSVForRecommendationsDV360):any{
  //Download Logic to be added
  let {payload:{aggregation_concept_id,start_date,end_date},callback} = action;
  try {
    const urlParams = `/v2/api/manual-action/dv360/download`;
    const response = yield call(HttpService.post,BASE_URL,urlParams,"",{aggregation_concept_id:aggregation_concept_id,start_date:start_date,end_date:end_date});
    callback(response,false);
  } catch (error:any) {
     console.log(error);    
     callback(error,true);
  }
}


export function* manualActionWatcher() {
  yield takeLatest(SUBMIT_MANUAL_ACTION_FORM_DATA, submitManualActionData);
  yield takeLatest(SUBMIT_FILE_MANUAL_ACTION, submitFileManualAction);
  yield takeLatest(GET_OPEN_SEARCH_AI_DATA, openSearchAiData);
  yield takeEvery(DOWNLOAD_CSV_RECOMMENDATION_FOR_DV360,downloadCSVForDV360Recommendations);
  yield takeEvery(SUBMIT_FILE_RECOMMENDATION_FOR_DV360,uploadCSVForDV360Recommendations);
}

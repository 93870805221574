import { Box, Paper, Popover, Typography } from "@mui/material";
import React from "react";
import PerformanceTooltipItem from "./PerformanceTooltipItem";
import { StyledText } from "components/shared";
import HighlightItem, { HighlightItemProp } from "./HighlightItem";
import { ReactComponent as OfferIcon } from "../../../../assets/cai/svg/offer.svg";
import { ReactComponent as BrandIcon } from "../../../../assets/cai/svg/brand.svg";
import { ReactComponent as BenifitIcon } from "../../../../assets/cai/svg/benifit.svg";
import { ReactComponent as CustomerIcon } from "../../../../assets/cai/svg/customer.svg";
import { ReactComponent as BulbIcon } from "../../../../assets/cai/svg/bulb.svg";

import { ColorMapping } from "./performance.constant";
import { StyledFooter, StyledPaper, StyledPopover } from "./styled";
import { PerformanceScoreType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";

interface objectType {
  [key: string]: HighlightItemProp;
}

export const highlightConstObject: objectType = {
  offer: {
    text: "Offer Centric",
    bgColor: "#FDF2FA",
    textColor: "#EB59AC",
    icon: <OfferIcon />,
  },

  brand: {
    text: "Brand Focused ",
    bgColor: " #F1F6FF",
    textColor: "#2BB3F4",
    icon: <BrandIcon />,
  },

  benifit: {
    text: "Benefit Based ",
    bgColor: " #F3EBFE",
    textColor: "#8832F5",
    icon: <BenifitIcon />,
  },
  customer: {
    text: "Customer Engaging",
    bgColor: "rgba(16, 171, 153, 0.07)",
    textColor: "#1ABFA1",
    icon: <CustomerIcon />,
  },
};

export default function PerformanceTooltip({
  open,
  anchorEl,
  infoItem,
  place,
}: {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  infoItem: PerformanceScoreType;
  place: "editor" | "variant";
}) {
  const id = open ? "performance-tooltip" : undefined;
  const calculateColorType = (value: number) => {
    if (value > 7) {
      return "high";
    } else if (value >= 4) {
      return "medium";
    }
    return "low";
  };

  const isKeyHighlights =
    infoItem?.offer_label ||
    infoItem?.brand_centric ||
    infoItem?.benefit_label ||
    infoItem?.customer_centric;

  return (
    <StyledPopover
      place={place}
      open={open}
      id={id}
      anchorEl={anchorEl}
      sx={{
        pointerEvents: "none",
      }}
      anchorOrigin={{
        vertical: place === "variant" ? "bottom" : "top",
        horizontal: place === "variant" ? "left" : "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          boxShadow: "none",
          background: "transparent",
          marginTop: place === "variant" ? "0px" : "-1em",
          marginLeft: place === "variant" ? "-1.875em" : "0px",
        },
      }}
    >
      <StyledPaper
        sx={{
          background: "#FFFFFF",
          borderRadius: ".5em",
          width: "22em",
          marginTop: place === "variant" ? "0.9375em" : "0px",
          marginLeft: place === "variant" ? "0px" : "1.25em",
        }}
        // elevation={10}
      >
        <Box display="flex" flexDirection="column" padding="1em .9em">
          {place === "variant" && (
            <PerformanceTooltipItem
              headSubtitle=">7 is considered Good"
              headTitle="Performance Score"
              midTitle={`${infoItem?.performance_score}/10`}
              progressValue={infoItem?.performance_score * 10}
              progressColor={
                ColorMapping[calculateColorType(infoItem?.performance_score)]
              }
            />
          )}
          <PerformanceTooltipItem
            headSubtitle="Impacts CTR of your Ad"
            headTitle={
              place === "variant"
                ? "Overall Readability Score"
                : "Readability Score"
            }
            midTitle={`${infoItem?.readability_score?.toFixed(0)}/100`}
            progressValue={infoItem?.readability_score}
            isShowFooter
            progressColor="linear-gradient(90deg, #0869FB 6.86%, #0CF0FF 109.31%)"
          />
          {isKeyHighlights && (
            <>
              <StyledText
                fontSize="0.75em"
                fontFamily="Lato"
                lineHeight="15px"
                fontWeight={600}
                pb=".5em"
              >
                Key Highlights -
              </StyledText>
              <Box display="flex" flexWrap="wrap" gap=".7em">
                {infoItem?.offer_label && (
                  <HighlightItem {...highlightConstObject["offer"]} />
                )}
                {infoItem?.brand_centric && (
                  <HighlightItem {...highlightConstObject["brand"]} />
                )}

                {infoItem?.benefit_label && (
                  <HighlightItem {...highlightConstObject["benifit"]} />
                )}
                {infoItem?.customer_centric && (
                  <HighlightItem {...highlightConstObject["customer"]} />
                )}
              </Box>
            </>
          )}
        </Box>
        {infoItem?.insights && (
          <StyledFooter>
            <BulbIcon />
            <StyledText
              fontSize="10px"
              fontWeight={500}
              lineHeight="14px"
              color="#F99F35"
              pr=".2em"
              pl=".5em"
            >
              Ad Variant needs
            </StyledText>

            <StyledText
              fontSize="10px"
              fontWeight={500}
              lineHeight="14px"
              color="#E84B47"
              pr=".2em"
            >
              attention
            </StyledText>

            <StyledText
              fontSize="10px"
              fontWeight={500}
              lineHeight="14px"
              color="#F99F35"
            >
              before publishing !
            </StyledText>
          </StyledFooter>
        )}
      </StyledPaper>
    </StyledPopover>
  );
}

import { Box, IconButton } from "@mui/material";
import CopyClipboard from "components/shared/CopyClipboard/CopyClipboard";
import CustomizedDialogs from "components/shared/Modal/CustomizedDialogs";
import React, { useEffect, useState } from "react";
import Loader from "../../../../../assets/Gif/3dot_loading.gif";
import Success from "../../../../../assets/svg/celebrate.svg";
import review_dummy from "../../../../../assets/png/review_dummy.png";
import {
  StyledFlowSubTitle,
  StyledFlowSubTitle2,
  StyledFlowTitle,
} from "./styled";

import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";

interface ReviewFlowModalProps {
  show: boolean;
  platform: string;
  onClose: () => void;
}

export default function ReviewFlowModal({
  show,
  platform,
  onClose,
}: ReviewFlowModalProps) {
  const { approverLink } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  useEffect(() => {
    if (approverLink.isError) {
      onClose();
    }
  }, [approverLink]);

  return (
    <CustomizedDialogs
      show={show}
      type="ConfirmDialog"
      footer={false}
      maxWidth="xs"
      dialogStyleProp={{
        borderRadius: "900px",
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" p="8px">
        <Box
          component="img"
          src={approverLink.isLoading ? Loader : Success}
          sx={{
            marginLeft: "-12px",
            height: "110px",
            width: "110px",
          }}
        />
        <StyledFlowTitle>
          {approverLink.isLoading ? "Processing..." : "Success"}
        </StyledFlowTitle>
        <StyledFlowSubTitle>
          A review request has been raised successfully. Share the link below
          with the approver
        </StyledFlowSubTitle>
        <CopyClipboard
          mixPanelReviewLinkCopied={() => {}}
          text={
            approverLink.isLoading
              ? "You review request link will appear here"
              : `${window.location.origin}/review/cai/${platform}/${approverLink.isData}`
          }
          isLoading={approverLink.isLoading}
        />
        <StyledFlowSubTitle2>
          Note: You can track the review progress directly from the Dashboard.
          To know more watch the video below
        </StyledFlowSubTitle2>
        <Box
          pt="32px"
          sx={{
            width: "375px",
            height: "250px",
          }}
        >
          <iframe
            style={{ display: "block" }}
            src={
              platform == "facebook"
                ? `https://drive.google.com/file/d/1CeV8MQtyxx-4I7I7Nyzs2LEmPceMbaQf/preview`
                : platform == "google"
                ? `https://drive.google.com/file/d/18GxhxwkU_DqDyeaNVZFFWbuAyiBY8fQG/preview`
                : ""
            }
            width="100%"
            height="100%"
            allow="autoplay"
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
    </CustomizedDialogs>
  );
}

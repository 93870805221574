import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAd } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  CreateVariantsState,
  SET_FILTERED_VARIANTS_LOADING,
  SET_FILTERED_VARIANT_LIST,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import {
  AdsItemType,
  FacebookInitialState,
} from "store/types/CreativeAI_Revamp/facebook.types";

export const useFilterHook = ({
  setSelectedAdIndex,
  sortType,
  sortSelect,
  setAdsSortFilterLoading,
  setFilteredAdLength,
}: {
  setSelectedAdIndex: any;
  sortType: "up" | "down";
  sortSelect: "Spends" | "CPR" | "CTR";
  setAdsSortFilterLoading: any;
  setFilteredAdLength: any;
}) => {
  //useState
  const [sortedAds, setSortedAds] = useState<AdsItemType[]>([]);
  const [filteredAds, setFilteredAds] = useState<AdsItemType[]>([]);
  //selectors
  const { filter } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );
  const { selectedAd, variantList, ads } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  //constants

  //dispatch
  const dispatch = useDispatch();

  //sorting
  useEffect(() => {
    if (ads && sortSelect && sortType) {
      let local = cloneDeep(ads);
      setSortedAds(
        local.sort((a: any, b: any) => {
          if (sortType === "down") {
            return a.metrics?.[sortSelect] - b.metrics?.[sortSelect];
          } else {
            return b.metrics?.[sortSelect] - a.metrics?.[sortSelect];
          }
        })
      );
    }
  }, [sortSelect, sortType, ads]);

  useEffect(() => {
    setAdsSortFilterLoading(true);
    const allSelected = filter.filter((item) => item.selected);
    if (allSelected.length) {
      const filteredAd = sortedAds.filter((item: any) => {
        let val = true;
        allSelected.forEach((filterItem, index) => {
          if (filterItem.type == "check") {
            if (filterItem.adKey == "draftVariants") {
              let v = false;
              filterItem.selected.forEach((selection: any) => {
                if (selection.value == "approved") {
                  v = v || Boolean(item.approvedVariants);
                }
                if (selection.value == "rejected") {
                  v = v || Boolean(item.rejectedVariants);
                }
                if (selection.value == "under_review") {
                  v = v || Boolean(item.underReviewVariants);
                }
                if (selection.value == "not_raised") {
                  v =
                    v ||
                    Boolean(
                      item.draftVariants -
                        item.approvedVariants -
                        item.rejectedVariants -
                        item.underReviewVariants
                    );
                }
              });
              val = val && v;
            } else {
              let v = false;
              filterItem.selected.forEach((selection: any) => {
                v = v || selection.value === item?.[filterItem.adKey];
              });
              val = val && v;
            }
          } else {
            if (filterItem.adKey == "billboardVariants") {
              val = val && item?.[filterItem.adKey] > 0;
            } else {
              val =
                val && filterItem.selected.value == item?.[filterItem.adKey];
            }
          }
        });
        return val;
      });
      setFilteredAds(filteredAd);
      setFilteredAdLength(filteredAd.length);
      // const selectedIndex = filteredAd.findIndex(
      //   (item) => item?.id == selectedAd?.id
      // );
      // if (selectedIndex > -1) {
      //   setSelectedAdIndex(selectedIndex);
      // } else {
      //   dispatch(setSelectedAd(filteredAd[0]));
      //   setSelectedAdIndex(0);
      // }
    } else {
      setFilteredAds(sortedAds);
      // const selectedIndex = sortedAds.findIndex(
      //   (item) => item?.id == selectedAd?.id
      // );
      // if (selectedIndex > -1) {
      //   setSelectedAdIndex(selectedIndex);
      // } else {
      //   dispatch(setSelectedAd(sortedAds[0]));
      //   setSelectedAdIndex(0);
      // }
      setFilteredAdLength && setFilteredAdLength(sortedAds.length);
    }
    setAdsSortFilterLoading(false);
  }, [filter, sortedAds]);

  useEffect(() => {
    dispatch({ type: SET_FILTERED_VARIANTS_LOADING, payload: true });
    const allSelected = filter.filter((item) => item.selected);
    if (allSelected.length) {
      const filteredVariants = variantList.filter((item: any) => {
        let val = true;
        allSelected.forEach((filterItem, index) => {
          if (filterItem?.variantKey === "reviewStatus") {
            if (item?.[filterItem.variantKey] == null) {
              val =
                val &&
                Boolean(
                  filterItem.selected.find(
                    (sel: any) => sel.value === "not_raised"
                  )
                );
            } else {
              val =
                val &&
                Boolean(
                  filterItem.selected.find(
                    (sel: any) =>
                      sel.value === item?.["reviewStatus"].toLowerCase()
                  )
                );
            }
          } else if (filterItem?.variantKey === "variantType") {
            if (item?.[filterItem.variantKey] != null) {
              val =
                val &&
                item?.[filterItem.variantKey]
                  .toLowerCase()
                  .includes("billboard");
            } else {
              val = false;
            }
          }
        });
        return val;
      });
      dispatch({ type: SET_FILTERED_VARIANT_LIST, payload: filteredVariants });
    } else {
      dispatch({ type: SET_FILTERED_VARIANT_LIST, payload: variantList });
    }
  }, [variantList, filter, filteredAds]);

  return {
    filteredAds,
  };
};

import { useState, useEffect } from "react";
import moment from "moment";
import { ReactComponent as Delete } from "assets/svg/delete.svg";
import { ReactComponent as UpDownArrow } from "assets/svg/up-down-arrow.svg";
import { ReactComponent as Lock } from "assets/svg/lock.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteToken, updatePrimaryToken } from "store/actions/accountsManager";
import { initialState } from "store/types/auth";
import { mapTokenPayload } from "utils/mapFactors";
import React from "react";
import { ReactComponent as CloseImg } from "../../../assets/svg/close.svg";
import { getWarningMessage, handleSort } from "../utils";
import { ReactComponent as WarningIcon } from "../../../assets/svg/warning-icon.svg";
import Loader from "../../../assets/Gif/CommonLoader.gif";
import { RolesWrapper } from "utils/rolesWrapper";
import { AdAccountState } from "store/types/adaccount";
import { getRolesHierarchy, isViewerRole } from "utils/commonFunctions";

function TokenAccess({ token }: { token: any }) {
  const [warnings, setWarnings] = useState<any>({});

  useEffect(() => {
    const _warnings = getWarningMessage([token]);
    setWarnings(_warnings);
  }, []);
  return (
    <div className={`token-access-stat token-${warnings.className}`}>
      {warnings.message ? (
        <>
          <WarningIcon />
          {warnings.message}
        </>
      ) : (
        "Active"
      )}
    </div>
  );
}

const ActiveToken: React.FC<{
  data?: any;
  handleClose: () => void;
  setCounter: any;
  setIsDeleteLoaderId: any;
  isDeleteLoaderId: any;
}> = ({
  data,
  handleClose,
  setCounter,
  setIsDeleteLoaderId,
  isDeleteLoaderId,
}) => {
  const dispatch = useDispatch();
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;
  const [list, setList] = React.useState<any>([]);
  const [sortOrder, setSortOrder] = React.useState<boolean>(false);

  const deleteActiveToken = (data: any) => {
    dispatch(
      deleteToken(
        {
          user: authState?.user,
          tokenUID: data.tokenUID,
          adAccountId: data.adAccountId,
        },
        (response: any, error: boolean) => {
          if (!error) {
            setCounter((counter: any) => counter + 1);
          } else {
            console.log(error);
          }
        }
      )
    );
  };
  const [isLoading, setIsLoading] = useState(false);
  const setPrimaryKey = (data: any) => {
    setIsLoading(true);
    dispatch(
      updatePrimaryToken(
        {
          user: authState?.user,
          tokenUID: data.tokenUID,
          adAccountId: data.adAccountId,
          payload: mapTokenPayload,
        },
        (response: any, error: boolean) => {
          if (!error) {
            setCounter((counter: any) => counter + 1);
          } else {
            console.log(error);
          }
        }
      )
    );
  };
  useEffect(() => {
    setList(data.tokens);
    setIsLoading(false);
  }, [data]);

  function _handleSort(key: string) {
    const _sortedList = handleSort(list, key, sortOrder);
    setSortOrder((value) => !value);
    setList(_sortedList);
  }

  return (
    <div className="account-manager-modal token-modal-container">
      <div className="token-modal">
        <div className="token-header">
          <div>
            <span>{data.adAccountName}</span>
            <span className="dot" />
            <span>
              {data.parentPlatformName === "facebook"
                ? "meta"
                : data.parentPlatformName}
            </span>
          </div>
          <CloseImg
            className="close-icon"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          />
        </div>
        <div className="token-account-id">{data.adAccountId}</div>
        <div className="token-table">
          <div className="table-head">
            <div className="table-row">
              <div>
                User &ensp;
                <UpDownArrow onClick={() => _handleSort("platformUserId")} />
              </div>

              <div>Token Status &ensp;</div>
              <div>
                Connected On &ensp;
                <UpDownArrow onClick={() => _handleSort("accountName")} />
              </div>
              <div>Action &ensp;</div>
            </div>
          </div>
          <div
            className="table-body"
            style={{
              display: `${isLoading ? "flex" : "block"}`,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <img
                alt="loader"
                style={{ height: "70px", width: "70px" }}
                src={Loader}
              />
            ) : (
              list.length &&
              list.map((token: any) => {
                return (
                  <div
                    key={token.platformUserEmail}
                    className={`table-row  ${
                      token.isPrimary ? "primary-token" : ""
                    }`}
                  >
                    <div className="token-owner">
                      <span>{token.platformUserEmail}</span>
                      <RolesWrapper
                        show={true}
                        // disabled={isViewerRole(brandRoles)}
                        disabled={getRolesHierarchy(brandRoles) === "viewer"}
                      >
                        <div className="lock-container">
                          <div className="lock-tooltip">Primary Token</div>
                          <Lock
                            className="lock-icon"
                            onClick={() => setPrimaryKey(token)}
                          />
                        </div>
                      </RolesWrapper>
                    </div>
                    <div>
                      <TokenAccess token={token} />
                    </div>
                    <div>
                      {moment(token.connectedOn).local().format("MMM DD,YYYY")}
                    </div>
                    {token.isPrimary || list.length === 1 ? (
                      <div />
                    ) : (
                      <RolesWrapper
                        show={true}
                        // disabled={isViewerRole(brandRoles)}
                        disabled={getRolesHierarchy(brandRoles) === "viewer"}
                      >
                        <div className="delete-container">
                          <div className="delete-tooltip">
                            Delete This Token
                          </div>
                          {isDeleteLoaderId !== token?.tokenUID ? (
                            <Delete
                              className="delete-icon"
                              onClick={() => {
                                setIsDeleteLoaderId(token?.tokenUID);
                                deleteActiveToken(token);
                              }}
                            />
                          ) : (
                            <>Deleting...</>
                          )}
                        </div>
                      </RolesWrapper>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveToken;

//@ts-nocheck
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import "./styles.scss";
import Loader from "../../../components/common/Loader";
import ModalWrapper from "../../../components/Modal/ModalWrapper";
import ModalContainer from "../../../components/Modal/ModalContainer";
import { ReactComponent as SuccessIcon } from "../../../assets/svg/success.svg";
import useSelectAccount from "./hooks";
import ConfirmationModalText from "./ConfirmationModalText";
import Container from "../Container";
import CheckBox from "./CheckBox";
import { ReactComponent as PixisLogoImage } from "assets/svg/pixis.svg";
import NoAdaccount from "./NoAdaccount";
import EmailModal from "./EmailModal";

function LabelComponent({ adAccount }) {
  return (
    <div className="label-component">
      <div className="label-title">
        <div className="acccount-name">{adAccount.adAccountName}</div>
        <div className="account-id">{adAccount.adAccountId}</div>
      </div>
      <div
        className={`connected-msg${
          adAccount.alreadyConnectedBySelf ? " opacity" : ""
        }`}
      >
        {adAccount.alreadyConnectedtoAnotherBrand
          ? "Connected to another brand"
          : !adAccount.hasPermission
          ? "Not enough permissions"
          : adAccount.alreadyConnectedBySelf
          ? "Already Connected by you"
          : null}
      </div>
    </div>
  );
}

function SelectAdAccount() {
  const {
    state,
    filteredList,
    loader,
    checkAll,
    modal,
    setModal,
    handleChanage,
    confirmationHeader,
    handleInputChanage,
    FooterComponentforConfirm,
    FooterComponentforSuccess,
    handleCheckAll,
    ErrorFooter,
    noAccounts,
    onSubmit,
    checked,
    email,
  } = useSelectAccount();

  return (
    <>
      {loader ? (
        <div className="tms-loader-container">
          <Loader />
        </div>
      ) : null}
      <Container>
        {noAccounts ? (
          <NoAdaccount email={email} state={state} />
        ) : (
          <div className="tms-select-account">
            <div className="header-logo">
              <PixisLogoImage />
            </div>
            <div className="header-text">
              <h4 className="brand-name">{state.brandName}</h4>
              <p className="selected-msg">Select Your Account(s) to continue</p>
            </div>

            <div className="form-header">
              <span className="choose-account">Choose Account</span>
              <div className="input-search">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => handleInputChanage(e.target.value)}
                />
                <SearchIcon />
              </div>
              <CheckBox
                isIntermediate={checkAll === "intermediate"}
                value={checkAll === "all"}
                onChange={handleCheckAll}
                label="All Accounts"
              />
            </div>

            <div className="form-content">
              {filteredList &&
                filteredList.map((adAccount, index) => (
                  <CheckBox
                    key={adAccount.adAccountId}
                    value={adAccount.isChecked}
                    disabled={
                      adAccount.alreadyConnectedtoAnotherBrand ||
                      adAccount.alreadyConnectedBySelf ||
                      !adAccount.hasPermission
                    }
                    className={
                      adAccount.alreadyConnectedBySelf &&
                      adAccount.hasPermission
                        ? "override-disable-styles"
                        : ""
                    }
                    onChange={() => {
                      if (
                        !(
                          adAccount.alreadyConnectedtoAnotherBrand ||
                          adAccount.alreadyConnectedBySelf ||
                          !adAccount.hasPermission
                        )
                      ) {
                        handleChanage(index);
                      }
                    }}
                    labelComponent={<LabelComponent adAccount={adAccount} />}
                  />
                ))}
            </div>
            <div className="pt-3 d-flex justify-content-end">
              <button
                className="mr-0 btn primary"
                disabled={!checked.length}
                onClick={() => {
                  // console.log("onSubmit line 138");
                  onSubmit();
                }}
              >
                Connect
              </button>
            </div>
          </div>
        )}
      </Container>
      {modal && (
        <ModalWrapper>
          {modal.key === "error" && (
            <ModalContainer
              title="Error occured"
              width="469px"
              footerComponent={ErrorFooter}
            >
              <div className="flex-center">
                <p className="m-0 confirm-text">
                  Some went worng. Please try after sometime.
                </p>
              </div>
            </ModalContainer>
          )}
          {modal.key === "emailPopup" && (
            <EmailModal
              platform={state.platform}
              onNextClick={() => {
                setModal({ key: `success` });
              }}
            />
          )}
          {modal.key === "connectAdaccountPopup" && (
            <ModalContainer
              title={confirmationHeader(state.platform)}
              width="469px"
              footerComponent={FooterComponentforConfirm}
            >
              <ConfirmationModalText platform={state.platform} />
            </ModalContainer>
          )}
          {modal.key === "success" && (
            <ModalContainer
              title="Accounts Linked"
              width="469px"
              footerComponent={FooterComponentforSuccess}
            >
              <div className="flex-center">
                <div className="p-3 mr-3 message-icon-bg">
                  <SuccessIcon />
                </div>
                <p className="confirm-text">
                  Your Accounts have been succesfully linked to the system!
                </p>
              </div>
            </ModalContainer>
          )}
        </ModalWrapper>
      )}
    </>
  );
}

export default SelectAdAccount;

import { Button } from "@mui/material";
import { payloadForSkip } from "components/CreativeAI/shared/commonFunctions";
import StatusTag from "components/shared/Input/StatusTag/StatusTag";
import Tooltip from "components/shared/Tooltip/CustomizedTooltip";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDraftAds } from "store/actions/CreativeAI/GsaiPlayground";
import { AdAccountState } from "store/types/adaccount";
import { ReactComponent as ApprovedIcon } from "../../../../../assets/svg/approved_icon.svg";
import { ReactComponent as Draft } from "../../../../../assets/svg/draft.svg";
import { ReactComponent as InfoIcon } from "../../../../../assets/svg/info_grey.svg";
import { ReactComponent as PendingIcon } from "../../../../../assets/svg/pending_icon.svg";
import { ReactComponent as RejectIcon } from "../../../../../assets/svg/reject_icon.svg";
import { ReactComponent as Live } from "../../../../../assets/svg/live.svg";
import { initialState } from "../../../../../store/types/auth";
import { getCurrencySymbol } from "../../../../../utils/commonFunctions";
import CustomizedButtonProps from "../../../../shared/Button/CustomizedButton";
import TooltipWithInfo from "../../../Facebook/shared/TooltipWithInfo/TooltipWithInfo";
import { textTruncateMore } from "../../../Facebook/utils/text.utils";
import GsaiCardTooltip from "./GsaiCardTooptip";
import {
  StyledAvarageText,
  StyledAverageContaner,
  StyledButtonContainer,
  StyledCardContainer,
  StyledCardHeadSubTitle,
  StyledCardHeadTitle,
  StyledCenterContent,
  StyledFirstPara,
  StyledHeaderContent,
  StyledHeadlineContainer,
  StyledParaContent,
  StyledPriceContent,
  StyledPriceDiv,
  StyledPriceDivLast,
  StyledPriceNo,
  StyledPriceSpan,
  StyledPriceText,
  StyledQualityContainer,
  StyledQualityText,
  StyledReplacementIcon,
  StyledSecondPara,
  StyledStatusIconContainer,
  StyledThirdPara,
  StyledTitle,
  StyledTitleIcon,
  StyledToolTipContent,
} from "./styled";

const GsaiCard: React.FC<{ data: any; index: number }> = ({
  data,
  index,
}): JSX.Element => {
  const dispatch = useDispatch();
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { readyToUseBUs } = useSelector(
    (state: any) => state.businessUnitsReducer
  );

  const filteredAds = useSelector(
    (state: any) => state.gsaiPlayground.filteredAds
  );
  const ads = useSelector((state: any) => state.gsaiPlayground.ads);
  const redirectUrl = `${window.location.origin}/review/cai/google/${data?.urlHash}`;
  const currency = authState?.accountDetails?.currency || "USD";
  const headlines = (data?.headline || []).map((item: any) => item.text);
  const headlineText = headlines.join(" | ");

  const descriptions = (data?.description || []).map((item: any) => item.text);
  const descriptionText = descriptions.join(" | ");

  const createAdRefreshAdBasicSetup = {
    adUnit: data,
    screen: 1,
    businessUnitLoader: true,
    keywordLoader: true,
    headlinedesLoader: true,
    descriptionLoader: true,
  };

  const handleDeleteDraft = () => {
    dispatch(
      deleteDraftAds({
        id: data.variant_id,
        callback: () => {
          const filterAds = filteredAds.filter(
            (f: any) => f.variant_id !== data.variant_id
          );
          const filterMainAds = ads.filter(
            (f: any) => f.variant_id !== data.variant_id
          );
          dispatch({
            type: "UPDATE_GSAI_STORE",
            payload: {
              filteredAds: filterAds,
              ads: filterMainAds,
            },
          });
        },
      })
    );
  };

  return (
    <>
      <StyledCardContainer>
        <StyledHeaderContent>
          <StyledTitle>
            <StyledCardHeadTitle>
              <Tooltip
                textTruncate={30}
                title={data?.adGroup?.name ?? data?.adGroup?.id}
                disableFocusListener
                disableTouchListener
              >
                {data?.adGroup?.name ?? data?.adGroup?.id}
              </Tooltip>
            </StyledCardHeadTitle>
            <StyledCardHeadSubTitle>
              <Tooltip
                textTruncate={32}
                title={data?.campaign?.name ?? data?.campaign?.id}
                disableFocusListener
                disableTouchListener
              >
                {data?.campaign?.name ?? data?.campaign?.id}
              </Tooltip>
            </StyledCardHeadSubTitle>
            {data.isDraft && data.isCreate ? <Draft /> : <Live />}
          </StyledTitle>
          <StyledTitleIcon>
            <GsaiCardTooltip
              title={
                <div>
                  Displays the current status of your approval request, click
                  here to view details.
                </div>
              }
            >
              <StyledStatusIconContainer>
                {data.reviewStatus === "APPROVED" ? (
                  <a target="_blank" href={redirectUrl}>
                    <ApprovedIcon />
                  </a>
                ) : data.reviewStatus === "UNDER_REVIEW" ? (
                  <a target="_blank" href={redirectUrl}>
                    <PendingIcon />
                  </a>
                ) : (
                  data.reviewStatus === "REJECTED" && (
                    <a target="_blank" href={redirectUrl}>
                      <RejectIcon />
                    </a>
                  )
                )}
              </StyledStatusIconContainer>
            </GsaiCardTooltip>
            <GsaiCardTooltip
              title={
                data.creativeReplacement
                  ? "Creative replacement enabled"
                  : "Creative replacement disabled"
              }
              disableFocusListener
              disableTouchListener
            >
              <StyledTitleIcon>
                <StyledReplacementIcon
                  className={
                    data.creativeReplacement ||
                    data?.replacementConfig?.isEnabled
                      ? "active"
                      : ""
                  }
                />
              </StyledTitleIcon>
            </GsaiCardTooltip>
          </StyledTitleIcon>
        </StyledHeaderContent>

        <StyledCenterContent>
          <StyledParaContent>
            <StyledHeadlineContainer>
              <StyledFirstPara>{headlineText}</StyledFirstPara>
              <GsaiCardTooltip
                maxWidth={170}
                title="This is a preview of one possible combination of your assets in Search Ad"
                placement="top"
              >
                <InfoIcon />
              </GsaiCardTooltip>
            </StyledHeadlineContainer>
            <StyledSecondPara>
              {textTruncateMore(
                (data?.adGroupAd?.ad?.finalUrls &&
                  data?.adGroupAd?.ad?.finalUrls.length > 0 &&
                  data?.adGroupAd?.ad?.finalUrls[0].split("/")[2]) +
                  (data?.adGroupAd?.ad?.responsiveSearchAd?.path1
                    ? `/${data?.adGroupAd?.ad?.responsiveSearchAd?.path1}`
                    : "") +
                  (data?.adGroupAd?.ad?.responsiveSearchAd?.path2
                    ? `/${data?.adGroupAd?.ad?.responsiveSearchAd?.path2}`
                    : ""),
                40
              )}
            </StyledSecondPara>
            <StyledThirdPara>{descriptionText}</StyledThirdPara>
          </StyledParaContent>
        </StyledCenterContent>
        <StyledPriceContent>
          <StyledPriceSpan>
            {data.isDraft && data.isCreate ? (
              <>
                <StyledPriceDiv>
                  <StyledPriceNo>{data.headline.length}</StyledPriceNo>
                  <StyledPriceText>Headline Used</StyledPriceText>
                </StyledPriceDiv>
                <StyledPriceDivLast>
                  <StyledPriceNo>{data.description.length}</StyledPriceNo>
                  <StyledPriceText>Descriptions Used</StyledPriceText>
                </StyledPriceDivLast>
              </>
            ) : (
              <>
                <StyledPriceDiv>
                  {data?.metrics?.costPerConversion ? (
                    <StyledPriceNo>
                      {getCurrencySymbol(currency)}{" "}
                      {data?.metrics?.costPerConversion?.toFixed(2)}
                    </StyledPriceNo>
                  ) : (
                    <StyledPriceNo>-</StyledPriceNo>
                  )}
                  <StyledPriceText>Cost / Conv.</StyledPriceText>
                </StyledPriceDiv>
                <StyledPriceDiv>
                  {data?.metrics?.ctr ? (
                    <StyledPriceNo>
                      {data?.metrics?.ctr?.toFixed(2) + "%"}
                    </StyledPriceNo>
                  ) : (
                    <StyledPriceNo>-</StyledPriceNo>
                  )}
                  <StyledPriceText>CTR</StyledPriceText>
                </StyledPriceDiv>
                <StyledPriceDivLast>
                  {data?.metrics?.costMicros ? (
                    <StyledPriceNo>
                      {getCurrencySymbol(currency)}{" "}
                      {data?.metrics?.costMicros?.toFixed(2)}
                    </StyledPriceNo>
                  ) : (
                    <StyledPriceNo>-</StyledPriceNo>
                  )}
                  <StyledPriceText>Spends</StyledPriceText>
                </StyledPriceDivLast>
              </>
            )}
          </StyledPriceSpan>
          {!(data.isDraft && data.isCreate) && (
            <StyledQualityContainer>
              <StyledQualityText>
                Quality{" "}
                <StyledToolTipContent style={{ cursor: "pointer" }}>
                  <TooltipWithInfo
                    top={"-10px"}
                    info="An estimate of the experience that users have when they see your search ads."
                  />
                </StyledToolTipContent>
              </StyledQualityText>
              <StyledAverageContaner>
                <StyledAvarageText>
                  {data?.adGroupAd?.adStrength?.toUpperCase()}
                </StyledAvarageText>
              </StyledAverageContaner>
            </StyledQualityContainer>
          )}
        </StyledPriceContent>

        <StyledButtonContainer>
          {data.isDraft && data.isCreate ? (
            <Button
              sx={{
                textTransform: "none",
                ":disabled": {
                  opacity: 0.5,
                },
              }}
              variant="text"
              className="gsaiDeleteAdButton"
              onClick={handleDeleteDraft}
              disabled={readyToUseBUs?.data?.length === 0}
            >
              Delete
            </Button>
          ) : (
            <CustomizedButtonProps
              variant="outlined"
              buttonText="Create Variants"
              buttonClass="gsaiCreateVariantButton"
              disabled={readyToUseBUs?.data?.length === 0}
              sx={{
                ":disabled": {
                  opacity: 0.5,
                },
              }}
              onClick={() => {
                dispatch({
                  type: "UPDATE_GSAI_STORE",
                  payload: {
                    ...createAdRefreshAdBasicSetup,
                    modal: "generating-recommendations",
                    isCreate: true,
                    isCreateVariant: true,
                  },
                });
              }}
            />
          )}
          <GsaiCardTooltip
            placement="top"
            title={
              data.isDraft && data.isCreate === false
                ? "This ad has some unpublished changes, click here to continue editing."
                : ""
            }
          >
            <Button
              sx={{
                textTransform: "none",
                ":disabled": {
                  color: "white",
                  opacity: 0.5,
                  backgroundColor: "#0869FB",
                },
              }}
              variant="contained"
              className="gsaiRefreshAdButton"
              disabled={readyToUseBUs?.data?.length === 0}
              onClick={() => {
                dispatch({
                  type: "UPDATE_GSAI_STORE",
                  payload:
                    data.reviewStatus === "APPROVED" ||
                    (data.isCreate && data.isDraft)
                      ? {
                          ...createAdRefreshAdBasicSetup,
                          ...payloadForSkip(data),
                          isCreate: true,
                          isCreateVariant: false,
                        }
                      : {
                          ...createAdRefreshAdBasicSetup,
                          isCreate: data.isCreate ?? false,
                          isCreateVariant: !data.isDraft ?? true,
                          modal: data.isDraft
                            ? "reference-modal"
                            : "generating-recommendations",
                        },
                });
              }}
            >
              {data.isDraft && data.isCreate === false ? (
                <>
                  Refresh Ad <sup>*</sup>
                </>
              ) : data.isDraft && data.isCreate ? (
                "Edit Variant"
              ) : (
                "Refresh Ad"
              )}
            </Button>
          </GsaiCardTooltip>
        </StyledButtonContainer>
      </StyledCardContainer>
    </>
  );
};

export default GsaiCard;

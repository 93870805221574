export const generateParamBody = (
  level: string,
  selectedItem: any,
  platform: string
) => {
  let params: any = [];

  switch (platform) {
    case "google":
      switch (level) {
        case "keyword":
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {
                concept_id: eachselectedItem?.adset_id,
                concept_name: eachselectedItem?.adset,
              },
            });
          });
          break;
        case "adset":
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {
                concept_id: eachselectedItem?.campaign_id,
                concept_name: eachselectedItem?.campaign,
              },
            });
          });
          break;
        case "campaign":
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {},
            });
          });
          break;
        default:
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {},
            });
          });
          break;
      }
      break;
    case "facebook":
      switch (level) {
        case "ad":
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {
                concept_id: eachselectedItem?.adset_id,
                concept_name: eachselectedItem?.adset,
              },
            });
          });
          break;
        case "adset":
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {
                concept_id: eachselectedItem?.campaign_id,
                concept_name: eachselectedItem?.campaign,
              },
            });
          });
          break;
        case "campaign":
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {},
            });
          });
          break;
        default:
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {},
            });
          });
          break;
      }
      break;
    case "bing":
      switch (level) {
        case "keyword":
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {
                concept_id: eachselectedItem?.adset_id,
                concept_name: eachselectedItem?.adset,
              },
            });
          });
          break;
        case "adset":
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {
                concept_id: eachselectedItem?.campaign_id,
                concept_name: eachselectedItem?.campaign,
              },
            });
          });
          break;
        case "campaign":
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {},
            });
          });
          break;
        default:
          selectedItem.map((eachselectedItem: any) => {
            params.push({
              concept_id: eachselectedItem?.name_id,
              concept_name: eachselectedItem?.name,
              parent: {},
            });
          });
          break;
      }
      break;
    case "linkedin":
      selectedItem.map((eachselectedItem: any) => {
        params.push({
          concept_id: eachselectedItem?.name_id,
          concept_name: eachselectedItem?.name,
          parent: {},
        });
      });
      break;

    default:
      selectedItem.map((eachselectedItem: any) => {
        params.push({
          concept_id: eachselectedItem?.name_id,
          concept_name: eachselectedItem?.name,
          parent: {},
        });
      });
      break;
  }

  return params;
};

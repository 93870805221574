import httpService from "HttpService/HttpService";
import { call, race, take, takeEvery } from "redux-saga/effects";
import {
  CANCEL_PERFORMANCE_SCORE,
  CANCEL_VARIANT_PERFORMANCE_SCORE,
  FETCH_PERFORMANCE_SCORE,
  FetchPerformanceScore,
} from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";
import { BASE_URL } from "utils/path";

function* fetchPerformanceScore({ payload }: FetchPerformanceScore): any {
  try {
    const url = `/v1/api/creative-variants/get-performance-score`;

    const { response, cancel } = yield race({
      response: call(httpService.post, BASE_URL, url, "", payload.body),
      cancel: take(
        Object.keys(payload.body).length > 2
          ? CANCEL_VARIANT_PERFORMANCE_SCORE
          : CANCEL_PERFORMANCE_SCORE
      ),
    });
    if (response.data.success) {
      yield payload.callback({
        error: false,
        performanceScore: response.data.data,
        cancel,
      });
    } else {
      payload.callback({ error: true, cancel });
    }
  } catch (error) {
    yield payload.callback({ error: true });
  }
}

export function* editorWatcherSaga() {
  yield takeEvery(FETCH_PERFORMANCE_SCORE, fetchPerformanceScore);
}

import React from "react";
import NoData from "../../../../assets/Gif/NoDataFound.gif";
import "./NoDataScreen.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getNoDataProps } from "../../constants/index";

interface Props {
  selectedCohortType: "live" | "drafted" | "recommended";
}

const NoDataScreen: React.FC<Props> = ({ selectedCohortType }) => {
  const history = useHistory();
  const adaccountState = useSelector((state: any) => state.adaccount);
  const brandId = adaccountState.selectedBrand.id;
  const audienceManagerState = useSelector(
    (state: any) => state.audienceManager
  );
  const {agencyAssets} = useSelector((state:any) => state.common);
  const props = getNoDataProps(agencyAssets)[selectedCohortType];
  return (
    <Box className="no_result_container">
      <Box sx={{ height: "17.125rem", width: "17.125rem" }}>
        <img
          src={NoData}
          alt="no-result-img"
          style={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Typography className="font-18 font-700 text-black mb-2">
        No Cohorts Found
      </Typography>

      <Typography className="font-12 font-500">{props.heading}</Typography>
      <Typography className="font-12 font-500">{props.subHeading}</Typography>
      <Button
        className="font-12 font-600 h-100 mt-2"
        variant="contained"
        sx={{
          borderRadius: 2,
          textTransform: "none",
          background: "#0869FB",
          height: "2rem !important",
        }}
        onClick={() => {
          history.push(`/targeting-ai-playground-beta?brand_id=${brandId}`, {
            aiGroup: audienceManagerState?.selectedAiGroup,
            platform: "fb",
          });
        }}
      >
        Create New Audience
      </Button>
    </Box>
  );
};

export default NoDataScreen;

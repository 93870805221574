import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgreement,
  getClientPDF,
  setModal,
  setPdfFile,
  updateUserDetails,
} from "../../store/actions/form";
import { AdAccountState } from "../../store/types/adaccount";
import { initialState } from "../../store/types/auth";
import { CommonState } from "../../store/types/common";
import { FormState } from "../../store/types/form";
import CheckBox from "../../ui/CheckBox/CheckBox";
import InputText from "../../ui/InputText/InputText";
import { UserDetailsFormValidationFields } from "../../utils/constants/userForm";
import Loader from "../common/Loader";
import ModalContainer from "../Modal/ModalContainer";
import ModalWrapper from "../Modal/ModalWrapper";
import "./index.scss";
import { BILLING_DASHBOARD_URL } from "../../utils/path";
import { PrivacyPolicy } from "Pages/PrivacyPolicy";

type formDataProp = {
  authorisedFullName: string;
  userEmail: string;
  userName?: string;
  createdAt?: any;
};

const UserDetails: React.FC<any> = () => {
  const dispatch = useDispatch();
  const formState = useSelector((state: { form: FormState }) => state.form);
  const { modal, pdfFile } = formState;
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  const common = useSelector((state: { common: CommonState }) => state.common);
  const { selectedAgency } = common;
  const [checkStatus, setCheckStatus] = React.useState(false);
  const [formDisable, setFormDisable] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [pdfFileData, setPdfFileData] = React.useState("");
  const [formData, setFormData] = React.useState<formDataProp>({
    authorisedFullName: "",
    userEmail: "",
  });
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const [formUpdated, setFormUpdated] = React.useState(false);

  const handleChange = (key: string, value: string | number) => {
    setFormData({ ...formData, [key]: value });
  };

  const validateForm = (data: any) => {
    for (
      let index = 0;
      index < UserDetailsFormValidationFields.length;
      index++
    ) {
      if (
        !(UserDetailsFormValidationFields[index] in data) ||
        !data[UserDetailsFormValidationFields[index]]
      ) {
        return false;
      }
    }
    return true;
  };

  const submitDetails = () => {
    if (checkStatus) {
      if (validateForm(formData) && auth) {
        setLoader(true);
        dispatch(
          updateUserDetails(
            {
              agencyId: selectedBrand.agencyId,
              user: auth.user,
              param: formData,
              userType: selectedAgency?.userType,
            },
            (response: any, error: boolean) => {
              setLoader(false);
              if (!error) {
                dispatch(setModal({ key: "confirmation" }));
              } else {
                dispatch(setModal({ key: "error" }));
              }
            }
          )
        );
      }
    } else {
      dispatch(setModal({ key: "termsAndConditionWarning" }));
    }
  };

  const getAgreementData = () => {
    setLoader(true);
    dispatch(
      getAgreement(
        { agencyId: selectedAgency.id, user: auth?.user },
        (response: any, error: boolean) => {
          setLoader(false);
          if (response && response.data && !error) {
            setFormData(response.data);
            setFormUpdated(true);
          }
        }
      )
    );
  };

  useEffect(() => {
    if (pdfFileData) {
      const file = new Blob([pdfFileData], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      dispatch(setPdfFile(fileURL));
      console.log("LL: fileURL", fileURL);
    } else {
      dispatch(setPdfFile(null));
    }
  }, [pdfFileData]);

  useEffect(() => {
    if (auth && selectedBrand && selectedBrand.agencyId) {
      dispatch(
        getClientPDF(
          {
            agencyId: selectedBrand.agencyId,
            user: auth?.user,
            userType: selectedAgency?.userType,
          },
          (response: any, error: boolean) => {
            if (!error) {
              setPdfFileData(response);
            } else {
              setPdfFileData("");
            }
          }
        )
      );
      getAgreementData();
    }
  }, [selectedBrand, selectedAgency]);

  useEffect(() => {
    if (formData && validateForm(formData)) {
      setFormDisable(false);
    } else {
      setFormDisable(true);
    }
  }, [formData]);

  return (
    <>
      <div className="flex  pb-3">
        <h4 className="font-weight-bold text-xl-left fs-6 m-0 w-auto m-0 navigation--header">
          User agreement
        </h4>
        <button
          onClick={() => window.open(BILLING_DASHBOARD_URL, "_blank")}
          className="btn primary"
        >
          Billing Dashboard
        </button>
      </div>
      <div
        className="d-flex flex-column p-4 bg-white flex-grow-1 box-shadow rounded user-details"
        style={{ height: "20px", overflow: "scroll", marginBottom: "20px" }}
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="user-details d-flex">
              <div key="user_email" className="form-group width-40">
                <label className="text-md form-title">User Email ID *</label>
                <InputText
                  name="user_email"
                  disable={formUpdated}
                  value={formData.userEmail}
                  onChange={(e: any) =>
                    handleChange("userEmail", e.target.value)
                  }
                />
              </div>
              <div key="authorisedFullName" className="form-group width-40">
                <label className="text-md form-title">
                  Authorised representative full name *
                </label>
                <InputText
                  name="authorisedFullName"
                  value={formData.authorisedFullName}
                  onChange={(e: any) =>
                    handleChange("authorisedFullName", e.target.value)
                  }
                />
              </div>
              <div></div>
            </div>
            <div className="user-details-form d-flex flex-wrap">
              {pdfFile && (
                <iframe
                  src={`${pdfFile}#toolbar=0&navpanes=0`}
                  width="100%"
                  height="100%"
                  className="agreement"
                ></iframe>
              )}
            </div>
            <div className="footer-user-details">
              <div className="terms pb-2">
                <CheckBox
                  disabled={formUpdated}
                  value={formUpdated}
                  onChange={(value) => {
                    if (value) {
                      setCheckStatus(true);
                    } else {
                      setCheckStatus(false);
                    }
                  }}
                />{" "}
                I agree to the terms and condition
              </div>
              <div className="footer-button pt-3">
                <div className={"approved-by"}>
                  {auth &&
                    formUpdated &&
                    `User agreement Approved by ` +
                      formData.userName +
                      ` at ` +
                      new Date(formData.createdAt).toLocaleString()}{" "}
                </div>
                <div>
                  <button
                    onClick={submitDetails}
                    disabled={formDisable || formUpdated}
                    className="btn primary"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
            {modal && (
              <ModalWrapper>
                {(modal.key === "termsAndConditionWarning" ||
                  modal.key === "error") && (
                  <ModalContainer
                    title={"Warning"}
                    submitText={"OK"}
                    handleClose={() => dispatch(setModal(null))}
                    handleSubmit={() => {
                      dispatch(setModal(null));
                    }}
                    tagline={""}
                  >
                    {modal.key === "error" ? (
                      <p>
                        Form not submitted successfully. Please connect with
                        user admin.
                      </p>
                    ) : (
                      <p>
                        Please read the terms and conditions and agree the same
                        to proceed further.
                      </p>
                    )}
                  </ModalContainer>
                )}
                {modal.key === "confirmation" && (
                  <ModalContainer
                    title={"Updated Successfully"}
                    submitText={"Continue"}
                    handleClose={() => {
                      setFormUpdated(true);
                      getAgreementData();
                      dispatch(setModal(null));
                    }}
                    handleSubmit={() => {
                      setFormUpdated(true);
                      getAgreementData();
                      dispatch(setModal(null));
                    }}
                    tagline={""}
                  >
                    <p>Your User Agreement has been successfully Updated.</p>
                  </ModalContainer>
                )}
              </ModalWrapper>
            )}
          </>
        )}
      </div>
      <PrivacyPolicy />
    </>
  );
};

export default UserDetails;

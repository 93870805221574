import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import GsaiLoader from "../../../assets/Gif/GsaiLoader.gif";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import ConfigureAd from "../../../components/CreativeAI/GsaiPlayground/ConfigureAd";
import CreateAdVariants from "../../../components/CreativeAI/GsaiPlayground/CreateVariants/CreateAdVariants";
import Modal from "../../../components/CreativeAI/GsaiPlayground/modals";
import ModalContainer from "../../../components/Modal/ModalContainer";
import ModalWrapper from "../../../components/Modal/ModalWrapper";
import AdsList from "./AdsList";
import CommonLoader from "../../../assets/Gif/CommonLoader.gif";
import { ReactComponent as Tick } from "../../../assets/svg/tick.svg";
import { ReactComponent as Failure } from "../../../assets/svg/red-failure.svg";
import useHeadlinesAndDescription from "./hooks";
import {
  StyledGSAILoaderGif,
  StyledGSAILoaderContainer,
  StyledGSAILoaderStep,
  StyledGSAICommonLoader,
  StyledGSAILoader,
  StyledGSAIText,
  StyledGSAIStepContainer,
  StyledMetricInfo,
  StyledErrorFail,
} from "./styled";
import { useAuth0 } from "@auth0/auth0-react";
import { mixPanelAdEvent } from "utils/mixpanel";
import { AdAccountState } from "store/types/adaccount";
import ReferenceModal from "./ReferenceModal";
import ReviewModal from "./ReviewModal ";
import ReviewFlowModal from "components/CreativeAI/Facebook/shared/ReviewFlowModal/ReviewFlowModal";
import { Popover } from "@mui/material";
import ErrorScreen from "components/shared/ErrorScreen/ErrorScreen";
import {
  CreateVariantsState,
  SUBMIT_FOR_REVIEW_ERROR,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { submitForReview } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { initialState } from "store/types/auth";

const StyledTick = styled(Tick)`
  height: 1.3rem;
  width: 1.3rem;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1201;

  .left-bar {
    height: 100%;
    width: 64px;
    background: #fff;
    padding: 36px 20px;

    button {
      padding: 0;
      border: none;
      background: transparent;

      svg {
        width: 24px;
      }
    }
  }

  .gsai-content {
    background: #f5f5f5;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 24px;
  }
`;

function Content({
  businessUnitLoader,
  keywordLoader,
  keywordFailer,
  headlinedesLoader,
  descriptionLoader,
  modal,
  isCreate,
}: any) {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  return (
    <Box
      width="36vw"
      height="70vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
    >
      <StyledGSAILoaderContainer>
        <StyledGSAILoaderGif src={GsaiLoader} alt="" />
        <StyledGSAIStepContainer>
          <StyledGSAILoaderStep>
            <StyledGSAILoader>
              {businessUnitLoader || modal === "publishing-ad" ? (
                <StyledGSAICommonLoader src={CommonLoader} alt="CommonLoader" />
              ) : (
                <StyledTick />
              )}
            </StyledGSAILoader>
            <StyledGSAIText>
              {modal !== "publishing-ad"
                ? isCreate
                  ? "Analyzing brand details"
                  : "Analyzing brand details and performance of current ads"
                : "Publishing Ad"}
            </StyledGSAIText>
          </StyledGSAILoaderStep>
          {modal !== "publishing-ad" && (
            <>
              <StyledGSAILoaderStep>
                <StyledGSAILoader>
                  {keywordLoader ? (
                    <StyledGSAICommonLoader
                      src={CommonLoader}
                      alt="CommonLoader"
                    />
                  ) : keywordFailer ? (
                    <>
                      <Failure
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        height={"1.3rem"}
                        width={"1.3rem"}
                      />
                      <Popover
                        id="mouse-over-popover"
                        className="content"
                        sx={{
                          pointerEvents: "none",
                        }}
                        PaperProps={{
                          style: {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            borderRadius: 0,
                          },
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <Box
                          mb="1.3em"
                          display="flex"
                          className="metric-popup"
                          sx={{
                            marginRight: "1em",
                            borderRadius: "0.625em",
                            marginTop: "0.5em",
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              "&::before": {
                                background: "#FFF",
                                content: '""',
                                display: "block",
                                position: "absolute",
                                width: "4em",
                                height: "2em",
                                left: "11em",
                                transform: "rotate(-45deg)",
                                zIndex: 0,
                                boxShadow: "0 0px 2px rgb(0 0 0 / 20%)",
                                bottom: 0,
                              },
                            }}
                          />
                          <StyledMetricInfo className="metric-popover">
                            <div className="flex-center justify-content-between text-md">
                              No keywords present in the ad group. Add them on
                              Google Ads Manager
                            </div>
                          </StyledMetricInfo>
                        </Box>
                      </Popover>
                    </>
                  ) : (
                    <StyledTick />
                  )}
                </StyledGSAILoader>
                <StyledGSAIText>
                  Exploring the most relevant keywords for contextual ad copies
                  <br />
                  {keywordFailer && (
                    <StyledErrorFail>
                      Error Ad Group has no Keywords, kindly add manually or
                      retry later!
                    </StyledErrorFail>
                  )}
                </StyledGSAIText>
              </StyledGSAILoaderStep>
              <StyledGSAILoaderStep>
                <StyledGSAILoader>
                  {headlinedesLoader ? (
                    <StyledGSAICommonLoader
                      src={CommonLoader}
                      alt="CommonLoader"
                    />
                  ) : (
                    <StyledTick />
                  )}
                </StyledGSAILoader>
                <StyledGSAIText>
                  Generating recommendations based on your preferences
                </StyledGSAIText>
              </StyledGSAILoaderStep>
              <StyledGSAILoaderStep>
                <StyledGSAILoader>
                  {descriptionLoader ? (
                    <StyledGSAICommonLoader
                      src={CommonLoader}
                      alt="CommonLoader"
                    />
                  ) : (
                    <StyledTick />
                  )}
                </StyledGSAILoader>
                <StyledGSAIText>
                  Refining the ad copies to make them more resonating
                </StyledGSAIText>
              </StyledGSAILoaderStep>
              {keywordFailer && (
                <StyledGSAILoaderStep className="justify-content-center">
                  <button
                    className="btn primary"
                    onClick={() => {
                      dispatch({
                        type: "UPDATE_GSAI_STORE",
                        payload: {
                          screen: 0,
                          modal: "",
                        },
                      });
                    }}
                  >
                    Go Back
                  </button>
                </StyledGSAILoaderStep>
              )}
            </>
          )}
        </StyledGSAIStepContainer>
      </StyledGSAILoaderContainer>
    </Box>
  );
}

function GsaiPlayground() {
  const [closeModal, setCloseModal] = React.useState<boolean>(false);
  const {
    screen,
    modal,
    closeAdConfig,
    adUnit,
    recommendations,
    showClose,
    saveAdConfig,
    dnd,
    businessUnitLoader,
    keywordLoader,
    keywordFailer,
    headlinedesLoader,
    descriptionLoader,
    isCreate,
  } = useHeadlinesAndDescription();
  const dispatch = useDispatch();
  const { user: authUser } = useAuth0();
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { approverLink } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const {
    selectedAdAccount: { adAccountId },
  } = useSelector((state: any) => state.aiGroupReducer);
  const displayLink = () => {
    dispatch({
      type: "UPDATE_GSAI_STORE",
      payload: {
        modal: "review-flow-modal",
      },
    });
  };
  const handleSubmitForReview = (variantId?: string) => {
    dispatch(
      submitForReview({
        params: {
          platform: "GOOGLE",
          type: "CREATIVE",
          brandId: selectedBrand?.id,
          variants: [
            {
              id: variantId ? variantId : adUnit.variant_id,
            },
          ],
          user: auth.user,
          adAccountId,
        },
      })
    );
    displayLink();
  };
  const handelRedirect = (item: any, screenData: any, setCloseModal: any) => {
    if (screenData == 1) {
      dispatch({
        type: "UPDATE_GSAI_STORE",
        payload: {
          variantName: "",
          finalUrl: "",
          screen: 0,
          isCreate: false,
          adUnit: {},
          error: null,
          bUnits: [],
          hnd: {},
          keywords: [],
          modal: "",
          recommendations: {
            requestId: "",
            error: "",
            status: "initial",
            data: [],
          },
          headlines: {
            requestId: "",
            error: "",
            status: "initial",
            data: [],
          },
          descriptions: {
            requestId: "",
            error: "",
            status: "initial",
            data: [],
          },
          dnd: {
            headlines: {
              recommended: [],
              toBePublished: [],
              more: [],
            },
            descriptions: {
              recommended: [],
              toBePublished: [],
              more: [],
            },
          },
          noAdsAvailable: false,
          replacementConfig: {
            isEnabled: false,
            headlines: {
              lowPerformance: { isChecked: false, value: 0 },
              goodPerformance: { isChecked: false, value: 0 },
              bestPerformance: { isChecked: false, value: 0 },
            },
            descriptions: {
              lowPerformance: { isChecked: false, value: 0 },
              goodPerformance: { isChecked: false, value: 0 },
              bestPerformance: { isChecked: false, value: 0 },
            },
          },
        },
      });
      setCloseModal(false);
    } else if (screenData === 0 && item?.length === 0) {
      window.location.href = "/singlePlatform" + window.location.search;
    }
  };

  useEffect(() => {
    dispatch({
      type: "GET_ALL_BUSINESS_UNITS",
      payload: {
        order: "ASC",
        orderBy: "businessUnit",
        limit: 50,
        brandId: selectedBrand?.id,
      },
    });
  }, []);

  useEffect(() => {
    mixPanelAdEvent("Page View - Dashboard", {
      module_name: "Google Search Playground",
      sub_module_name: null,
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_name: selectedBrand?.agency_name,
      agency_id: selectedBrand?.agencyId,
      email: authUser?.email,
      username: authUser?.name,
    });
  }, []);

  return (
    <Container>
      {closeModal && (
        <ModalWrapper>
          <ModalContainer
            title="Close ad creator"
            submitText="Confirm"
            cancelText="Cancel"
            handleSubmit={() => {
              handelRedirect(modal, screen, setCloseModal);
            }}
            handleClose={() => {
              setCloseModal(false);
            }}
          >
            <p>
              All changes will be lost if the slider is closed without saving
              the ad
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
      {modal === "reference-modal" && <ReferenceModal adUnit={adUnit} />}
      {modal === "review-modal" && <ReviewModal adUnit={adUnit} />}
      {modal === "review-flow-modal" && (
        <ReviewFlowModal
          show={modal === "review-flow-modal"}
          platform="google"
          onClose={() => {
            dispatch({
              type: "UPDATE_GSAI_STORE",
              payload: {
                modal: "",
                screen: 0,
              },
            });
            // window.location.href = `/google-search-playground?brand_id=${selectedBrand?.id}`;
          }}
        />
      )}
      {approverLink.isError && (
        <ErrorScreen
          actionMessage="Click Refresh to retry or Go Back to the previous page"
          actionText="Refresh"
          mainMessage="Review Request Generation Failed"
          cancelText="Go Back"
          onClose={() => {
            dispatch({
              type: SUBMIT_FOR_REVIEW_ERROR,
              payload: false,
            });
          }}
          show={approverLink.isError}
          handleSubmit={() => {
            handleSubmitForReview();
          }}
        />
      )}
      {modal === "configure-ad" ? (
        <ConfigureAd
          adUnit={adUnit}
          generateData={saveAdConfig}
          handleClose={closeAdConfig}
          recommendations={recommendations}
          showClose={showClose}
          dnd={dnd}
        />
      ) : null}
      {modal === "generating-recommendations" ||
      modal === "generating-hnd" ||
      "publishing-ad" === modal ? (
        <Modal>
          <Content
            businessUnitLoader={businessUnitLoader}
            keywordLoader={keywordLoader}
            keywordFailer={keywordFailer}
            headlinedesLoader={headlinedesLoader}
            descriptionLoader={descriptionLoader}
            modal={modal}
            isCreate={isCreate}
          />
        </Modal>
      ) : null}
      <div className="left-bar">
        <button onClick={() => setCloseModal(true)}>
          <Close />
        </button>
      </div>
      <div className="gsai-content">
        {screen === 1 && <CreateAdVariants adUnit={adUnit} screen={screen} />}
        <AdsList screen={screen} />
      </div>
    </Container>
  );
}

export default GsaiPlayground;

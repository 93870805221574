import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import mixpanel from "mixpanel-browser";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import MuiDrawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";

import { CSSObject, styled, Theme } from "@mui/material/styles";

import Journey from "./Journey";
import SideBarAccordian from "./SideBarAccordian";
import Loader from "../common/Loader";
import SearchableSelectNew from "../../ui/SearchableSelect/SearchableSelectNew";

import { AccountsManagerState } from "../../store/types/accountsManager";
import { AdAccountState, brand } from "../../store/types/adaccount";
import {
  getAgency,
  getAgencyAssets,
  setAgencyAssets,
  setDefaultAgencyAssets,
  setSelectedAgency,
} from "../../store/actions/common";
import {
  getInitials,
  getRolesHierarchy,
  isAdminRole,
} from "../../utils/commonFunctions";
import { getPhase } from "../../store/actions/auth";
import { initialState } from "../../store/types/auth";
import { setActiveBrand } from "../../store/actions/adaccount";

import { ReactComponent as AccountsManager } from "./assets/AccountsManager.svg";
import { ReactComponent as PixisLogo } from "./assets/PixisLogo.svg";
import SettingIcon from "assets/svg/Setting.svg";
import { REACT_APP_BASE_URL_PIXIS2 } from "utils/path";
import { getSearchParams } from "utils/commonFunctions";

import {
  getAgenciesSmb,
  setAgenciesSmb,
} from "../../store/actions/SmbDashboard/agencies";

import "./SideBar.scss";
import { resetBrandDetails } from "../../store/actions/SmbDashboard/brand";
import { Skeleton, Stack, Typography } from "@mui/material";
import {
  setAdSet,
  setSelectedAdAccount,
  setSelectedCampaignDebugger,
} from "store/actions/ActionDebugger/ActionDebuggerHelper";
import { mixPanelAdEvent } from "utils/mixpanel";
import { useManageAgencyUserRolesState } from "Pages/ManageAgency/UserRoles/useManageAgencyUserRolesState";
import { collator } from "utils/strings";

const drawerWidth = "24rem";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderTopRightRadius: "1rem",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  borderTopRightRadius: "1rem",
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ChildComponent = ({
  value,
  dataPoint,
}: {
  value: string;
  dataPoint: brand;
}): JSX.Element => {
  return (
    <>
      <input
        type="radio"
        name="paltform-nav"
        className="cursor-pointer"
        value={dataPoint.label}
        checked={value === dataPoint.label}
        onChange={(e) => e.preventDefault()}
      />
      <div className="radio-label">
        <div className="ml-2 text-md"> {dataPoint.label}</div>
        <div className="ml-2 text-sm color-grey"> {dataPoint.agency_name}</div>
      </div>
    </>
  );
};

export default function SideBar({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: any;
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [brandOptions, setBrandOptions] = useState<brand[]>([]);

  const { user } = useAuth0();
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles, contextInfo } = adaccount;
  const authState = useSelector((state: { auth: initialState }) => state.auth);
  const { agencyAssets, loadingAgencyAssets } = useSelector(
    (state: any) => state.common
  );

  const { selectedBrand, brands, selectedPlatform } = adaccount;

  const agenciesState: any = useSelector((state: any) => state.smb.agencies);
  const { userInfo } = useSelector((state: any) => state.userInfoReducer);
  const [userName, setUserName] = useState<string>("");
  const params = new URLSearchParams(window.location.search);
  const agencyId = params.get("agency-id");
  useEffect(() => {
    if (user?.name) {
      mixpanel.identify(user?.name);
      mixpanel.people.set({ $name: user?.name, $email: user?.email });
      if (user?.name?.includes("@")) {
        const strArr = user?.name?.split("@");
        let name = strArr[0];
        setUserName(name);
      } else {
        setUserName(user?.name);
      }
    }
  }, [user]);

  useEffect(() => {
    var userId = user?.sub || null;
    (window as any)?.hj("identify", userId, user || {});
  }, [user]);

  useEffect(() => {
    if (user && selectedBrand) {
      dispatch(getPhase());
    }
  }, [selectedBrand?.id, user]);

  useEffect(() => {
    dispatch(
      getAgencyAssets(
        { agencyId: selectedBrand?.agencyId },
        (response: any, error: boolean) => {
          if (!error && response?.data?.data) {
            dispatch(setAgencyAssets(response?.data?.data));
          } else {
            dispatch(setDefaultAgencyAssets());
            console.log(error);
          }
        }
      )
    );
    if (user && agencyId) {
      dispatch(
        getAgency(
          { agencyId: selectedBrand?.agencyId, user },
          (response: any, error: boolean) => {
            if (!error) {
              const selectedAgency = agenciesState?.data.find(
                (agency: any) => agency.id === selectedBrand.agencyId
              );
              dispatch(setSelectedAgency(response?.data));
            } else {
              console.log(error);
            }
          }
        )
      );
      dispatch(getAgenciesSmb({ user, agencyId: selectedBrand?.agencyId }));
    }
  }, [selectedBrand, user, agencyId]);

  useEffect(() => {
    if (brands.length > 0) {
      brands.map((brand: any) => {
        brand.label = brand.name;
      });

      setBrandOptions(
        brands.sort((brand1, brand2) =>
          collator.compare(brand1.name.trim(), brand2.name.trim())
        )
      );
    }
  }, [brands]);
  useEffect(() => {
    if (user) dispatch(getAgenciesSmb({ user, agencyId: agencyId }));
  }, [user, agencyId]);

  const isEnabled: boolean = useMemo(
    () => (selectedBrand ? selectedBrand.enabled : false),
    [selectedBrand]
  );
  const onBrandChange = (brand: brand) => {
    dispatch(setSelectedCampaignDebugger([]));
    dispatch(setAdSet([]));
    dispatch(setSelectedAdAccount({}));
    dispatch(setActiveBrand(brand as any));
    history.push({
      pathname: "/singlePlatform",
      search: ("?brand_id=" + brand.id) as any,
    });
  };
  useEffect(() => {
    if (selectedBrand?.agencyId) {
      dispatch(
        getAgency(
          { agencyId: selectedBrand?.agencyId, user },
          (selectedAgencyDetail: any) => {
            dispatch(setSelectedAgency(selectedAgencyDetail?.data));
          }
        )
      );
    }
  }, [selectedBrand, user]);

  const filterBrandOptions = (value: string) => {
    if (value) {
      let newBrandOptions = brands.filter(
        (brand: any) =>
          brand.label && brand.label.toLowerCase().includes(value.toLowerCase())
      );
      setBrandOptions(newBrandOptions);
    } else {
      setBrandOptions(brands);
    }
  };
  const handleLogout = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setAgenciesSmb([]));
    history.push("/auth/logout");
    mixPanelAdEvent("Logout Successful", {
      agency_name: selectedBrand?.agency_name,
      agency_id: selectedBrand?.agencyId,
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      email: user?.email,
      username: user?.name,
      source: "Dashboard",
    });
  };

  const customProps = {
    id: "dashboard-selected-brand",
    "data-brand-id": selectedBrand && selectedBrand.id,
    "data-brand-name": selectedBrand && selectedBrand.name,
    "data-agency-name": selectedBrand && selectedBrand.agency_name,
  };
  const accountsManager = useSelector(
    (state: { accountsManager: AccountsManagerState }) => state.accountsManager
  );

  const handlePixisLogoClick = () => {
    if (!history.location.pathname.includes("/smb-dashboard/agency")) {
      dispatch(resetBrandDetails());
      agenciesState.data.length > 0
        ? history.push(
            "/smb-dashboard/agency" +
              `?agency-id=${selectedBrand.agencyId}&brand_id=${selectedBrand.id}`
          )
        : console.log("not smb");
    }
    return;
  };

  // const { isAgencyAdmin } = useManageAgencyUserRolesState();
  // const isSuperAdmin = userInfo?.roles?.includes("super-admin");

  const isSuperAdmin = isAdminRole(brandRoles);
  const isAgencyAdmin = isAdminRole(brandRoles);

  return (
    <>
      {user && (
        <Box
          onMouseLeave={(e: any) => {
            e.preventDefault();
            setOpen(false);
          }}
          sx={{ display: "flex" }}
          className="side-bar-new"
          onMouseOver={(e: any) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          <CssBaseline />

          <Drawer variant="permanent" open={open}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "4.3rem", padding: open ? 2 : 0 }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={open ? "" : "center"}
                width={"95%"}
              >
                <Box mr={open ? 2 : 0}>
                  {/* <PixisLogo
                    className={`justify-self-center ${
                      agenciesState.data.length > 0 ? "pointer-cursor" : ""
                    }`}
                    onClick={handlePixisLogoClick}
                  /> */}
                  {loadingAgencyAssets ? (
                    <Skeleton
                      variant="circular"
                      width={"2.3rem"}
                      height={"2.3rem"}
                    />
                  ) : (
                    <img
                      src={agencyAssets?.logo}
                      className={`justify-self-center ${
                        agenciesState.data.length > 0 ? "pointer-cursor" : ""
                      }`}
                      onClick={handlePixisLogoClick}
                      style={{ width: "2.3rem", height: "2.3rem" }}
                      alt="logo"
                    />
                  )}
                </Box>
                {open &&
                  (loadingAgencyAssets ? (
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1.625rem", width: "5rem" }}
                    />
                  ) : agencyAssets?.textLogoDark ? (
                    <img
                      src={agencyAssets?.textLogoDark}
                      style={{ height: "0.9375rem" }}
                      alt="wordmark"
                    />
                  ) : (
                    <Box className="justify-self-start side-bar-heading">
                      {agencyAssets?.title}
                    </Box>
                  ))}
              </Stack>
              {open && contextInfo?.isBrand && (
                <Box>
                  <img
                    style={{ cursor: "pointer" }}
                    src={SettingIcon}
                    alt="admin panel"
                    onClick={() => {
                      window.open(
                        `${REACT_APP_BASE_URL_PIXIS2}${"/adminpanel/overview/agencies"}?brandId=${
                          selectedBrand?.id
                        }&source=legacy${
                          getSearchParams("group_id")
                            ? "&aiGroupId=" + getSearchParams("group_id")
                            : ""
                        }`,
                        "_blank"
                      );
                    }}
                  />
                </Box>
              )}
            </Stack>
            {/* </Box> */}
            <Divider />
            <Box
              className={`d-flex${
                open
                  ? " justify-content-start w-100"
                  : " align-items-center justify-content-center"
              }`}
              sx={{ p: 2 }}
            >
              {open ? (
                <SearchableSelectNew
                  customProps={customProps}
                  value={
                    (selectedBrand && selectedBrand.name) || ` No Brand Found`
                  }
                  data={brandOptions}
                  onChange={onBrandChange}
                  filterOptions={filterBrandOptions}
                  labelComponent={ChildComponent}
                />
              ) : (
                <Avatar
                  sx={{
                    bgcolor: "#FF8702",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {selectedBrand &&
                    selectedBrand.name &&
                    getInitials(selectedBrand.name)}
                </Avatar>
              )}
            </Box>
            <SideBarAccordian
              isSuperAdmin={isSuperAdmin}
              isAgencyAdmin={isAgencyAdmin}
              open={open}
              setOpen={setOpen}
              disabled={
                accountsManager?.isAppDisabled
                  ? accountsManager.isAppDisabled
                  : false || selectedBrand?.isDisableDashboard
              }
              user={user}
            />
            <Box sx={{ mt: "auto", pt: 1, pb: 2 }}>
              <Box className="w-100">
                {open && (
                  <>
                    <Box sx={{ px: 2, pb: 2 }}>
                      <Journey enabled={isEnabled} phases={authState?.phase} />
                      <Button
                        variant="outlined"
                        startIcon={<AccountsManager />}
                        sx={{
                          border: "none",
                          boxShadow: 2,
                          color: "#292D32",
                          mt: 2,
                        }}
                        className="w-100"
                        onClick={() =>
                          history.push(
                            "/accounts-manager" + window.location.search
                          )
                        }
                      >
                        Accounts Manager
                      </Button>
                    </Box>
                    <Divider
                      variant="middle"
                      className="w-100"
                      sx={{ background: "black", color: "black", m: 0 }}
                    />
                  </>
                )}

                <Box sx={{ px: 2, pt: 1 }}>
                  <Box
                    className={`d-flex align-items-center ${
                      open ? "w-100" : "justify-content-center"
                    }`}
                    sx={authState && { cursor: "pointer" }}
                    onClick={() => {
                      // if (selectedAgency?.agreementType) {
                      history.push(`/user-details${history.location.search}`);
                      // }
                    }}
                  >
                    <Avatar
                      className="h-100 d-flex align-items-center"
                      src={user && user.picture ? user.picture : undefined}
                    />
                    {open && (
                      <Box sx={{ ml: 2 }} className="d-flex w-100">
                        <Box>
                          <Box className="side-bar-user-name">
                            <Typography sx={{ textTransform: "capitalize" }}>
                              {userName || "-"}
                            </Typography>
                          </Box>
                          <Box className="side-bar-user-email">
                            {user?.email || "-"}
                          </Box>
                        </Box>
                        <Box
                          className="p-0 link-primary text-md d-flex align-items-center"
                          role="button"
                          onClick={handleLogout}
                          sx={{ ml: "auto" }}
                        >
                          <LogoutIcon />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      )}
    </>
  );
}

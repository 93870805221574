// TBO - need to refactor

import { useVGSPollingHook } from "components/CreativeAI/Facebook/CreateVariants/Editor/hooks/useVgsPollingHook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_REQUEST_ID } from "store/types/CreativeAI/Facebook/BundleGeneration/bundleGeneration";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { InitialMediaStudioType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { AdAccountState } from "store/types/adaccount";
import { initialState } from "store/types/auth";
import {
  checkAdType,
  checkAdTypeForPublish,
  fetchAdTypeField,
  mapAdAdSetToElement,
} from "../../utils/common.utils_old";
import { APIGenerationType } from "../../facebook.types";
import { filterVariantData } from "../../utils/filterLevers.utils";
import {
  SET_AD_ADSET_FIELD_LIST,
  SET_ELEMENT_CREATIVE_AI,
} from "store/types/CreativeAI/Facebook/Main/Main";
import {
  getBillboardBundle,
  getBundleGenerate,
} from "store/actions/CreativeAI/Facebook/BundleGeneration/bundleGeneration";
import {
  generateConfigurePayload,
  getFormValues,
} from "../../utils/form.utils";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { useCreativeAnimator } from "./useCreativeAnimator";
import {
  setAutogenerateAdId,
  setAutogenerateData,
} from "store/actions/CreativeAI_Revamp/facebook.actions";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { fetchPerformanceScore } from "store/actions/CreativeAI_Revamp/HomeScreen/Editor/editor.actions";
export const useAutoGenerate = () => {
  //dispatch
  const dispatch = useDispatch();

  // redux states
  const { variantList, selectedAd, createSpec } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const { adAccountsSelection, adSetSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const {
    adsList,
    aiGroupList: { selectedAIGroup },
    autogenerate,
  } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );
  const {
    bundleContext,
    isBundleMediaStudioProcessingFailure,
    isBundleMediaStudioProcessing,
  } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );

  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { BUSINESS_UNIT_BY_ID_DATA, CREATIVE_ELEMENT_AI } = useSelector(
    (state: { selectCohortReducer: any; MainReducer: any }) => ({
      BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
      CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi,
    })
  );

  const { getMediaStudioData } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const {
    isFailed,
    isLoading,
    onClickCreateAnimater,
    setIsLoading,
    onCloseCreateAnimater,
    adVariants,
    resetFlag,
  } = useCreativeAnimator();
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  const { LEVERS } = useSelector((state: any) => ({
    LEVERS: state.MainReducer.defaultLevers,
  }));

  const { COHORT_DATA } = useSelector(
    (state: { selectCohortReducer: any; createVariantReducer: any }) => ({
      COHORT_DATA: state?.selectCohortReducer?.cohortData,
    })
  );
  // const { socket } = useSelector(
  //   (state: { navigationReducer: NavigationInitialState }) =>
  //     state.navigationReducer
  // );
  // local vcariables

  const data: APIGenerationType[] = useMemo(
    () =>
      filterVariantData(LEVERS, [
        "user",
        "ad",
        "adset",
        "campaign",
        "ad_video",
        "ad_image",
      ]),
    [LEVERS]
  );

  // common functions
  const getDisplayUrlAndUrl = useCallback(() => {
    const { asset_feed_spec, object_story_spec } = createSpec;
    const { video_data, link_data } = object_story_spec;
    if (link_data) {
      return {
        displayUrl: link_data?.caption ?? "",
        url: createSpec?.object_story_spec?.link_data?.link ?? "",
      };
    } else if (video_data) {
      return {
        displayUrl: video_data?.call_to_action?.value?.link_caption ?? "",
        url: video_data?.call_to_action?.value?.link ?? "",
      };
    } else if (asset_feed_spec) {
      const { link_urls } = asset_feed_spec;
      return {
        displayUrl: link_urls?.[0]?.display_url,
        url: link_urls?.[0]?.website_url,
      };
    }
    return { displayUrl: "", url: "" };
  }, [createSpec]);

  useEffect(() => {
    console.log(autogenerate);
  }, [autogenerate?.[selectedAd?.id]]);

  const generateCAIRecommendationsBG = (type: "Auto" | "Manual") => {
    dispatch(
      setAutogenerateData({
        path: selectedAd?.id,
        value: {
          isMediaStudioAvailable: selectedAd?.isMediaStudioAvailable,
          variantsCount: 0,
          inDraft: variantList?.length,
          status: {
            inProgress: true,
            isError: false,
            // step: number;
            // percentage: number;
            auto: type === "Auto" ? true : false,
          },
          mediaStudioData: {
            ...getMediaStudioData,
          },
          adsetId: adSetSelection?.selectedAdSetId,
          adAccountId: adAccountsSelection?.selectedAdAccountId,
          elementId: adAccountsSelection?.selectedElementId,
          headline: fetchAdTypeField(createSpec, "headline"),
          description: fetchAdTypeField(createSpec, "description"),
          primaryText: fetchAdTypeField(createSpec, "primary_text"),
          adType: checkAdType(createSpec),
          settings: {
            displayUrl: getDisplayUrlAndUrl().displayUrl || "",
            url: getDisplayUrlAndUrl().url || "",
            fbPage: createSpec?.object_story_spec?.page_id || "",
            instagramPage:
              createSpec?.object_story_spec?.instagram_actor_id || "",
            pixel: selectedAd?.tracking_specs?.[0]?.fb_pixel?.[0] || "",
            adType: checkAdTypeForPublish(createSpec),
            adName: selectedAd?.name,
          },
        },
      })
    );
    if (!autogenerate?.[selectedAd?.id]?.defaultScore) {
      dispatch(
        fetchPerformanceScore({
          body: {
            brand_name: selectedBrand?.name,
            primary_text: fetchAdTypeField(createSpec, "primary_text"),
            headline: fetchAdTypeField(createSpec, "headline"),
            description: fetchAdTypeField(createSpec, "description"),
          },
          callback: ({ error, performanceScore }) => {
            if (!error) {
              dispatch(
                setAutogenerateData({
                  path: [selectedAd?.id, "defaultScore"],
                  value: performanceScore,
                })
              );
            } else {
              dispatch(
                setAutogenerateData({
                  path: [selectedAd?.id, "defaultScore"],
                  value: null,
                })
              );
            }
          },
        })
      );
    }
    if (
      !autogenerate?.[selectedAd?.id]?.bundleRecommendations?.bundles?.length
    ) {
      dispatch(
        setAutogenerateData({
          path: [selectedAd?.id, "status"],
          value: { isError: false, inProgress: true, step: 0, progress: 0 },
        })
      );
      if (selectedBrand?.isCreativeAnimator) {
        dispatch(
          getBillboardBundle(
            {
              payload: {
                adAccountId: adAccountsSelection?.selectedAdAccountId,
                adId: selectedAd?.id,
                adSetId: adSetSelection?.selectedAdSetId,
                brandId: selectedBrand?.id,
                creativeImage: createSpec?.image_url,
                headline: fetchAdTypeField(createSpec, "headline"),
                description: fetchAdTypeField(createSpec, "description"),
                ctaText: createSpec?.call_to_action_type,
              },
            },
            (response: any, error: boolean) => {
              if (!error) {
                dispatch(
                  setAutogenerateData({
                    path: selectedAd?.id,
                    value: {
                      generateBulkVideos: response,
                    },
                  })
                );
              } else {
              }
            }
          )
        );
      }
      const createSpecAPI = selectedAd?.adcreatives?.data[0];
      const adType = checkAdType(createSpecAPI);
      let newLeverData: any = {};
      if (selectedAd?.isMediaStudioAvailable && getMediaStudioData?.isData) {
        if (adType === "link_data") {
          newLeverData = data.filter(
            (item) => item.generation_type !== "video"
          );
        } else {
          newLeverData = data.filter(
            (item) => item.generation_type !== "image"
          );
        }
      } else {
        newLeverData = data.filter(
          (item) =>
            item.generation_type !== "video" && item.generation_type !== "image"
        );
      }
      const { newCreativeElementAI, adAdSetFieldList } = mapAdAdSetToElement({
        createSpec: createSpecAPI,
        targeting: COHORT_DATA?.facebookData?.targeting,
        creativeElementAI: CREATIVE_ELEMENT_AI.data,
        levers: newLeverData,
        getMediaStudioData,
      });
      dispatch({
        type: SET_ELEMENT_CREATIVE_AI,
        payload: {
          data: newCreativeElementAI,
        },
      });
      dispatch({
        type: SET_AD_ADSET_FIELD_LIST,
        payload: adAdSetFieldList,
      });
      dispatch(
        getBundleGenerate(
          {
            user: auth.user,
            data: {
              adId: selectedAd.id,
              no_of_bundles: 9,
              ...generateConfigurePayload(
                {
                  ...getFormValues(newCreativeElementAI, true),
                  businessUnit: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
                  businessUnitDetail:
                    BUSINESS_UNIT_BY_ID_DATA?.businessUnitDetail,
                },
                newLeverData,
                "bundle",
                adType,
                selectedBrand?.id,
                selectedAd?.id,
                adAccountsSelection?.selectedAdAccountId
              ),
            },
            finalAction: SET_REQUEST_ID,
          },
          (response: any, error: any) => {
            if (!error) {
              dispatch(
                setAutogenerateData({
                  path: selectedAd?.id,
                  value: { requestId: response?.requestid },
                })
              );
            } else {
              dispatch(
                setAutogenerateData({
                  path: [selectedAd?.id, "status"],
                  value: { isError: true, inProgress: false },
                })
              );
            }
          }
        )
      );
    } else {
      dispatch(setAutogenerateAdId(selectedAd?.id));
      dispatch(
        setAutogenerateData({
          path: selectedAd?.id,
          value: {
            status: {
              inProgress: true,
              isError: false,
              auto: false,
            },
            inDraft: variantList?.length,
          },
        })
      );
    }
  };
  return {
    generateCAIRecommendationsBG,
    autogenerate,
  };
};

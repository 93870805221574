//@ts-nocheck
import React, { useEffect, useState } from "react";
import "./AllPlatformGraph.scss";
import { Pie, Line } from "react-chartjs-2";
import { AdAccountState } from "../../store/types/adaccount";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPlatformMetricsData,
  setSelectedOption,
  getAllPlatformCummulativeData,
  setAllPlatformSelectedOption,
} from "../../store/actions/campaign";
import { ActionImpactState } from "../../store/types/actionImpact";
import { initialState } from "../../store/types/auth";
import {
  formatAllPlatformChartInfo,
  formatAllPlatformChartOptions,
  getAllPlatformLabels,
} from "../../utils/commonFunctions";
import { CampaignState } from "../../store/types/campaign";
import { colors, commonOptions } from "../../utils/constants/actionImpact";
import {
  calculateCurrencyValue,
  getAdaccountList,
} from "../../utils/currencyConverter";
import {
  getCurrencyDetails,
  setAllPlatformAdaccountDetails,
  setAllPlatformChartCordsData,
  setAllPlatformChartData,
  setAllPlatformChartOption,
  setAllPlatformGraphSpinner,
  setAllPlatformPieSpinner,
  setCurrencyIdentifier,
} from "../../store/actions/actionImpact";
import { getAdAccountDetails } from "../../store/actions/adaccount";

interface Brand {
  label: string;
  data: number;
}
const AllPlatformGraph: React.FC = () => {
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedGroup, selectedGroupAdaccounts, platforms } = adaccount;
  const actionImpact = useSelector(
    (state: { actionImpact: ActionImpactState }) => state.actionImpact
  );
  const {
    allPlatformLinkClicks,
    allPlatformChartData,
    allPlatformChartCordsData,
    allPlatformChartOption,
    allPlatformGraphSpinner,
    pieSpinner,
    allPlatformAdaccountDetails,
    currencyIdentifier,
  } = actionImpact;
  const { user, ...authState } = useSelector(
    (state: { auth: initialState }) => state.auth
  );
  const campaign = useSelector(
    (state: { campaign: CampaignState }) => state.campaign
  );
  const { allPlatformSelectedOption } = campaign;
  const [pieChartData, setPieChartData] = useState<any>({});
  const [allPlatformMetricData, setAllPlatformMetricData] = useState<any>({});
  const [allPlatformPieChartData, setallPlatformPieChartData] = useState<any>(
    {}
  );
  const [resolutionChangeCount, setResolutionChangeCount] = useState<number>(0);
  const dispatch = useDispatch();

  const renameFacebookAd = (data: any) => {
    if (Array.isArray(data)) {
      // Use map to recursively call the function on each element
      return data.map((element) => renameFacebookAd(element));
    } else if (typeof data === "object" && data !== null) {
      // Iterate over each key in the object
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          // Recursively call the function on the value of the key
          data[key] = renameFacebookAd(data[key]);

          // Check if the value is "FACEBOOK AD" and rename it to "META AD"
          if (data[key] === "FACEBOOK AD") {
            data[key] = "META AD";
          }
        }
      }
    }
    return data;
  };

  const replaceAndSortBrands = (
    data: string[] | Brand[]
  ): string[] | Brand[] => {
    if (data.length === 0) {
      return data;
    }

    if (typeof data[0] === "string") {
      // Handle array of strings
      let stringArray = data as string[];
      // Replace "META AD" with "FACEBOOK AD"
      stringArray = stringArray.map((item) =>
        item === "META AD" ? "FACEBOOK AD" : item
      );
      // Sort the array
      stringArray.sort();
      // Replace "FACEBOOK AD" back with "META AD"
      stringArray = stringArray.map((item) =>
        item === "FACEBOOK AD" ? "META AD" : item
      );
      return stringArray;
    } else if (typeof data[0] === "object" && "label" in data[0]) {
      // Handle array of objects with label property
      let brandArray = data as Brand[];
      // Replace "META AD" with "FACEBOOK AD"
      brandArray = brandArray.map((item) => {
        if (item.label === "META AD") {
          return { ...item, label: "FACEBOOK AD" };
        }
        return item;
      });
      // Sort the array by the label property
      brandArray.sort((a, b) => a.label.localeCompare(b.label));
      // Replace "FACEBOOK AD" back with "META AD"
      brandArray = brandArray.map((item) => {
        if (item.label === "FACEBOOK AD") {
          return { ...item, label: "META AD" };
        }
        return item;
      });
      return brandArray;
    }

    // Return the data as is if it does not match expected formats
    return data;
  };

  useEffect(() => {
    if (selectedGroup?.id) {
      dispatch(setAllPlatformChartCordsData([]));
      dispatch(setAllPlatformChartData([]));
      dispatch(setAllPlatformGraphSpinner(true));
      dispatch(
        getAllPlatformMetricsData(
          {
            groupId: selectedGroup.id,
            dates: allPlatformLinkClicks,
            user: user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              if (response.data && Object.keys(response.data).length > 0) {
                getListofAdaccounts(response.data, selectedGroupAdaccounts);
                setAllPlatformMetricData(response.data);
                dispatch(
                  setAllPlatformChartOption(
                    formatAllPlatformChartOptions(response.data)
                  )
                );
                dispatch(setAllPlatformGraphSpinner(false));
              } else {
                dispatch(setAllPlatformGraphSpinner(false));
              }
            } else {
              dispatch(setAllPlatformGraphSpinner(false));
            }
          }
        )
      );
      dispatch(setAllPlatformPieSpinner(true));
      dispatch(
        getAllPlatformCummulativeData(
          {
            groupId: selectedGroup.id,
            dates: allPlatformLinkClicks,
            user: user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              if (response.data && Object.keys(response.data).length > 0) {
                if (response.data["FACEBOOK AD"]) {
                  response.data["META AD"] = response.data["FACEBOOK AD"];
                  delete response.data["FACEBOOK AD"];
                }
                setallPlatformPieChartData(response.data);
              } else {
                dispatch(setAllPlatformPieSpinner(false));
              }
            } else {
              dispatch(setAllPlatformPieSpinner(false));
            }
          }
        )
      );
    }
  }, [allPlatformLinkClicks, selectedGroupAdaccounts]);

  const getListofAdaccounts = (data: any, adaccounts: any) => {
    let adaccountList = getAdaccountList(adaccounts, data, platforms);
    Promise.all(
      adaccountList.map((adacc: any) => {
        return new Promise((res) => {
          dispatch(
            getAdAccountDetails(
              { adaccountId: adacc.id, platformType: adacc.platformType },
              (response: any, error: boolean) => {
                if (!error) {
                  res({
                    id: adacc.id,
                    currency: response.data.currency
                      ? response.data.currency.toLowerCase()
                      : "usd",
                  });
                }
              }
            )
          );
        });
      })
    ).then((result) => {
      dispatch(setAllPlatformAdaccountDetails(result));
    });
  };

  useEffect(() => {
    getCurrencyValues(allPlatformAdaccountDetails);
  }, [allPlatformAdaccountDetails]);

  const getCurrencyValues = (data: any) => {
    if (data.length > 0) {
      Promise.all(
        data.map((adacc: any) => {
          return new Promise((resolve) => {
            dispatch(
              getCurrencyDetails(
                { currencyName: adacc.currency },
                (response: any, error: boolean) => {
                  if (!error) {
                    resolve({
                      id: adacc.id,
                      value: response[adacc.currency].usd,
                    });
                  }
                }
              )
            );
          });
        })
      ).then((newresult) => {
        dispatch(setCurrencyIdentifier(newresult));
      });
    }
  };

  useEffect(() => {
    if (currencyIdentifier.length > 0) {
      dispatch(
        setAllPlatformChartData(
          formatAllPlatformChartInfo(
            allPlatformMetricData,
            allPlatformLinkClicks,
            selectedGroupAdaccounts,
            platforms,
            currencyIdentifier
          )
        )
      );
      formatPieChartData(allPlatformPieChartData, currencyIdentifier);
    }
  }, [currencyIdentifier]);

  const formatPieChartData = (data: any, currencyIdentifier: any) => {
    let labels: any = [];
    let datasets: any = [];
    labels = getAllPlatformLabels(data);
    let dataPoints: any = [];
    // Find the index of "FACEBOOK AD"
    const index = labels.indexOf("FACEBOOK AD");

    // Rename "FACEBOOK AD" to "META AD" if it exists in the array
    if (index !== -1) {
      labels[index] = "META AD";
    }
    let calculatedData = calculateCurrencyValue(
      data,
      labels,
      platforms,
      selectedGroupAdaccounts,
      currencyIdentifier
    );
    labels = labels.sort();
    calculatedData = calculatedData.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );
    labels = replaceAndSortBrands(labels);
    calculatedData = replaceAndSortBrands(calculatedData);
    if (calculatedData.filter((res: any) => res.data > 0).length >= 1) {
      datasets = [
        {
          label: "Spend",
          data: calculatedData.map((a: any) => a.data),
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
        },
      ];
      setPieChartData({ labels: labels, datasets: datasets });
      dispatch(setAllPlatformPieSpinner(false));
    } else {
      setPieChartData({ labels: [], datasets: [] });
      dispatch(setAllPlatformPieSpinner(false));
    }
  };

  useEffect(() => {
    if (allPlatformChartData) {
      const currency = "";
      let datasets = allPlatformChartData?.datasets?.map((d: any) => {
        return {
          ...d,
          data: d.data,
          backgroundColor: d?.backgroundColor,
          borderColor: d?.borderColor,
          currency,
        };
      });
      dispatch(
        setAllPlatformChartCordsData({ ...allPlatformChartData, datasets })
      );
    } else {
      dispatch(
        setAllPlatformChartCordsData({ ...allPlatformChartData, datasets: [] })
      );
    }
  }, [allPlatformChartData]);

  useEffect(() => {
    const dpiChangeListiner = (e) => {
      const matchMediaInstance = window.matchMedia(
        `screen and (resolution: ${window.devicePixelRatio}dppx)`
      );
      matchMediaInstance.addEventListener("change", dpiChangeListiner);
      setResolutionChangeCount((count) => count + 1);
    };
    dpiChangeListiner({});
  }, []);

  useEffect(() => {
    if (allPlatformChartOption) {
      let options = allPlatformChartOption?.map((o: any, index: number) => ({
        ...o,
        position: "left",
        display: true,
        ticks: {
          display: index === 0 ? true : false,
          stepSize: 10,
          beginAtZero: true,
          max: 100,
          min: 0,
        },
        gridLines: {
          display: index === 0 ? true : false,
        },
      }));
      dispatch(
        setAllPlatformSelectedOption({
          ...commonOptions,
          maintainAspectRatio: false,
          scales: {
            yAxes: options,
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "day",
                  unitStepSize: 1,
                  displayFormats: {
                    day: "MMM DD",
                  },
                },
              },
            ],
          },
          legend: { display: true, position: "bottom", onClick: {} },
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              label(tooltipItem: any, data: any) {
                const label = data.datasets[tooltipItem.datasetIndex].label;
                return ` ${label} : ${tooltipItem.yLabel.toFixed(2)}%`;
              },
            },
          },
        })
      );
    }
  }, [allPlatformChartOption]);

  const options = {
    legend: { display: true, position: "bottom", onClick: {} },
    maintainAspectRatio: false,
    datalabels: {
      display: true,
      color: "white",
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(2)
          );
          return percentage + "%";
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
    responsive: true,
  };

  return (
    <>
      {/* Handle Below Condition : (TypeError: Cannot read properties of undefined (reading 'getBasePixel')) */}
      {!(allPlatformGraphSpinner || pieSpinner) &&
      allPlatformChartData?.datasets?.length > 0 &&
      pieChartData?.datasets?.length > 0 ? (
        <>
          <div
            className={`all-platform-graph-container resolutionChangeCount-${resolutionChangeCount}`}
          >
            <div className="pie-chart-container">
              {!(allPlatformGraphSpinner || pieSpinner) &&
                pieChartData?.datasets?.length > 0 && (
                  <Pie
                    data={pieChartData}
                    options={options}
                    height={null}
                    width={null}
                    redraw
                  />
                )}
            </div>
            {!(allPlatformGraphSpinner || pieSpinner) &&
              allPlatformChartCordsData &&
              allPlatformSelectedOption &&
              allPlatformChartData?.datasets &&
              allPlatformChartData?.datasets[0]?.data?.length > 0 &&
              allPlatformChartData.datasets?.length ===
                allPlatformSelectedOption?.scales?.yAxes?.length && (
                <div className="line-chart-container">
                  <Line
                    data={renameFacebookAd(allPlatformChartCordsData)}
                    options={renameFacebookAd(allPlatformSelectedOption)}
                    redraw
                  />
                </div>
              )}
          </div>
          {!(allPlatformGraphSpinner || pieSpinner) &&
            allPlatformChartCordsData &&
            allPlatformSelectedOption &&
            allPlatformChartData?.datasets &&
            allPlatformChartData?.datasets[0]?.data?.length > 0 &&
            allPlatformChartData.datasets?.length ===
              allPlatformSelectedOption?.scales?.yAxes?.length && (
              <div className="ml-2 text-sm mt-2">
                {`*Spends accross all ad accounts have been converted to USD($)`}
              </div>
            )}
        </>
      ) : (
        !(allPlatformGraphSpinner || pieSpinner) && (
          <div className="nodata">No Data Present</div>
        )
      )}
    </>
  );
};

export default React.memo(AllPlatformGraph);

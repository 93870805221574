import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { APIGenerationType } from "../Facebook/shared/SharedTypes";
import CustomizedDialogs from "../../shared/Modal/CustomizedDialogs";
import CustomizedButton from "../../shared/Button/CustomizedButton";
import { InputFormControl, SwitchFormControl } from "../../shared";
import FormLevers from "../Facebook/shared/FormLevers/FormLevers";
import {
  expandData,
  getBUFormValues,
  mergeData,
} from "../Facebook/utils/form.utils";
import { TextAreaFormControl } from "../../shared/Input/TextArea/TextAreaFormControl";
import { useDispatch, useSelector } from "react-redux";
import { filterLevers } from "../Facebook/utils/filterLevers.utils";
import { cloneDeep } from "lodash";
import {
  StyledButtonFooter,
  StyledCVButton,
  StyledLoaderContainer,
  StyledForSpacing,
} from "../Facebook/Main/styled";
import Loader from "../../common/Loader";
import { AdAccountState } from "../../../store/types/adaccount";
import { Box } from "@mui/material";
import InputLabel from "components/shared/InputLabel/InputLabel";

import { ReactComponent as LaunchBlue } from "../../../assets/svg/launch_blue.svg";
import { StyledLaunchButtonText } from "./styled";

const BusinessUnitModal: React.FC<{
  editState: boolean;
  handleBusinessSave: any;
  singleBusinessUnit?: any;
  handleBusinessUpdate: any;
  setShowUniteForm: any;
  showUnitForm: any;
  onHide: () => void;
}> = ({
  onHide,
  editState,
  setShowUniteForm,
  showUnitForm,
  handleBusinessUpdate,
  handleBusinessSave,
  singleBusinessUnit,
}) => {
  const { LEVERS } = useSelector((state: any) => ({
    LEVERS: state.MainReducer.defaultLevers,
  }));
  const data: APIGenerationType[] = useMemo(
    () => filterLevers(LEVERS, ["user", "super_user"]),
    [LEVERS]
  );

  const [showFields, setShowFields] = useState(false);

  const headlineDescriptionLevers = useMemo(() => {
    return mergeData(cloneDeep(data));
  }, []);

  const { addBusinessUnitState, updateBusinessUnitState } = useSelector(
    (state: any) => state.businessUnitsReducer
  );

  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  const {
    control,
    getValues,
    reset,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    watch();
  }, []);

  useEffect(() => {
    setShowFields(false);
    if (editState) {
      if (singleBusinessUnit) {
        reset(getBUFormValues(singleBusinessUnit, true));
        watch();
        setShowFields(true);
        setShowUniteForm(true);
      }
    } else {
      setShowUniteForm(true);
      setShowFields(true);
    }
  }, [singleBusinessUnit]);

  const onGenerate = (data: any) => {
    const { headlineDescription, ...payload } = data;

    if (payload.status === true) {
      payload.status = "readyToUse";
    } else {
      payload.status = "inReview";
    }
    handleBusinessSave({
      primaryText: {},
      ...payload,
      ...expandData(headlineDescription, headlineDescriptionLevers, true),
      brandId: selectedBrand.id,
    });
    !addBusinessUnitState.loading && setShowUniteForm(false);
  };

  const onUpdateGenerate = (data: any) => {
    dispatch({
      type: "UPDATE_BUSINESS_UNIT_STATE",
      payload: { loading: true },
    });
    const { headlineDescription, ...payload } = data;
    if (payload.status === true) {
      payload.status = "readyToUse";
    } else {
      payload.status = "inReview";
    }

    handleBusinessUpdate(
      singleBusinessUnit.id,
      singleBusinessUnit.creativeAiId,
      {
        primaryText: {},
        ...payload,
        ...expandData(headlineDescription, headlineDescriptionLevers, true),
      }
    );

    onHide();
  };

  return (
    <CustomizedDialogs
      type="modal"
      title="Creative AI Setup for Business Unit"
      maxWidth="lg"
      subTitle={`${
        singleBusinessUnit?.businessUnit && editState
          ? singleBusinessUnit?.businessUnit
          : ""
      }`}
      show={showUnitForm}
      handleClose={() => onHide()}
      titleSize={20}
      subTitleSize={16}
      buttons={
        <StyledButtonFooter content="space-between">
          <div className="mt-2">
            <SwitchFormControl
              control={control}
              registeredName="status"
              label="Ready To Use"
              labelStyle={{ fontStyle: "italic" }}
            />
          </div>
          <StyledCVButton>
            {editState ? (
              <CustomizedButton
                disabled={
                  updateBusinessUnitState?.loading || !singleBusinessUnit
                }
                // buttonText="Update"
                buttonText={
                  updateBusinessUnitState.loading ? "Updating..." : "Update"
                }
                buttonClass="primary save-btn"
                onClick={handleSubmit(onUpdateGenerate)}
                variant="contained"
                style={
                  updateBusinessUnitState?.loading || !singleBusinessUnit
                    ? { backgroundColor: "#B5D2FE", color: "#ffffff" }
                    : {}
                }
              />
            ) : (
              <CustomizedButton
                disabled={addBusinessUnitState?.loading}
                buttonText={
                  addBusinessUnitState?.loading ? "Saving..." : "Save"
                }
                buttonClass="primary save-btn"
                onClick={handleSubmit(onGenerate)}
                variant="contained"
              />
            )}
          </StyledCVButton>
          {/* </div> */}
        </StyledButtonFooter>
      }
    >
      {editState && !singleBusinessUnit ? (
        <StyledLoaderContainer>
          <Loader />
        </StyledLoaderContainer>
      ) : (
        <div id="businessForm" style={{ width: "692px" }}>
          {showFields ? (
            <>
              <InputFormControl
                label="Business Unit"
                tooltipText="Business unit refers to an independent vertical of the brand. For example, if the brand is a bank, then, personal loan or credit card is a business unit."
                disableUnderline
                control={control}
                registeredName="businessUnit"
                required
                fullWidth
                placeholder="Enter Brand Name"
                sx={{ height: "40px", fontSize: "12px" }}
                isBorder
                backgroundColor="#fffffff"
              />

              <InputFormControl
                label="Display Brand Name"
                tooltipText="Display brand name refers to how you want the brand name to be displayed in the ad copy."
                disableUnderline
                control={control}
                registeredName="brandName"
                required
                fullWidth
                placeholder="Enter Brand Name"
                sx={{ height: "40px", fontSize: "12px" }}
                isBorder
                backgroundColor="#fffffff"
              />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pb="6px"
              >
                <InputLabel required label="Business Unit Detail" />
                <StyledLaunchButtonText
                  startIcon={<LaunchBlue />}
                  onClick={() => {
                    window.open(
                      "https://cai-sandbox.pixis.ai/?page=BU+Details",
                      "_blank"
                    );
                  }}
                >
                  Generate BU Details
                </StyledLaunchButtonText>
              </Box>
              <TextAreaFormControl
                control={control}
                registeredName="businessUnitDetail"
                placeholder="Enter Business Unit Detail"
                required
                sx={{ fontSize: "12px" }}
                isBorder
                backgroundColor="#fffffff"
                rows={7}
              />

              <>
                <InputFormControl
                  label="Interest Keyword"
                  tooltipText="Interest keyword refers to the crux of the ad generations. It indicates what the ad should deliver based on the product/service category and what the ad generations should be based on."
                  disableUnderline
                  control={control}
                  registeredName="interestKeyword"
                  required
                  fullWidth
                  placeholder="Enter Interest Keyword"
                  sx={{ height: "40px", fontSize: "12px" }}
                  isBorder
                  backgroundColor="#fffffff"
                />
                <FormLevers
                  data={data}
                  headlineDescriptionLevers={headlineDescriptionLevers}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  formType="BU"
                  errors={cloneDeep(errors)}
                  setError={setError}
                  clearErrors={clearErrors}
                ></FormLevers>
              </>
              <StyledForSpacing />
            </>
          ) : (
            <StyledLoaderContainer>
              <Loader />
            </StyledLoaderContainer>
          )}
        </div>
      )}
    </CustomizedDialogs>
  );
};

export default BusinessUnitModal;

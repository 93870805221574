import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { ReactComponent as InfoIcon } from "../../assets/svg/infoIcon.svg";

import { KEYWORDTUNINGLABELS } from "../../constants";
import "../../style/global.scss";
import { Handle, Position } from "react-flow-renderer";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TaiContext } from "../../../TaiContextProvider";
import { ReactComponent as Edit } from "../../assets/svg/editIcon.svg";

function KeywordTuningCollapsed({ data }: any) {
  const dispatch = useDispatch();
  const loaderState = useSelector((state: any) => state.taiCommon.loader);
  const gsKeywordTuningState = useSelector(
    (state: any) => state?.gsTargetingAI?.gsKeywordTuning?.data
  );
  const { setNodes, setEdges } = useContext(TaiContext) as any;
  const KEYWORD_TUNING: any = {
    "Cost Weightage": `${gsKeywordTuningState?.keywordCostValue}%`,
    "Impressions Quality": `${
      gsKeywordTuningState?.impressionSignificance
    }% - ${100 - gsKeywordTuningState?.impressionSignificance}%`,
    "Competition Index": `${
      gsKeywordTuningState?.competationIndexCheck === false
        ? "-"
        : `${
            gsKeywordTuningState?.competationIndex?.high === true ? "high" : ""
          },
    ${gsKeywordTuningState?.competationIndex?.medium === true ? "medium" : ""}, 
    ${gsKeywordTuningState?.competationIndex?.low === true ? "low" : ""}`
    }`,
    "Monthly Avg Search": `${
      gsKeywordTuningState?.monthlyAvgSearchCheck === false
        ? "-"
        : `${gsKeywordTuningState?.monthlyAvgSearch?.min} - ${
            gsKeywordTuningState?.monthlyAvgSearch?.max === 10000
              ? "10000+"
              : gsKeywordTuningState?.monthlyAvgSearch?.max
          }`
    }`,
  };
  const KEYWORD_TUNING_STYLE: any = {
    "Cost Weightage": 1,
    "Impressions Quality": 1,
    "Competition Index": `${
      gsKeywordTuningState?.competationIndexCheck === true ? "1" : "0.5"
    }`,
    "Monthly Avg Search": `${
      gsKeywordTuningState?.monthlyAvgSearchCheck === true ? "1" : "0.5"
    }`,
  };

  return (
    <Stack
      className="font-inter"
      sx={{
        width: "16rem",
        background: "#FFFFFF",
        border: "0.8px solid #EFEFEF",
        boxShadow: "0px 3.00565px 10.5198px rgba(0, 0, 0, 0.05)",
        borderRadius: "7px",
        p: 2,
      }}
    >
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box className="font-12 font-600">Keyword Tuning</Box>
        <Edit
          onClick={() =>
            data.onEditClick(dispatch, loaderState, setNodes, setEdges)
          }
        />
      </Stack>
      {KEYWORDTUNINGLABELS.map((label: string, index: number) => (
        <Stack
          key={label}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "#FFFFFF",
            border: "0.8px solid #DBDDDF",
            borderRadius: "6px",
            p: 1,
            my: 1,
            opacity: KEYWORD_TUNING_STYLE[label],
          }}
        >
          <Box className="font-lato font-10 font-400">
            {label} <InfoIcon />
          </Box>
          <Box className="font-10 font-800">{KEYWORD_TUNING[label]}</Box>
        </Stack>
      ))}
    </Stack>
  );
}

export default KeywordTuningCollapsed;

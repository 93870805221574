import { all } from "redux-saga/effects";
import { clusterWatcher } from "./cluster";
import { commonWatcher } from "./common";
import { actionImpactWatcher } from "./actionImpact";
import { formWatcher } from "./form";
import { campaignWatcher } from "./campaign";
import { authWatcher } from "./auth";
import { adAccountWatcher } from "./adaccount";
import { creativeAIWatcher } from "./creativeAi";
import { MontageWatcher } from "./montageAds";
import { createVariantWatcher } from "./CreativeAI/Facebook/CreateVariant/createVariant";
import { selectCohortWatcher } from "./CreativeAI/Facebook/Select_Cohort/selectCohort";
import { MainWatcher } from "./CreativeAI/Facebook/Main/Main";
import { AccountsManager } from "./accountsManager";
import { GsaiPlaygroundWatcher } from "./CreativeAI/GsaiPlayground";
import { manualActionWatcher } from "./manualAction";
import { bundleWatcher } from "./CreativeAI/Facebook/BundleGeneration/bundleGeneration";
import { TargetingAIWatcher } from "./targetingAI";
import { ActionSummaryWatcher } from "./ActionDebugger/ActionSummary";
import { ActionDebuggerWatcher } from "./ActionDebugger/ActionDebugger";
import { mediaStudioAdminWatcher } from "./CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import { mediaStudioWatcher } from "./CreativeAI/Facebook/CreateVariant/mediaStudio";
import { AgenciesWatcher } from "./SmbDashboard/agencies";
import { BrandsWatcher } from "./SmbDashboard/brand";
import { SelectedBrandWatcher } from "./SmbDashboard/selectedBrand";
import { PlatformsWatcher } from "./SmbDashboard/common";
import { ParentAiGroupWatcher } from "./ParentAiGroup/index";
import { AiGroupWatcher } from "./BudgetPlannerAiGroup/index";
import { GsTargetingAiWatcher } from "./gsTargetingAI";
import { reviewSagaWatcher } from "./CreativeAI/shared/Review/review";
import { facebookSageWatcher } from "./CreativeAI_Revamp/facebook.saga";
import { mediaStudioTestingSagaWatcher } from "./CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdminTesting";
import { caiFBWatcher } from "./CreativeAI_Revamp/facebookSaga";
import { logScreenSagaWatcher } from "./CreativeAI_Revamp/LogScreen/logscreen.saga";
import { AudienceManagerWatcher } from "./AudienceManager";
import { creativeAnimaterWatcher } from "./CreativeAI/Facebook/CreateVariant/creativeAnimater";
import { editorWatcherSaga } from "./CreativeAI_Revamp/HomeScreen/Editor/editor.saga";
import { adAccountsWatcher } from "./SmbDashboard/adAccount";
import { thirdPartyChannelsWatcher } from "./thirdPartyChannels";
import { CreativeRotationSagaWatcher } from "./ActionDebugger/CreativeRotation/creativeRotation.saga";
import { TargetingICPWatcher } from "./targetingICP";
import { AudienceManagerYoutubeWatcher } from "./AudienceManager/youtube";
import { UTGbundleWatcher } from "./CreativeAI_Revamp/UniTextGen/uniTextGen";

export function* rootSaga() {
  yield all([
    commonWatcher(),
    actionImpactWatcher(),
    formWatcher(),
    clusterWatcher(),
    campaignWatcher(),
    authWatcher(),
    adAccountWatcher(),
    creativeAIWatcher(),
    MontageWatcher(),
    createVariantWatcher(),
    selectCohortWatcher(),
    MainWatcher(),
    AccountsManager(),
    manualActionWatcher(),
    bundleWatcher(),
    GsaiPlaygroundWatcher(),
    mediaStudioWatcher(),
    TargetingAIWatcher(),
    mediaStudioAdminWatcher(),
    ActionSummaryWatcher(),
    ActionDebuggerWatcher(),
    AgenciesWatcher(),
    BrandsWatcher(),
    SelectedBrandWatcher(),
    PlatformsWatcher(),
    ParentAiGroupWatcher(),
    AiGroupWatcher(),
    GsTargetingAiWatcher(),
    reviewSagaWatcher(),
    facebookSageWatcher(),
    mediaStudioTestingSagaWatcher(),
    caiFBWatcher(),
    logScreenSagaWatcher(),
    AudienceManagerWatcher(),
    creativeAnimaterWatcher(),
    editorWatcherSaga(),
    adAccountsWatcher(),
    thirdPartyChannelsWatcher(),
    CreativeRotationSagaWatcher(),
    TargetingICPWatcher(),
    AudienceManagerYoutubeWatcher(),
    UTGbundleWatcher(),
  ]);
}

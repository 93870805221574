import { call, put, takeEvery } from "redux-saga/effects";
import HttpService from "../../../HttpService/HttpService";
import { BASE_URL } from "../../../utils/path";
import {
  GetActionSummary,
  GetKeyIndicators,
  GetPerformanceSummary,
  GET_ACTION_SUMMARY,
  GET_KEY_INDICATORS,
  GET_PERFORMANCE_SUMMARY,
} from "../../types/ActionDebugger/ActionSummary";

function* getActionSummary(action: GetActionSummary): any {
  let {
    payload: { elementId, start_date, end_date, typeOfData, typeOfLoader },
  } = action;

  try {
    const urlParams = `/v1/api/action-debugger/elements/${elementId}/ai-action-summary?startDate=${start_date}&endDate=${end_date}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      const data = response.data.data;
      yield put({ type: typeOfLoader, payload: false });
      // const data = typeOfData === "SET_ACTION_SUMMARY" ? actionSummaryData : previousActionSummaryData;
      yield put({ type: typeOfData, payload: data });
    } else {
      console.log("got error in getActionSummary");
      yield put({ type: typeOfLoader, payload: false });
      yield put({ type: typeOfData, payload: [] });
    }
  } catch (error: any) {
    console.log("got error in getActionSummary", error);
    yield put({ type: typeOfLoader, payload: false });
    yield put({ type: typeOfData, payload: [] });
  }
}

function* getPerformanceSummary(action: GetPerformanceSummary): any {
  let {
    payload: {
      elementId,
      start_date,
      end_date,
      adsetIds,
      campaignIds,
      adMetricIds,
      typeOfData,
      typeOfLoader,
      attributionWindow,
    },
  } = action;

  try {
    const urlParams = `/v1/api/action-debugger/elements/${elementId}/performance-summary?startDate=${start_date}&endDate=${end_date}${
      adsetIds && adsetIds.length > 0 ? "&adsetIds=" + adsetIds : ""
    }${
      campaignIds && campaignIds.length > 0 ? "&campaignIds=" + campaignIds : ""
    }${adMetricIds ? "&adMetricIds=" + adMetricIds : ""}${
      attributionWindow
        ? "&attributionWindow=" + encodeURIComponent(attributionWindow)
        : ""
    }`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      const data = response.data.data.metrics;

      // /const data = typeOfData === "SET_PERFORMANCE_SUMMARY" ? performanceSummary : previousPerformanceSummary;
      yield put({ type: typeOfData, payload: data });
      yield put({ type: typeOfLoader, payload: false });
    } else {
      console.log("got error in getPerformanceSummary");
      yield put({ type: typeOfLoader, payload: false });
      yield put({ type: typeOfData, payload: {} });
    }
  } catch (error: any) {
    console.log("got error in getPerformanceSummary", error);
    yield put({ type: typeOfLoader, payload: false });
    yield put({ type: typeOfData, payload: {} });
  }
}

function* getKeyIndicators(action: GetKeyIndicators): any {
  let {
    payload: {
      elementId,
      start_date,
      end_date,
      typeOfData,
      typeOfLoader,
      adsetIds,
      campaignIds,
      attributionWindow,
    },
  } = action;

  try {
    const urlParams = `/v1/api/action-debugger/elements/${elementId}/key-indicators?startDate=${start_date}&endDate=${end_date}${
      adsetIds && adsetIds.length > 0 ? "&adsetIds=" + adsetIds : ""
    }${
      campaignIds && campaignIds.length > 0 ? "&campaignIds=" + campaignIds : ""
    }${
      attributionWindow
        ? "&attributionWindow=" + encodeURIComponent(attributionWindow)
        : ""
    }`;
    const response = yield call(HttpService.get, BASE_URL, urlParams, "");
    if (response.data.success) {
      const data = response.data.data.metrics;
      yield put({ type: typeOfLoader, payload: false });
      // const data = typeOfData === "SET_KEY_INDICATORS" ? keyIndicators : previousKeyIndicators;
      yield put({ type: typeOfData, payload: data });
    } else {
      console.log("got error in getKeyIndicators");
      yield put({ type: typeOfLoader, payload: false });
      yield put({ type: typeOfData, payload: [] });
    }
  } catch (error: any) {
    console.log("got error in getKeyIndicators", error);
    yield put({ type: typeOfLoader, payload: false });
    yield put({
      type: typeOfData,
      payload: [],
    });
  }
}

export function* ActionSummaryWatcher() {
  yield takeEvery(GET_ACTION_SUMMARY, getActionSummary);
  yield takeEvery(GET_PERFORMANCE_SUMMARY, getPerformanceSummary);
  yield takeEvery(GET_KEY_INDICATORS, getKeyIndicators);
}

import {
  FetchValueFormAdEnum,
  FetchValueFromAdSetType,
  FetchValueFromAdType,
  GenderEnum,
  GeneratePreviewImageType,
  GeneratePreviewVideoType,
  GenerationType,
  GenerationTypeConvertEnum,
} from "../shared/SharedTypes";

export const getCreativeSPecFieldName = ({
  type,
  field,
}: {
  type: any;
  field: GenerationType;
}) => {
  if (type === "link_data") {
    return GeneratePreviewImageType[field];
  } else {
    return GeneratePreviewVideoType[field];
  }
};

export const getCohortName = (adSetName: string) => {
  const adSetNameArray = adSetName.split("_");
  if (adSetName && adSetNameArray.length > 2)
    return adSetNameArray[adSetNameArray.length - 2];
  else {
    return undefined;
  }
};

export const checkAdType = (createSpec: any) => {
  if (
    createSpec?.object_story_spec?.link_data ||
    createSpec?.asset_feed_spec?.images
  )
    return "link_data";
  return "video_data";
};

export const checkAdTypeForPublish = (createSpec: any) => {
  const { object_story_spec, asset_feed_spec } = createSpec;

  if (object_story_spec?.link_data || asset_feed_spec?.images)
    return "SINGLE_IMAGE_AD";
  else if (object_story_spec?.video_data || asset_feed_spec?.videos)
    return "VIDEO_AD";
  return "";
};

export const getComaSeparatedDate = (fields: any) => {
  const value = fields.map((element: any) => element.name);
  return value.join(", ");
};

export const findTheAdData = (adArrayInfo: any[]) => {
  for (const adInfoItem of adArrayInfo) {
    if (adInfoItem.text.length > 0) {
      return adInfoItem.text;
    }
  }
  return "";
};

//field mapping of fetch value form ad level

export const fetchAdTypeField = (
  createSpec: any,
  facebook_internal_key: FetchValueFromAdType
): any => {
  const fieldsOptionArray =
    FetchValueFormAdEnum[facebook_internal_key].split("|");
  let fetchAdTypeFieldValue = "";
  for (let fieldsString of fieldsOptionArray) {
    const fieldsArray = fieldsString.split(".");
    let currentValue = createSpec;
    for (let field of fieldsArray) {
      if (currentValue[field]) {
        currentValue = currentValue[field];
        currentValue = Array.isArray(currentValue)
          ? findTheAdData(currentValue)
          : currentValue;
      } else {
        currentValue = undefined;
        break;
      }
    }
    if (currentValue) {
      fetchAdTypeFieldValue = currentValue;
      break;
    }
  }
  return fetchAdTypeFieldValue;
};

//field mapping of fetch value form adset level

export const fetchValueFromAdSet = (
  targeting: any,
  facebook_internal_key: FetchValueFromAdSetType
) => {
  switch (facebook_internal_key) {
    case "age_min": {
      return targeting?.age_min ?? "";
    }
    case "age_max": {
      return targeting?.age_max ?? "";
    }
    case "interests": {
      return targeting?.flexible_spec[0]?.interests
        ? getComaSeparatedDate(targeting?.flexible_spec[0]?.interests)
        : "";
    }
    case "behaviors": {
      return targeting?.flexible_spec[0]?.behaviors
        ? getComaSeparatedDate(targeting?.flexible_spec[0]?.behaviors)
        : "";
    }
    case "genders": {
      return targeting?.genders?.length
        ? targeting.genders
            .map((gender: number) =>
              gender <= 2 ? GenderEnum[gender - 1] : "All"
            )
            .join(",")
        : "";
    }
  }
};

// map element level data with ad and adset level of data

export const mapAdAdSetToElement = ({
  levers,
  createSpec,
  targeting,
  creativeElementAI,
  getMediaStudioData,
}: any) => {
  let newCreativeElementAI = { ...creativeElementAI };

  let adAdSetFieldList: any = {};
  for (let leverField of levers) {
    const generationType: GenerationType = leverField.generation_type;
    if (!newCreativeElementAI.CreativeAiData[generationType]) {
      newCreativeElementAI.CreativeAiData[generationType] = {};
    }
    const leversObject = leverField.levers;
    for (let lever of leversObject) {
      const leverName = lever.lever_name;

      if (!newCreativeElementAI.CreativeAiData[generationType]?.[leverName]) {
        newCreativeElementAI.CreativeAiData[generationType][leverName] = {};
      }

      const fields = lever.fields;
      for (let field of fields) {
        const fetchValueFrom = field.fetch_value_from;
        const fieldName = field.field_name;
        const facebook_internal_key = field.facebook_internal_key;
        if (fetchValueFrom === "ad") {
          const adValue = fetchAdTypeField(createSpec, facebook_internal_key);
          adAdSetFieldList[`${generationType}.${leverName}.${fieldName}`] =
            adValue;

          newCreativeElementAI.CreativeAiData[
            GenerationTypeConvertEnum[generationType]
          ][leverName] = {
            ...newCreativeElementAI.CreativeAiData[
              GenerationTypeConvertEnum[generationType]
            ][leverName],
            [fieldName]: adValue,
          };
        }
        if (fetchValueFrom === "adset") {
          const adSetValue = fetchAdTypeField(targeting, facebook_internal_key);
          adAdSetFieldList[`${generationType}.${leverName}.${fieldName}`] =
            adSetValue;

          newCreativeElementAI.CreativeAiData[
            GenerationTypeConvertEnum[generationType]
          ][leverName] = {
            ...newCreativeElementAI.CreativeAiData[
              GenerationTypeConvertEnum[generationType]
            ][leverName],
            [fieldName]: adSetValue,
          };
        }

        if (fetchValueFrom === "ad_image") {
          const forMattedData = getMediaStudioData?.data.frames[0].layers.map(
            (item: any) => ({
              layer_name: item.name,
              layer_text: item.originalText,
              min_char_limit: Number(item.minCharacters),
              max_char_limit: Number(item.maxCharacters),
              is_sentence_related: item?.is_sentence_related
                ? item?.is_sentence_related === "Related"
                : false,
              is_partial_sentence: item?.is_partial_sentence
                ? item?.is_partial_sentence === "Partial Sentence"
                : false,
              related_to: item?.related_to ?? null,
            })
          );

          adAdSetFieldList[`${generationType}.${leverName}.${fieldName}`] =
            forMattedData;

          newCreativeElementAI.CreativeAiData[
            GenerationTypeConvertEnum[generationType]
          ][leverName] = {
            ...newCreativeElementAI.CreativeAiData[
              GenerationTypeConvertEnum[generationType]
            ][leverName],
            [fieldName]: forMattedData,
          };
        }
        if (fetchValueFrom === "ad_video") {
          let outputData: any = [];
          for (let frameIndex in getMediaStudioData?.data.frames) {
            const forMattedData = getMediaStudioData?.data.frames[
              frameIndex
            ].layers.map((item: any) => ({
              frame_name: `Frame_${Number(frameIndex) + 1}`,
              layer_name: item.name,
              layer_text: item.originalText,
              min_char_limit: Number(item.minCharacters),
              max_char_limit: Number(item.maxCharacters),
              is_sentence_related: item?.is_sentence_related
                ? item?.is_sentence_related === "Related"
                : false,
              is_partial_sentence: item?.is_partial_sentence
                ? item?.is_partial_sentence === "Partial Sentence"
                : false,
              related_to: item?.related_to ?? null,
            }));
            outputData = [...outputData, ...forMattedData];
          }

          adAdSetFieldList[`${generationType}.${leverName}.${fieldName}`] =
            outputData;

          newCreativeElementAI.CreativeAiData[
            GenerationTypeConvertEnum[generationType]
          ][leverName] = {
            ...newCreativeElementAI.CreativeAiData[
              GenerationTypeConvertEnum[generationType]
            ][leverName],
            [fieldName]: outputData,
          };
        }
      }
    }
  }
  return { newCreativeElementAI, adAdSetFieldList };
};

export const isFiltered = (item: any, filters: any) => {
  const arr = filters.map(
    (filter: any) => item?.[filter] == true || item?.[filter] > 0
  );
  return arr.some((item: boolean) => item == true);
};

export const variantFilters = (filters: any) => {
  const arr = filters.filter(
    (filter: any) =>
      filter.includes("approved") ||
      filter.includes("rejected") ||
      filter.includes("Review") ||
      filter.includes("billboard") ||
      filter.includes("draft")
  );
  return arr;
};

export const isVariantFiltered = (item: any, filters: any) => {
  const arr = variantFilters(filters).map((filter: any) => {
    if (filter.includes("approved")) {
      return item?.reviewStatus == "APPROVED";
    }
    if (filter.includes("rejected")) {
      return item?.reviewStatus == "REJECTED";
    }
    if (filter.includes("Review")) {
      return item?.reviewStatus == "UNDER_REVIEW";
    }
    if (filter.includes("billboard")) {
      return item?.variantType == "billboard";
    }
    if (filter.includes("draft")) {
      return item?.status == "draft";
    }
    return 0;
  });
  return arr.some((item: boolean) => item == true);
};

export const getDisplayUrlAndUrl = (createSpec: any) => {
  const { asset_feed_spec, object_story_spec } = createSpec;
  const { video_data, link_data } = object_story_spec;
  if (link_data) {
    return {
      displayUrl: link_data?.caption ?? "",
      url: createSpec?.object_story_spec?.link_data?.link ?? "",
    };
  } else if (video_data) {
    return {
      displayUrl: video_data?.call_to_action?.value?.link_caption ?? "",
      url: video_data?.call_to_action?.value?.link ?? "",
    };
  } else if (asset_feed_spec) {
    const { link_urls } = asset_feed_spec;
    return {
      displayUrl: link_urls?.[0]?.display_url,
      url: link_urls?.[0]?.website_url,
    };
  }
  return { displayUrl: "", url: "" };
};

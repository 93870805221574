import React, { useCallback, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./index.scss";
import ReactFlow, { addEdge } from "react-flow-renderer";
import Header from "./components/Header";
import "react-toggle/style.css";

import "react-toggle/style.css";
import pixisLogo from "../../assets/tai/pixisLogo.svg";
import taiIntro from "../../assets/tai/taiIntro.svg";
import taiStart from "../../assets/tai/taiStart.svg";
import TAISideBar from "./components/TAISideBar";
import { setNodeCollapse } from "../../store/actions/targetingAI";
import PublishModal from "./components/PublishModal";
import SuccessModal from "./common/SuccessModal";
import { changePosition as changePositionFacebook, cleanupEdges as cleanupEdgesFB } from "./utils/facebook";
import {
  changePosition as changePositionGoogle,
  GS_EDGES_LIST,
} from "./utils/googleSearch";

import { EDGE_TYPES, REACT_FLOW_STYLE } from "./utils";
import { TaiContext } from "./TaiContextProvider";
import { Userpilot } from "userpilot";
import useFocusToNode from './useFocusToNode';
import { useParams } from 'react-router-dom';

const edgeTypes = EDGE_TYPES;

let letsShowModel = (modalData: any, setShowPublishModal: Function) => {
  if (modalData === "success") {
    return <SuccessModal showSuccessModal={true} />;
  } else if (modalData === "failed") {
    return <SuccessModal showSuccessModal={false} />;
  }
};

const TargetAIPlaygroundBeta: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    popoverFlag,
    modalProps,
    setModalProps,
    showModal,
    setShowModal,
    initialProps,
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    setPopover,
    nodesList,
    edgesList,
    showPublishModal,
    publishModalData,
    setShowPublishModal,
    nodeTypes,
  } = useContext(TaiContext) as any;
  const { platform } = useParams<{ platform: string }>();

  const focusToNode = useFocusToNode();

  const currentPage = useSelector((state: any) => state.taiCommon.currentPage);
  const loaderState = useSelector((state: any) => state.taiCommon.loader);
  const {agencyAssets} = useSelector((state:any) => state.common);

  const [showModelWithData, setShowModelWithData] = useState(false);
  const [modalWithData, setModalWithData] = useState<any>([]);

  const onConnect = useCallback((params) => {
    console.log("LL: onConnect -> params", params);
    return setEdges((eds: any) =>
      addEdge(
        {
          ...params,
        },
        eds
      )
    );
  }, []);

  useEffect(() => {
    Userpilot.reload();
  }, []);

  return (
    <>
      <div
        className="tai-style"
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          zIndex: "1031",
          background: "#eaeaea",
          top: "0",
          left: "0",
        }}
      >
        <div
          id="message-container"
          className="bg-white d-flex flex-grow-1 message-container"
          style={{ margin: "1.25rem", borderRadius: "8px", height: "95vh" }}
        >
          {showPublishModal
            ? letsShowModel(publishModalData, setShowPublishModal)
            : null}
          {showModelWithData ? (
            <>
              <div
                className="fixed inset-0 z-50 w-40 overflow-y-auto outline-none d-flex focus:outline-none"
                style={{
                  position: "absolute",
                  top: "40%",
                  zIndex: "100",
                  left: "32%",
                }}
              >
                <div className="p-2 bg-white border rounded shadow outline-none w-100 focus:outline-none">
                  <div
                    className="rounded w-100 "
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "4px",
                    }}
                  >
                    <h5>{modalWithData?.label}</h5>
                    <button
                      className="text-black bg-transparent border-0 "
                      onClick={() => setShowModelWithData(false)}
                    >
                      <h5 className="block text-xl text-black opacity-7 ">x</h5>
                    </button>
                  </div>
                  <div
                    className="py-2 m-2 border rounded"
                    style={{ backgroundColor: "#E3EEFF" }}
                  >
                    <div className="flex">
                      {modalWithData?.data?.map((element: any, index: any) => (
                        <p
                          className="p-2 m-2 text-center bg-white border rounded"
                          key={index}
                        >
                          {element}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div
            className="m-2 border-2 rounded-lg d-flex flex-column w-100"
            style={{ height: "90vh" }}
          >
            <Header
              showModal={showModal}
              modalProps={modalProps}
              setShowModal={setShowModal}
              initialProps={initialProps}
              setModalProps={setModalProps}
            />
            <div className="d-flex align-items-center w-100 h-100">
              <div
                id="playground-intro"
                className="d-flex flex-column align-items-center"
                style={{ height: "75%", justifyContent: "space-between" }}
              >
                <img
                  src={agencyAssets?.logo}
                  height={38.4}
                  width={41.51}
                  alt="pixis-logo"
                  style={{ marginTop: "30px" }}
                />
                <div className="w-100">
                  <div
                    className="tai-font tai-heading w-100 d-flex justify-content-center"
                    style={{
                      color: "#333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                    }}
                  >
                    Targeting AI
                  </div>
                  <div
                    className="tai-font tai-heading w-100 d-flex justify-content-center"
                    style={{ color: "#0869FB" }}
                  >
                    <span
                      className="d-flex flex-column"
                      style={{
                        lineHeight: "1.3",
                        fontFamily: "Lato",
                        fontStyle: "normal",
                      }}
                    >
                      Playground
                    </span>
                  </div>
                </div>
                <span
                  style={{
                    width: "80%",
                    color: "#5A5A5A",
                    textAlign: "center",
                    fontSize: "0.813rem",
                    margin: "2rem 0",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                  }}
                >
                  Targeting AI playground lets you explore audience cohorts to
                  improve your Ad campaign performance, using historical
                  performance data and audience insights from competitive/brand
                  specific trends.
                </span>
                <img
                  className="ml-2"
                  src={taiIntro}
                  height={250}
                  width={300}
                  alt="intro"
                />
              </div>
              {currentPage === 1 && (
                <div
                  style={{ height: "86%", width: "1px", background: "#E6E6E6" }}
                ></div>
              )}
              <div id="tai-sidebar">
                <TAISideBar />
              </div>
              <div
                className=" d-flex flex-column align-items-center"
                style={{ marginRight: "3.25rem", marginLeft: "1.25rem" }}
                id="tai-start"
              >
                <span
                  style={{
                    fontSize: "1rem",
                    letterSpacing: "0.60rem",
                    fontFamily: "Inter",
                    marginBottom: "1rem",
                  }}
                >
                  START HERE
                </span>
                <img
                  src={taiStart}
                  style={{ height: "2.5rem", width: "12.625rem" }}
                  alt="start"
                />
              </div>
              <div
                className={
                  currentPage === 1 ? "d-flex firstNode" : "d-flex otherNodes"
                }
                style={{ height: "85vh", marginTop: "0.625rem" }}
              >
                <ReactFlow
                  nodeTypes={nodeTypes}
                  edgeTypes={edgeTypes}
                  style={REACT_FLOW_STYLE}
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  zoomOnScroll={false}
                  onMoveStart={() => {
                    if (popoverFlag) setPopover(false);
                  }}
                  defaultZoom={1}
                  defaultEdgeOptions={{ zIndex: 0 }}
                  snapToGrid={true}
                  attributionPosition="top-right"
                  preventScrolling={false}
                  onNodeClick={(event, node) => {
                    let trimId = node.id.split("Collapsed")[0];
                    if (
                      [
                        "loaderCollapsed",
                        "basicSetupLoaderCollapsed",
                        "keywordSetupLoaderCollapsed",
                        "loaderCalibrationCollapsed",
                        "loaderKeywordTuningCollapsed",
                        "loaderKeywordExplorerCollapsed",
                      ].includes(node.id)
                    ) {
                      setNodes((nodes: any[]) => {
                        let index = nodes.findIndex((e) => e?.id === node.id);
                        if (index === -1) return nodes;

                        const filtered = nodes.filter(n => n.id !== node.id);
                        nodesList[`${trimId}Expanded`].data.textBtn = "Next";
                        filtered.splice(index, 0, nodesList[`${trimId}Expanded`]);
                        console.log("LL: [...a]", [...filtered]);
                        return [...filtered];
                      });
                      const nodeIdToRemove = [node.id];
                      const nodeIdsToInsert = [`${trimId}Expanded`];

                      if (platform === "googlesearch") {
                        setEdges((edges: any) => {
                          if (node.id === "loaderCalibrationCollapsed") {
                            const addEdge = [
                              ...(loaderState["nodeCalibrationCollapsed"]
                                ? [
                                  GS_EDGES_LIST[
                                  "loaderCalibrationExpandedToNodeCalibrationCollapsed"
                                  ],
                                ]
                                : [
                                  GS_EDGES_LIST[
                                  "loaderCalibrationExpandedToNodeCalibrationExpandedExplore"
                                  ],
                                  GS_EDGES_LIST[
                                  "loaderCalibrationExpandedToNodeCalibrationExpandedExploit"
                                  ],
                                ]),
                            ];
                            const filteredEgs = edges.filter(
                              (e: any) =>
                                ![e?.source, e?.target].includes(
                                  "loaderCalibrationCollapsed"
                                )
                            );

                            return [
                              ...filteredEgs,
                              GS_EDGES_LIST["aiGroupToLoaderCalibrationExpanded"],
                              ...addEdge,
                            ];
                          }
                          if (node.id === "loaderKeywordTuningCollapsed") {
                            const addEdge = [
                              loaderState["nodeKeywordTuningCollapsed"]
                                ? GS_EDGES_LIST[
                                "loaderKeywordTuningExpandedToNodeKeywordTuningCollapsed"
                                ]
                                : GS_EDGES_LIST[
                                "loaderKeywordTuningExpandedToNodeKeywordTuningExpanded"
                                ],
                            ];
                            const filteredEgs = edges.filter(
                              (e: any) =>
                                ![e?.source, e?.target].includes(
                                  "loaderKeywordTuningCollapsed"
                                )
                            );

                            return [
                              ...filteredEgs,
                              loaderState["nodeCalibrationCollapsed"]
                                ? GS_EDGES_LIST[
                                "nodeCalibrationCollapsedToLoaderKeywordTuningExpanded"
                                ]
                                : GS_EDGES_LIST[
                                "nodeCalibrationExpandedToLoaderKeywordTuningExpanded"
                                ],
                              ...addEdge,
                            ];
                          }
                          if (node.id === "loaderKeywordExplorerCollapsed") {
                            const addEdge = [
                              GS_EDGES_LIST[
                              "loaderKeywordExplorerExpandedToNodeKeywordExplorerExpanded"
                              ],
                            ];
                            const filteredEgs = edges.filter(
                              (e: any) =>
                                ![e?.source, e?.target].includes(
                                  "loaderKeywordExplorerCollapsed"
                                )
                            );

                            return [
                              ...filteredEgs,
                              loaderState["nodeKeywordTuningCollapsed"]
                                ? GS_EDGES_LIST[
                                "nodeKeywordTuningCollapsedToLoaderKeywordExplorerExpanded"
                                ]
                                : GS_EDGES_LIST[
                                "nodeKeywordTuningExpandedToLoaderKeywordExplorerExpanded"
                                ],
                              ...addEdge,
                            ];
                          }
                        });
                      } else {
                        const cleanupEdges = {
                          // "googlesearch": cleanupEdgesGS, // above if condition to be removed and cleanupEdgesGs to be uncommented when google nodes and edges code is cleanup similer to facebook
                          "facebook": cleanupEdgesFB,
                        }[platform as string];
                        cleanupEdges?.(nodeIdsToInsert, nodeIdToRemove, setEdges);
                      }



                      dispatch(
                        setNodeCollapse({
                          type: node.id,
                          collapse: false,
                        })
                      );
                      let changePosition = window.location.pathname.includes(
                        "googlesearch"
                      )
                        ? changePositionGoogle
                        : changePositionFacebook;

                      changePosition(node.data.page, setNodes, {
                        ...loaderState,
                        [node.id]: false,
                      });

                      focusToNode(`${trimId}Expanded`);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TargetAIPlaygroundBeta;

import { call, takeLatest, takeEvery } from "redux-saga/effects";
import HttpService from "../../HttpService/HttpService";
import { BASE_URL } from "../../utils/path";
import { crossPlatform, crossPlatformV2 } from "../../MappedAPI/MappedAPI";
import {
  GENERATE_COHORTS_ICP,
  GET_TAI_COHORT_ICP,
  GET_TAI_KWS_ICP,
  GenerateCohortsICP,
  GetTAICohortICP,
  GetTAIKWSICP,
  REFRESH_COHORTS_ICP,
  STORE_FAVOURITE_COHORT_ICP,
  STORE_TAI_KWS_ICP,
  StoreFavouriteCohortICP,
  StoreTAIKWSICP,
  UPDATE_COHORTS_ICP,
} from "store/types/targetingICP";

function* storeTAIICPKWS(action: StoreTAIKWSICP): any {
  let {
    payload: { kwsData, user },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatformV2}/icp/generate-keywords`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      "",
      kwsData
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getTargetingAIKWSICP(action: GetTAIKWSICP): any {
  let { payload: request_ids, callback } = action;
  try {
    const urlParams = `${crossPlatform}/icp/keyword-status/${request_ids}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getTAIKWS ~ error", error);
    callback && callback(error.response, true);
  }
}

function* generateCohortsICP(action: GenerateCohortsICP): any {
  let {
    payload: { kwsData, aiGroupId, platformId },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatformV2}/icp/ai-groups/${aiGroupId}/generate-targetings?platformId=${platformId}`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      "",
      kwsData
    );
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* getTAICohortICP(action: GetTAICohortICP): any {
  let { payload: request_ids, callback } = action;
  try {
    const urlParams = `${crossPlatform}/icp/targeting-status/${request_ids}`;
    const response = yield call(HttpService.get, BASE_URL, urlParams);
    if (!response.data.error) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("function*getTAIKWS ~ error", error);
    callback && callback(error.response, true);
  }
}

function* storeFavouriteCohort(action: StoreFavouriteCohortICP): any {
  let {
    payload: { favouriteCohort, aiGroupId },
    callback,
  } = action;
  try {
    delete (favouriteCohort[0] as any).semantics_relevance_score;
    delete (favouriteCohort[0] as any).audience_relevance_score;
    delete (favouriteCohort[0] as any).similar_cohorts;
    delete (favouriteCohort[0] as any).edited_cohort_flag;
    delete (favouriteCohort[0] as any).interest_cluster;
    const urlParams = `${crossPlatform}/targeting-ai-recommendation/ai-groups/${aiGroupId}/save-cohorts`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      "",
      favouriteCohort
    );
    if (response.data.success) {
      callback && callback(response.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    callback && callback(error.response, true);
  }
}

function* updateCohortICP(action: any): any {
  const {
    payload: { payloadData },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/icp/update-cohorts`;
    const response = yield call(
      HttpService.put,
      BASE_URL,
      urlParams,
      "",
      payloadData
    );
    if (response.data.success && !response.data.data.error) {
      callback && callback(response.data.data.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("update recommendation ~ error", error);
    callback && callback(error.response, true);
  }
}

function* refreshCohortICP(action: any): any {
  const {
    payload: { payloadData },
    callback,
  } = action;
  try {
    const urlParams = `${crossPlatform}/icp/refresh-cohort`;
    const response = yield call(
      HttpService.post,
      BASE_URL,
      urlParams,
      "",
      payloadData
    );
    if (response.data.success && !response.data.data.error) {
      callback && callback(response.data.data.data, false);
    } else {
      callback && callback(response, true);
    }
  } catch (error: any) {
    console.log("update recommendation ~ error", error);
    callback && callback(error.response, true);
  }
}

export function* TargetingICPWatcher() {
  yield takeLatest(STORE_TAI_KWS_ICP, storeTAIICPKWS);
  yield takeLatest(GENERATE_COHORTS_ICP, generateCohortsICP);
  yield takeLatest(GET_TAI_KWS_ICP, getTargetingAIKWSICP);
  yield takeLatest(GET_TAI_COHORT_ICP, getTAICohortICP);
  yield takeLatest(STORE_FAVOURITE_COHORT_ICP, storeFavouriteCohort);
  yield takeLatest(UPDATE_COHORTS_ICP, updateCohortICP);
  yield takeLatest(REFRESH_COHORTS_ICP, refreshCohortICP);
}

export const FETCH_PERFORMANCE_SCORE = "FETCH_PERFORMANCE_SCORE";
export const CANCEL_PERFORMANCE_SCORE = "CANCEL_PERFORMANCE_SCORE";
export const CANCEL_VARIANT_PERFORMANCE_SCORE =
  "CANCEL_VARIANT_PERFORMANCE_SCORE";

export interface FetchPerformanceScore {
  type: typeof FETCH_PERFORMANCE_SCORE;
  payload: FetchPerformanceScorePayload;
}

export interface FetchPerformanceScorePayload {
  callback: ({
    error,
    performanceScore,
  }: {
    error: boolean;
    performanceScore?: PerformanceScoreType;
    cancel?: boolean;
  }) => void;
  body: {
    headline?: string;
    description?: string;
    primary_text?: string;
    brand_name: string;
  };
}

export interface PerformanceScoreType {
  performance_labels: string;
  readability_score: number;
  brand_centric: boolean;
  customer_centric: boolean;
  benefit_label: boolean;
  offer_label: boolean;
  insights?: boolean;
  performance_score: number;
  performance_probabilities: number;
  error?: any;
  insight_generation?: boolean;
}

export interface RecommendationDataType extends PerformanceScoreType {
  text: string;
  isLoading?: boolean;
  isFailed?: boolean;
  isEdited?: boolean;
}
/* 
kept for future use 
// export const STORE_VARIANT_PERFORMANCE_SCORE =
//   "STORE_VARIANT_PERFORMANCE_SCORE";
// export const STORE_RECOMMENDATION_PERFORMANCE_SCORE =
//   "STORE_RECOMMENDATION_PERFORMANCE_SCORE";

// export interface StoreVariantPerformanceScore {
//   type: typeof STORE_VARIANT_PERFORMANCE_SCORE;
//   payload: StoreVariantPerformanceScorePayload;
// }

// export interface StoreVariantPerformanceScorePayload {}

// export interface StoreRecommendationPerformanceScore {
//   type: typeof STORE_RECOMMENDATION_PERFORMANCE_SCORE;
//   payload: StoreRecommendationPerformanceScorePayload;
// }

// export interface StoreRecommendationPerformanceScorePayload {}

// // initial state

// export interface EditorInitialState {}

*/

import React, { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Lottie from "lottie-react";
import { setSession } from "../store/actions/auth";
import {
  getSearchParams,
  getSessionData,
  postMessage,
  setLocalStorageData,
} from "../utils/commonFunctions";
import { user_session_key } from "../utils/constants";
import { setActiveBrand } from "../store/actions/adaccount";
import OnBoardingJson from "../assets/json/OnBoarding.json";
import { AUTH0_CLIENT_ID, AUTH0_COOKIE_DOMAIN } from "utils/path";

const Authenticate: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loginWithRedirect, isAuthenticated, user, logout, isLoading }: any =
    useAuth0();

  const history = useHistory();
  const params = useParams<any>();
  const action = useMemo(() => params?.action || "login", [params?.action]);
  useEffect(() => {
    dispatch(setActiveBrand({ id: getSearchParams("brand_id") } as any));
  }, []);

  const sessionLogout = (sessionTrigger: boolean = false) => {
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/auth/login`,
        client_id: AUTH0_CLIENT_ID,
        federated: true,
        redirect_uri: `${window.location.origin}/auth/login`,
      },
    });
  };

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);
    const queryParams = getSearchParams("from");
    if (!isLoading) {
      if (isAuthenticated && action === "login") {
        const data: any = getSessionData();
        if (data && data?.body?.refresh_token) {
          let session: any = {
            refreshToken: data.body.refresh_token,
            accessToken: data.body.access_token,
            expiresAt: data.expiresAt * 1000,
          };
          setLocalStorageData(user_session_key, session);
          setTimeout(() => {
            postMessage({ from: "PYXIS_DASHBOARD", data: { session, user } });
          }, 1000);
          dispatch(setSession(session));
        }
        if (queryParams) {
          history.push("/login/successfull");
        } else {
          history.push("/" + window.location.search);
        }
      } else if (action === "login" && !isAuthenticated) {
        const redirectUri = queryParams
          ? `${window.location.origin}/login/successfull`
          : `${window.location.origin}/auth/login`;
        loginWithRedirect({
          authorizationParams: {
            redirect_uri: redirectUri,
          },
        });
      } else if (action === "logout" && isAuthenticated) {
        postMessage({ from: "PYXIS_DASHBOARD", data: {} });
        const isAutoApproveShown = localStorage.getItem("gotAutoApprove");
        const isNoRecommendationShown = localStorage.getItem(
          "gotNoRecommendation"
        );
        sessionLogout();
        if (isAutoApproveShown) {
          localStorage.setItem("gotAutoApprove", "true");
        }
        if (isNoRecommendationShown) {
          localStorage.setItem("gotNoRecommendation", "true");
        }
      } else {
        window.location.pathname = "/auth/logout";
      }
    }
  }, [isAuthenticated, isLoading, location]);

  return (
    <Lottie
      style={{ height: "100vh", width: "100vw" }}
      animationData={OnBoardingJson}
      loop={true}
    />
  );
};
export default Authenticate;

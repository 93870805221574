import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { CROSS_PLATFORM_URL } from "utils/path";
import HttpService from "HttpService/HttpService";
import { useQuery } from "Pages/TokenManagementSystem/hooks";
import { getTokenUID } from "store/actions/adaccount";


export default function useThirdPartyAccount() {
  const [state, token] = useQuery();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [modal, setModal] = useState<any>(null);
  const [tokenUID, setTokenUID] = useState<string>("");
  const [platformId, setPlatformId] = useState<any>({});
  const auth = useSelector((state: { auth: any }) => state.auth);

  useEffect(() => {
    if (state.brand_id && token) {
      setLoader(true);
      dispatch(
        getTokenUID(
          {
            brandId: state.brand_id,
            platform: state.platform,
            oauthCode: token,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              if (response?.data?.tokenId) {
                setTokenUID(response.data.tokenId);
              } else {
                setModal({ key: "error" });
              }
            } else {
              setModal({ key: "error" });
            }
            setLoader(false);
          }
        )
      );
    }
  }, [state, token]);

  useEffect(() => {
    HttpService.get(CROSS_PLATFORM_URL, "/v1/api/parent-platform")
      .then((res: any) => {
        if (res?.data?.data) {
          const _platformId: any = {};
          res.data.data?.forEach((item: any) => {
            _platformId[item.name.toLowerCase()] = item.id;
          });
          setPlatformId(_platformId);
        }
      })
      .catch((err: any) => {
        setModal({ key: "error" });
      });
  }, []);

 
  const ErrorFooter = () => {
    return (
      <div className="modal-footer d-flex justify-content-end">
        <button
          type="button"
          className="modal-button primary"
          onClick={() => {
            window.location.href = "/accounts-manager?brand_id=" + state.brand_id;
          }}
        >
          Close
        </button>
      </div>
    );
  };



  return {
    state,
    loader,
    modal,
    platformId,
    tokenUID,
    ErrorFooter
  };
}

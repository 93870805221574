import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {
  StyledTextFieldContainer,
  StyledTextFieldMainContainer,
  StyledTextFieldSubContainer,
  StyledUTGImgCOntainer,
} from "./styled";
import TextArea from "components/shared/Input/TextArea/TextArea";
import { ReactComponent as RightClick } from "../../../../../assets/svg/arrowRightCricled.svg";
import { ReactComponent as Sparkles } from "../../../../../assets/svg/sparkles_beta.svg";
import { useCaiFbMixPanel } from "../hooks/useCaiFbMixPanel";
function UTGTextField({
  active,
  setActive,
  setText,
  text,
  placeholder,
  onGenerate,
  disabled,
}: any) {
  const { mixPanelPreferredMethodSelected } = useCaiFbMixPanel();
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}
    >
      <StyledTextFieldMainContainer>
        <StyledTextFieldSubContainer>
          <StyledTextFieldContainer>
            {/* <StyledUTGImgCOntainer> */}
            <Sparkles height={"4em"} width={"4em"} />
            {/* </StyledUTGImgCOntainer> */}
            <TextArea
              minRows={1}
              maxRows={3}
              disableUnderline
              onChange={(e) => setText(e.target.value.substring(0, 500))}
              value={text}
              placeholder={active ? "" : placeholder}
              onFocus={() => {
                setActive(true);
              }}
              disabled={disabled}
              sx={{
                background: "#f6f7f8",
                fontFamily: "Inter",
                fontSize: "1em",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "1.5em",
                letterSpacing: " -0.03125rem",
              }}
            />
            <IconButton
              disabled={text.length == 0 || disabled}
              sx={{
                padding: "0",
                ":disabled": {
                  opacity: 0.5,
                },
              }}
              onClick={() => {
                onGenerate();
                mixPanelPreferredMethodSelected("Universal Text Generator");
              }}
            >
              <RightClick height={"1.5em"} width={"1.5em"} />
            </IconButton>
          </StyledTextFieldContainer>
        </StyledTextFieldSubContainer>
      </StyledTextFieldMainContainer>
      <Box sx={{ opacity: 0.6, fontSize: "0.75em" }}>{text.length}/500</Box>
    </Box>
  );
}

export default UTGTextField;

import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Box, Button, MenuItem, Select, Snackbar, Stack, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { ButtonGroup, Card, DropdownButton, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPaginatedRecommendations, getSuperAdminEmails, setStatus } from "store/actions/actionImpact";
import { calcualteTimeOffset } from "utils/date.util";
import { RolesWrapper } from "utils/rolesWrapper";
import { ReactComponent as CheckBlack } from "../../../assets/svg/CheckBlack.svg";
import { ReactComponent as CloseWhite } from "../../../assets/svg/CloseWhite.svg";
import { ReactComponent as Retry } from "../../../assets/svg/Retry.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { ReactComponent as Download } from "../../../assets/svg/download.svg";
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import {ReactComponent as SuccessUploadIcon} from "./assets/success-upload-icon.svg";
import sortOrder from "../../../assets/svg/sort_order.svg";
import { ReactComponent as GreenTick } from "../../../assets/svg/tick.svg";
import { AdAccountState } from "../../../store/types/adaccount";
import { initialState } from "../../../store/types/auth";
import ButtonUI from "../../../ui/ButtonUI/ButtonUI";
import CheckBox from "../../../ui/CheckBox/CheckBox";
import DropdownUI from "../../../ui/Dropdown/Dropdown";
import {
  exportToCsv,
  filterConfidenceData,
  generateChipText,
  getPlatformName,
  getRolesHierarchy,
  isAdminRole,
} from "../../../utils/commonFunctions";
import {
  adsetFirst,
  campaignFirst,
  confRadioInput,
  phaseList,
  searchHead,
  statusList,
} from "../../../utils/constants/actionImpact";
import { getModuleName, mixPanelAdEvent } from "../../../utils/mixpanel";
import Chip from "../../Chip/Chip";
import DatePickerDropdown from "../../DatePickerDropdown/DatePickerDropdown";
import ManualActionButton from "../../ManualActionButton/ManualActionButton";
import HandleClickOutside from "../HandleClickOutside";
import Loader from "../Loader";
import NoData from "../NoData";
import AssetGroupViewDetailsModal, { AssetGroupViewDetailsModalProps } from './AssetGroupViewDetailsModal';
import "./index.scss";
import "./styles.scss";
import UploadDownloadCardSelection, { CardType } from "./UploadDownloadCardSelection";
import TriggerActionsFileUpload from "Pages/SinglePlatform/TriggerActionsFileUpload";
import { downloadFileForRecommendationDV360, resetFormDataState, submitFileForRecommendationDV360, submitFileManualAction } from "store/actions/manualAction";
import { recommendationKeyFromBackend, recommendationKeyValueMapping } from "./recommendationKeyHeaderMapping";


const menuItemStyling = {
  fontFamily: "Inter",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "22px",
  letterSpacing: "0.01em",
  textAlign: "left",
}

interface Recommendation {
  recommendation_id: string; // column 1 - <no_header_title>
  concept_id: string; // column 2 - Asset ID
  concept_level: string; // column 3 - Asset Level (L2- Line Item, L1- Ad group)
  concept_name: string; // column 4 - Asset Name
  ad_account_id: string; // column 5 - Ad Account ID
  ad_account_name: string; // column 6 - Ad Account Name
  action_to_do: string; // column 7 - Recommended Action
  updated_value: string; // column 8 - Recommended Value
  previous_value: string; // column 9 - Current Value
  status: string; // coulmn 10 - Status
}

interface Response {
  recommendations: Recommendation[];
}

const PyxisTable: React.FC<{
  type: "actionImpact" | "campaign";
  tableHeader: any;
  tableTitle?: any;
  data: any;
  responsiveType?: string;
  showTableTopBar?: boolean;
  showFilterAndSearch?: boolean;
  showCustomiseColumn?: boolean;
  maxHeight?: any;
  loading?: boolean;
  tableClass?: string;
  tableContainerClass?: string;
  tableHeaderClass?: string;
  linkClicks?: any;
  setDateRange: (value: any) => void;
  requestSort: (value: any) => void;
  updateTableData: (activeHeader: any) => void;
  loadMore: (value: any) => void;
  loadMoreRecommendations: any;
  setButtonDisabled: any;
  buttonDisabled: any;
  setPagination: any;
  pagination: any;
  showLoadMore: any;
  sortRecommendations: any;
  setUpdatedTableHeader: (tableData: any) => void;
  handleAction: null | ((index: number) => void);
  sortConfig?: any;
  isSuperAdmin: boolean;
}> = ({
  type,
  tableHeader,
  tableTitle,
  data,
  responsiveType,
  maxHeight,
  tableClass,
  tableContainerClass,
  tableHeaderClass,
  showTableTopBar,
  showCustomiseColumn = true,
  showFilterAndSearch = true,
  linkClicks,
  loading,
  setDateRange,
  requestSort,
  updateTableData,
  loadMore,
  loadMoreRecommendations,
  setButtonDisabled,
  buttonDisabled,
  setPagination,
  pagination,
  showLoadMore,
  sortRecommendations,
  setUpdatedTableHeader,
  handleAction,
  sortConfig,
  isSuperAdmin,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState(data);
  const [checkedData, setCheckedData] = useState<any>({
    approve: {},
    retry: {},
  });
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState<any>(false);
  const [showAcionSnackbar, setShowAcionSnackbar] = useState(false);
  const [superAdminEmails, setSuperAdminEmails] = useState<any>([]);
  // const { isSuperAdmin } = useManageAgencyUserRolesState();
  const [actionImpactTableDetails, setActionImpactTableDetails] =
    useState(tableHeader);
  const adaccount: any = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const {
    selectedBrand,
    selectedAdAccount,
    selectedGroup,
    selectedPlatform,
    brandRoles,
  } = adaccount;
  const userDetailInfo = useSelector(
    (state: any) => state.userInfoReducer.userInfo
  );
  let timezone =
    selectedGroup?.timeZone ||
    "" + " " + (calcualteTimeOffset(selectedGroup?.timeZoneOffSet) || "");
  const [searchState, setSearchState] = useState(0);
  const [loadMoreToggle, setLoadMoreToggle] = React.useState<boolean>(false);

  const [confState, setConfState] = useState("gt");
  const [rangeState, setRangeState] = useState([
    {
      startDate: linkClicks ? linkClicks?.start : new Date(),
      endDate: linkClicks ? linkClicks?.end : new Date(),
      key: "selection",
    },
  ]);
  const [selectedCardType, setSelectedCardType] = useState<CardType | null>(
    null
  );
  const [downloadUpdateRecommendationModal, setDownloadUpdateRecommendationModal] = useState<boolean>(false)
  const [assetGroupViewDetailsModalData, setAssetGroupViewDetailsModalData] = useState<{
    details:AssetGroupViewDetailsModalProps["details"],
    itemToChange: string;
  }|null>(null);
  const isAssetGroupViewDetailsModalOpen = !!assetGroupViewDetailsModalData;
  const openAssetGroupViewDetailsModal = (data: typeof assetGroupViewDetailsModalData) => () => setAssetGroupViewDetailsModalData(data);
  const closeAssetGroupViewDetailsModal = () => setAssetGroupViewDetailsModalData(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showCustomise, setShowCustomise] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [isConfView, SetIsConfView] = useState(false);
  const [filteredSearchHead, setFilteredHead] = useState(searchHead);
  const [searchValue, setSearchValue] = useState(searchHead);
  const [finalFilter, setFinalFilter] = useState<
    { firstStep: string; secondStep: string }[]
  >([]);
  const [confStateValue, setConfStateValue] = useState<{
    from: number;
    to: number;
  }>({
    from: -1.0,
    to: -1.0,
  });
  const [searchFilter, setSearchFilter] = useState({
    firstStep: "",
    secondStep: "",
  });
  const { accountDetails = {} } = useSelector(
    (state: { auth: initialState }) => state.auth
  );
  const { submitManualActionState } = useSelector(
    (state: any) => state.manualActionReducer
  );
  const { user } = useAuth0();
  const [actions,setActions] = useState("");
  const [openActions,setOpenActions] = useState(false); 
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openedPopoverId, setOpenedPopoverId] = React.useState<number | null>(
    null
  );
  const handlePopoverOpen = (
    id: number,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(id);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };
  const open = Boolean(anchorEl);
  const handleTableCheckbox = (element: any) => {
    if (element.status.toLowerCase().includes("waiting")) {
      if (checkedData.approve[element.id]) {
        const newApproveData = { ...checkedData.approve };
        delete newApproveData[element.id];
        setCheckedData({ ...checkedData, approve: { ...newApproveData } });
      } else {
        setCheckedData({
          ...checkedData,
          approve: { ...checkedData.approve, [element.id]: element },
        });
      }
    } else {
      if (checkedData.retry[element.id]) {
        const newApproveData = { ...checkedData.retry };
        delete newApproveData[element.id];
        setCheckedData({ ...checkedData, retry: { ...newApproveData } });
      } else {
        setCheckedData({
          ...checkedData,
          retry: { ...checkedData.retry, [element.id]: element },
        });
      }
    }
    setShowAcionSnackbar(true);
  };

  const handleUpdateStatus = (
    items: any,
    type: string,
    itemIndex?: number,
    typeFromTableButton?: string
  ) => {
    const payload = items.map((item: any) => ({
      recommendation_id: item.id,
      concept_type: item.concept_type.toLowerCase(),
      update_status: item.status.toLowerCase().includes("failed")
        ? "PROCESSING"
        : type == "reject" || typeFromTableButton == "reject"
        ? "OUTLIER_REJECTED"
        : "OUTLIER_APPROVED",
      version: item.version,
    }));

    if (type === "reject") type = "approve";

    dispatch(
      setStatus({ data: payload, user }, () => {
        setShowSuccessSnackbar({
          length: payload.length,
          type: type ? type : typeFromTableButton,
        });
        if (payload.length > 1 || type) {
          const selectedItems = Object.keys(checkedData[type]);
          const newTableData = tableData.map((item: any) => {
            if (selectedItems.includes(`${item.id}`))
              return { ...item, status: "Processing" };
            return item;
          });
          setTableData(newTableData);
          setCheckedData({ ...checkedData, [type]: {} });
        } else {
          const newTableData = [...tableData];
          if (itemIndex || itemIndex == 0)
            newTableData[itemIndex].status = "Processing";
          setTableData(newTableData);
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      getSuperAdminEmails((emails: any) => {
        setSuperAdminEmails(emails);
      })
    );
  }, []);

  useEffect(() => {
    if (
      !accountDetails ||
      !accountDetails?.platform ||
      accountDetails?.platform !== "facebook"
    ) {
      const newSearchHead = filteredSearchHead.filter(
        (item) => item.key !== "phase"
      );
      setFilteredHead(newSearchHead);
      setSearchValue(newSearchHead);
    } else {
      setFilteredHead(searchHead);
      setSearchValue(searchHead);
    }
  }, [accountDetails]);

  const tableKey = React.useMemo(
    () =>
      tableHeader
        .filter((element: any) => element.isEnabled)
        .map((element: any) => element.sortKey),
    [tableHeader]
  );

  const analyticsKey = React.useMemo(
    () =>
      tableHeader
        .filter((element: any) => element.type === "analytics")
        .map((element: any) => {
          return {
            key: element.sortKey,
            value: element.name,
            isEnabled: element.isEnabled,
          };
        }),
    [tableHeader]
  );

  const setupKey = React.useMemo(
    () =>
      tableHeader
        .filter((element: any) => element.type === "setup")
        .map((element: any) => {
          return {
            key: element.sortKey,
            value: element.name,
            isEnabled: element.isEnabled,
          };
        }),
    [tableHeader]
  );

  useEffect(() => {
    if (!loading) {
      if (!finalFilter.length) {
        setTableData(data);
      } else {
        setFilteredData();
      }
    }
  }, [loading, data]);

  useEffect(() => {
    if (searchFilter.secondStep) {
      if (searchFilter.firstStep !== "confidence_score") {
        setDropdownOpen(!dropdownOpen);
      }
      addToFinalFilter();
    }
  }, [searchFilter.secondStep]);

  useEffect(() => {
    setFilteredData();
  }, [finalFilter]);

  useEffect(() => {
    setRangeState([
      {
        startDate: linkClicks ? new Date(linkClicks?.start) : new Date(),
        endDate: linkClicks ? new Date(linkClicks?.end) : new Date(),
        key: "selection",
      },
    ]);
  }, [linkClicks]);

  const handleSelect = (ranges: any) => {
    setRangeState([ranges.selection]);
  };

  const setFilteredData = () => {
    let updatedTableData = data;
    if (!!finalFilter.length) {
      finalFilter.map((elem: any) => {
        if (elem.firstStep !== "confidence_score") {
          updatedTableData = updatedTableData.filter(
            (element: any) =>
              element[elem.firstStep] &&
              element[elem.firstStep].toLowerCase().includes(elem.secondStep)
          );
        } else {
          updatedTableData = filterConfidenceData(elem, updatedTableData);
        }
      });
    }
    setTableData(updatedTableData);
  };

  const updateDate = () => {
    setDefault();
    setDateRange({
      start: rangeState[0].startDate,
      end: rangeState[0].endDate,
    });
    const date = {
      start: moment(rangeState[0].startDate).format("YYYY-MM-DD"),
      end: moment(rangeState[0].endDate).format("YYYY-MM-DD"),
    };
    mixPanelAdEvent("Date Range Selected", {
      module_name: getModuleName(history.location.pathname),
      feature_name: "Recommendations Table - Action Impact",
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ad_account_id:
        history.location.pathname === "/singlePlatform"
          ? selectedAdAccount?.id || null
          : "ALL",
      ad_account_name:
        history.location.pathname === "/singlePlatform"
          ? selectedAdAccount?.name || null
          : "ALL",
    });
  };

  useEffect(() => {
    if (sortRecommendations) {
      let updatedTableData = loadMoreRecommendations?.results;
      if (!!finalFilter.length && updatedTableData.length) {
        finalFilter.map((elem: any) => {
          if (elem.firstStep !== "confidence_score") {
            updatedTableData = updatedTableData.filter(
              (element: any) =>
                element[elem.firstStep] &&
                element[elem.firstStep].toLowerCase().includes(elem.secondStep)
            );
          } else {
            updatedTableData = filterConfidenceData(elem, updatedTableData);
          }
        });
      }
      setTableData([...tableData, ...(updatedTableData || [])]);
      setLoadMoreToggle(false);
    }
  }, [loadMoreRecommendations]);

  const loadMoreData = () => {
    mixPanelAdEvent("Recommendations Load More Clicked", {
      username: user?.name,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      ad_account_id: selectedAdAccount?.id,
      ad_account_name: selectedAdAccount?.name,
      ai_group_name: selectedGroup?.name,
      ai_group_id: selectedGroup?.id,
      platform_name: getPlatformName(selectedPlatform?.name),
      module_name: "Action Impact Analysis - Single platforms",
    });
    setPagination((prev: any) => prev + 1);
    loadMore(pagination + 1);

    if (pagination < loadMoreRecommendations?.pages) {
      setLoadMoreToggle(true);
    } else {
      setButtonDisabled(true);
    }
  };

  const setDefault = () => {
    setSearchFilter({
      firstStep: "",
      secondStep: "",
    });
    setConfStateValue({
      from: -1,
      to: -1,
    });
    setSearchState(0);
    SetIsConfView(false);
    setSearchValue(filteredSearchHead);
  };

  const addToFinalFilter = () => {
    setFinalFilter((finalFilter) => {
      return [
        ...finalFilter,
        {
          ...searchFilter,
          text: generateChipText(searchFilter),
        },
      ];
    });
  };

  const downloadCSV = () => {
    dispatch(
      getPaginatedRecommendations(
        {
          elementId: selectedAdAccount.elementId,
          dates: linkClicks,
          user,
          activeHeader: actionImpactTableDetails.reduce(
            (acc: any, elem: any) => {
              if (elem.isEnabled) acc.push(elem.sortKey);
              return acc;
            },
            []
          ),
          pageNumber: 1,
          sort_by: sortConfig?.key ?? "",
          sort_order: sortConfig?.direction ?? "",
          pageSize: -1,
        },
        (response: any, error: boolean) => {
          if (!error) {
            if (response.data.results?.length) {
              const result = [
                [...tableKey],
                ...response.data.results.map((element: any) =>
                  tableKey.map((keyword: any) => element[keyword])
                ),
              ];

              exportToCsv("recommendation_result.csv", result);
            } else {
              alert("No recommendations found for the given date range.");
            }
          } else {
            console.log(error);
          }
        }
      )
    );
  };

  const handleChange = (key: any) => {
    setActionImpactTableDetails((actionImpactTableDetails: any) => {
      const updatedTable = actionImpactTableDetails.map((element: any) => {
        if (element.sortKey === key) {
          return {
            ...element,
            isEnabled: !element.isEnabled,
          };
        } else return element;
      });

      return updatedTable;
    });
  };

  const updateRecommendationList = () => {
    const selectedKey = actionImpactTableDetails
      .filter((elem: any) => elem.isEnabled)
      .map((element: any) => element.sortKey);
    setDefault();
    setShowCustomise(false);
    updateTableData(selectedKey);
    setUpdatedTableHeader(actionImpactTableDetails);
  };

  const applySearchNextState = (key: string) => {
    if (searchState === 0) {
      if (key === "status") {
        setSearchValue(statusList);
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            firstStep: key,
          };
        });
      } else if (key === "phase") {
        setSearchValue(phaseList);
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            firstStep: key,
          };
        });
      } else if (key === "item_to_change") {
        const adsets = data.map((elem: any) => {
          const adset = elem.item_to_change.split(" ");
          return {
            type: adset[0],
            value: adset[adset.length - 1],
          };
        });
        
        const uniqueList = [
          ...new Map(adsets.map((item: any) => [item["value"], item])).values(),
        ];
        const campaignsList = uniqueList.filter((item: any) =>
          item.type.includes("Campaign") || item.type.includes("Line")
        );
        const adsetsList = uniqueList.filter(
          (item: any) => item.type === "Adset" || item.type === "Adgroup"
        );
        let campaignDropdown = campaignsList.map((elem: any) => {
          return {
            key: elem.value,
            value: elem.value,
            disabled: false,
          };
        });
        let adsetsDropdown = adsetsList.map((elem: any) => {
          return {
            key: elem.value,
            value: elem.value,
            disabled: false,
          };
        });
        campaignDropdown = campaignDropdown.length
          ? [campaignFirst, ...campaignDropdown]
          : [];
        adsetsDropdown = adsetsDropdown.length
          ? [adsetFirst, ...adsetsDropdown]
          : [];
     
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            firstStep: key,
          };
        });
        setSearchValue([...adsetsDropdown, ...campaignDropdown]);
      } else if (key === "confidence_score") {
        SetIsConfView(true);
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            firstStep: key,
          };
        });
      }
    } else if (searchState === 1) {
      if (searchFilter.firstStep === "confidence_score") {
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            secondStep: `${key} ${confStateValue.from} ${confStateValue.to}`,
          };
        });
      } else {
        setSearchFilter((searchFilter) => {
          return {
            ...searchFilter,
            secondStep: key,
          };
        });
      }
    }
  };

  const handleRadioChange = (value: string) => {
    setTimeout(() => {
      setConfState(value);
    }, 0);
    setConfStateValue({
      from: -1,
      to: -1,
    });
  };

  const deleteChip = (index: number) => {
    setFinalFilter((finalFilter) => {
      return finalFilter.filter((elem, i) => i !== index);
    });
  };

  const updateState = (key: string, searchState: number) => {
    switch (searchState) {
      case 0:
        applySearchNextState(key);
        setSearchState(1);
        break;
      case 1:
        applySearchNextState(key);
        break;
      default:
        break;
    }
  };

  const [platform, setPlatform] = useState<any>("");

  useEffect(() => {
    if (
      selectedPlatform?.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c001"
    )
      return setPlatform("facebook");
    if (
      selectedPlatform?.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c002"
    )
      return setPlatform("google");
    if (
      selectedPlatform?.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c003"
    )
      return setPlatform("linkedin");
    if (
      selectedPlatform?.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c004"
    )
      return setPlatform("bing");
    if (
      selectedPlatform.parentPlatformId ===
      "aa59a6e2-843b-4633-5621-6fa93ce6c006"
    )
      return setPlatform("tiktok");
  }, [selectedPlatform, adaccount]);


  const onCardSelect = (type: CardType | null) => {
    setDownloadUpdateRecommendationModal(false);
    setSelectedCardType(type)
  };

  // const isBrandViewer = useMemo(
  //   () => brandRoles?.length === 1 && brandRoles[0]?.role === "brand-viewer",
  //   [brandRoles]
  // );
  const sendMixPanelEventForRetryCTAFailedAction = (
    element: any,
    isRetryBulk?: boolean
  ) => {
    isRetryBulk
      ? mixPanelAdEvent("Failed Action Retried", {
          username: userDetailInfo?.name,
          agency_id: selectedBrand?.agencyId,
          agency_name: selectedBrand?.agency_name,
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
          email: userDetailInfo?.email,
          brand_id: selectedBrand?.id,
          ai_group_name: selectedGroup?.name,
          platform_selected: selectedPlatform?.name,
          ai_group_id: selectedGroup?.id,
          ad_account_id: selectedAdAccount?.adAccountId,
          asset: element,
        })
      : mixPanelAdEvent("Failed Action Retried", {
          username: userDetailInfo?.name,
          agency_id: selectedBrand?.agencyId,
          agency_name: selectedBrand?.agency_name,
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
          email: userDetailInfo?.email,
          brand_id: selectedBrand?.id,
          ai_group_name: selectedGroup?.name,
          platform_selected: selectedPlatform?.name,
          ai_group_id: selectedGroup?.id,
          ad_account_id: selectedAdAccount?.adAccountId,
          asset_id: element?.concept_id,
          asset_name: element?.concept_name || "",
          asset_type: element?.concept_type,
          error_message: element?.message,
        });
  };

  const sendMixPanelEventForUserApprovesOutlierAction = (
    element: any,
    isApproveBulk?: boolean
  ) => {
    isApproveBulk
      ? mixPanelAdEvent("Outlier Action Approved", {
          username: userDetailInfo?.name,
          agency_id: selectedBrand?.agencyId,
          agency_name: selectedBrand?.agency_name,
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
          email: userDetailInfo?.email,
          brand_id: selectedBrand?.id,
          ai_group_name: selectedGroup?.name,
          platform_selected: selectedPlatform?.name,
          ai_group_id: selectedGroup?.id,
          ad_account_id: selectedAdAccount?.adAccountId,
          asset: element,
        })
      : mixPanelAdEvent("Outlier Action Approved", {
          username: userDetailInfo?.name,
          agency_id: selectedBrand?.agencyId,
          agency_name: selectedBrand?.agency_name,
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
          email: userDetailInfo?.email,
          brand_id: selectedBrand?.id,
          ai_group_name: selectedGroup?.name,
          platform_selected: selectedPlatform?.name,
          ai_group_id: selectedGroup?.id,
          ad_account_id: selectedAdAccount?.adAccountId,
          asset_id: element?.concept_id,
          asset_name: element?.concept_name || "",
          asset_type: element?.concept_type,
        });
  };

  const sendMixPanelEventForUsersRejectOutlierAction = (
    element: any,
    isRejectedBulk?: boolean
  ) => {
    isRejectedBulk
      ? mixPanelAdEvent("Outlier Action Rejected", {
          username: userDetailInfo?.name,
          agency_id: selectedBrand?.agencyId,
          agency_name: selectedBrand?.agency_name,
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
          email: userDetailInfo?.email,
          brand_id: selectedBrand?.id,
          ai_group_name: selectedGroup?.name,
          platform_selected: selectedPlatform?.name,
          ai_group_id: selectedGroup?.id,
          ad_account_id: selectedAdAccount?.adAccountId,
          asset: element,
        })
      : mixPanelAdEvent("Outlier Action Rejected", {
          username: userDetailInfo?.name,
          agency_id: selectedBrand?.agencyId,
          agency_name: selectedBrand?.agency_name,
          brand_name: selectedBrand?.name,
          brand_type: selectedBrand?.brandType,
          email: userDetailInfo?.email,
          brand_id: selectedBrand?.id,
          ai_group_name: selectedGroup?.name,
          platform_selected: selectedPlatform?.name,
          ai_group_id: selectedGroup?.id,
          ad_account_id: selectedAdAccount?.adAccountId,
          asset_id: element?.concept_id,
          asset_name: element?.concept_name || "",
          asset_type: element?.concept_type,
        });
  };
  // const [open, setOpen] = React.useState(false);
  const getPlatformID = () => {
    if (platform === "facebook") return 1;
    if (platform === "google") return 2;
    if (platform === "linkedin") return 3;
    if (platform === "bing") return 4;
    if (platform === "tiktok") return 5;
    if (platform === "dv360") return 6;
    if (platform === "apple") return 7;
    if (platform === "pinterest") return 23;
  };
  const [fileUploaded, setFileUploaded] = useState<any>(null);
  const [uploadedSucessfulSnackbar,setUploadedSucessSnackbar] = useState<boolean>(false);

  const fileUploadSubmit = (file: any) => {
    dispatch(submitFileForRecommendationDV360({
      file: fileUploaded[0],
    },(response:any,error:string|null)=>{
      if(response.success){
        handleClose();
        setUploadedSucessSnackbar(true);
      }
      setActions("");
    }
    ))
  };
  const handleClose = () => {
    // setOpen(false);
    setFileUploaded(null);
    setSelectedCardType(null);
    dispatch(resetFormDataState());
  };
  const renderErrorScreenAfterUploading = (error:any) => (
    <Box sx={{
      maxHeight:234,
      overflowY:'scroll',
      background: '#F6F7F8',
      border: '1px solid #E91B16',
      marginTop:2,
      padding:'17px 46px'
    }}>
       {error}
    </Box>
  )
  const renderCsvUploadModalFromActionsDropdown = () => (
    <TriggerActionsFileUpload
      title="Upload .CSV"
      description="Upload a CSV file containing a list of all your Assets within this Brand and Platform and update the recommendation and execution information"
      open={selectedCardType === CardType.CSV_UPLOAD}
      onClose={handleClose}
      fileUploaded={fileUploaded}
      onFileUpload={(value) => setFileUploaded(value)}
      onSubmit={fileUploadSubmit}
      onBack={!!selectedCardType ? ()=>{
        setDownloadUpdateRecommendationModal(true);
        handleClose();
        setActions("");
      } : undefined}
      isManualActionButton={false}
      renderErrorScreen={renderErrorScreenAfterUploading}
    />
  );
  const wrapperRef = useRef(null);
  HandleClickOutside(wrapperRef, [setShowCustomise]);
  return (
    <>
      <div className={`pyxis-table-header-container ${tableHeaderClass}`}>
        {tableTitle && tableTitle()}
        {!showTableTopBar &&
          (history.location.pathname === "/singlePlatform" ||
            history.location.pathname === "/actionImpactAnalysis" ||
            history.location.pathname === "/") && (
            <div className="p-0 ml-4 search-text fs-6 d-flex">
              {showFilterAndSearch && (
                <div className="d-flex position-relative w-100">
                  <Dropdown
                    onSelect={(target: any) => {}}
                    show={dropdownOpen}
                    className="mr-3 hoverable position-set"
                    id="search-and-filter"
                    style={{ marginLeft: "auto" }}
                  >
                    <Dropdown.Toggle
                      className="search-dropdown"
                      disabled={loading}
                    >
                      <SearchIcon className="search-icon" />
                      {finalFilter?.map((element: any, index: number) => {
                        return (
                          <Chip
                            index={index}
                            chip={element.text}
                            deleteChip={deleteChip}
                            key={element?.id}
                          />
                        );
                      })}
                      <input
                        type="text"
                        placeholder="Search and filter"
                        className="text-md form-control form-control-md"
                        id="dropdown-toggle"
                        onClick={() => {
                          if (!dropdownOpen) {
                            setDefault();
                          }
                          setDropdownOpen(!dropdownOpen);
                        }}
                        onChange={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </Dropdown.Toggle>
                    {isConfView ? (
                      <Dropdown.Menu className="conf-dropdown">
                        <Dropdown.Item
                          className="py-2 text-truncate conf-view border-bottom"
                          eventKey={"conf"}
                          key={"conf"}
                          style={{
                            backgroundColor: "#E9E9E9",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="confidence-score-container">
                            <Card>
                              <Card.Body>
                                <Card.Title>Confidence score</Card.Title>
                                <div className="conf-body">
                                  <div className="radio">
                                    {confRadioInput.map((value: any) => {
                                      return (
                                        <label
                                          className="display-block text-md"
                                          key={value?.id}
                                        >
                                          <input
                                            type="radio"
                                            checked={confState === value.value}
                                            id={value.value}
                                            name={value.value}
                                            value={value.value}
                                            className="radio-input"
                                            onChange={(e) => {
                                              e.stopPropagation();
                                              handleRadioChange(e.target.value);
                                            }}
                                          />
                                          <span>{value.name}</span>
                                        </label>
                                      );
                                    })}
                                  </div>
                                  {confState === "gt" || confState === "lt" ? (
                                    <input
                                      type="number"
                                      className="text-md form-control-md"
                                      value={
                                        confStateValue.from != -1
                                          ? confStateValue.from
                                          : ""
                                      }
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        setConfStateValue((confStateValue) => {
                                          return {
                                            ...confStateValue,
                                            from: parseFloat(e.target.value),
                                          };
                                        });
                                      }}
                                    />
                                  ) : (
                                    <div className="input-container">
                                      <input
                                        type="number"
                                        value={
                                          confStateValue.from != -1
                                            ? confStateValue.from
                                            : ""
                                        }
                                        className="text-mdform-control-md"
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          setConfStateValue(
                                            (confStateValue) => {
                                              return {
                                                ...confStateValue,
                                                from: parseFloat(
                                                  e.target.value
                                                ),
                                              };
                                            }
                                          );
                                        }}
                                      />
                                      <span>and</span>
                                      <input
                                        type="number"
                                        value={
                                          confStateValue.to != -1
                                            ? confStateValue.to
                                            : ""
                                        }
                                        className="text-md form-control-md"
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          setConfStateValue(
                                            (confStateValue) => {
                                              return {
                                                ...confStateValue,
                                                to: parseFloat(e.target.value),
                                              };
                                            }
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="conf-footer">
                                  <button
                                    type="button"
                                    className="p-1 btn primary btn-cancel"
                                    onSelect={function () {}}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDefault();
                                      setDropdownOpen(!dropdownOpen);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="p-1 btn primary"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateState(confState, searchState);
                                      setDropdownOpen(!dropdownOpen);
                                    }}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    ) : (
                      <Dropdown.Menu>
                        {searchValue.map((element: any) => (
                          <Dropdown.Item
                            key={element?.id}
                            className="py-2 text-truncate border-bottom dropdown-recommendation"
                            eventKey={element.key}
                            disabled={element.disabled}
                            style={{
                              backgroundColor: "#E9E9E9",
                              color: "#000",
                              fontWeight: "bold",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              updateState(element.key, searchState);
                            }}
                          >
                            {element.value}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </div>
              )}
              <div className="right-panel" ref={wrapperRef}>
                <DatePickerDropdown
                  showDatePicker={showDatePicker}
                  loading={loading}
                  updateDate={updateDate}
                  setShowDatePicker={setShowDatePicker}
                  setShowCustomise={setShowCustomise}
                  handleSelect={handleSelect}
                  linkClicks={linkClicks}
                  rangeState={rangeState}
                />

                {showCustomiseColumn && (
                  <DropdownUI
                    className="ml-2 dropdown-up-ui hoverable customise-column"
                    isDropDownVisible={showCustomise}
                    setIsDropDownVisible={() => {}}
                    toggelButton={
                      <ButtonUI
                        className="customize-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowCustomise((v) => !v);
                          setShowDatePicker(false);
                        }}
                        disabled={loading}
                      >
                        <div className="pr-3 text-left text-dark text-truncate">
                          Customise Column
                        </div>

                        <span className="dropdown_arrow">
                          <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </ButtonUI>
                    }
                  >
                    <div
                      style={{
                        height: "200px",
                        overflow: "auto",
                      }}
                    >
                      <div
                        className="py-2 pt-3 pr-3 dropdown-item text-truncate border-bottom "
                        key={"date"}
                        style={{
                          backgroundColor: "#E9E9E9",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        Setup
                      </div>
                      {setupKey?.map((key: any) => {
                        return (
                          <div
                            className="py-2 dropdown-item text-truncate border-bottom"
                            key={key.key}
                            style={{ backgroundColor: "#E9E9E9" }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <CheckBox
                              value={key.isEnabled}
                              onChange={() => handleChange(key.key)}
                            />{" "}
                            {key.value}
                          </div>
                        );
                      })}
                      <div
                        className="py-2 dropdown-item text-truncate border-bottom border-top"
                        key={"date"}
                        style={{
                          backgroundColor: "#E9E9E9",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        Analytics
                      </div>
                      {analyticsKey &&
                        analyticsKey.map((key: any) => {
                          return (
                            <div
                              className=" dropdown-item text-truncate"
                              key={key.key}
                              style={{ backgroundColor: "#E9E9E9" }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <CheckBox
                                value={key.isEnabled}
                                onChange={() => handleChange(key.key)}
                              />{" "}
                              {key.value}
                            </div>
                          );
                        })}
                    </div>
                    <div
                      className="pt-2 pb-2"
                      key={"date"}
                      style={{
                        backgroundColor: "#E9E9E9",
                      }}
                    >
                      <button
                        type="button"
                        className="mx-3 my-1 btn primary"
                        onClick={updateRecommendationList}
                      >
                        Apply
                      </button>
                    </div>
                  </DropdownUI>
                )}
                <div>
                  {history.location.pathname.search(/singlePlatform/) > -1 && (
                    // !isBrandViewer && <ManualActionButton />}
                    <ManualActionButton />
                  )}
                </div>
                {/* The actions dropdown should be shown when platform is DV360 YouTube & Partner Video and its single platform pathname
                     history.location.pathname.search(/singlePlatform/) > -1
                     && selectedPlatform?.type === "DV360 Youtube & Partners Video"
                */}
              {(history.location.pathname.search(/singlePlatform/) > -1  && selectedPlatform?.type === "DV360 Youtube & Partners Video") ?
                 <>
                 <Select
                 labelId="demo-controlled-open-select-label"
                 id="demo-controlled-open-select"
                 className="ml-2"
                 open={openActions}
                 onClose={()=>setOpenActions(false)}
                 onOpen={()=>setOpenActions(true)}
                 value={actions || "Actions"}
                 sx={{
                   width: 109,
                   background: "#F6F7F8",
                   boxShadow: "none",
                   borderRadius: "8px",
                   ".MuiSelect-select": {
                     paddingTop: 0,
                     paddingBottom: 0,
                     borderRadius: 8,
                   },
                   ".MuiOutlinedInput-notchedOutline": { border: 0 },
                   "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                   {
                     border: 0,
                   },
                   "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                   {
                     border: 0,
                   },
                 }}
                 onChange={(event)=>setActions(event.target.value)}
                 renderValue={() => (
                 <Typography sx={{
                      fontFamily: "Inter",
                      fontSize: 14,
                      fontWeight: 600,
                      lineHeight: "22px",
                      letterSpacing: "0.0125em",
                      textAlign: "center",
                  }}>Actions</Typography>)}
               >
                 <MenuItem value="export" sx={menuItemStyling} onClick={()=>downloadCSV()}>Export recommendations</MenuItem>
                 <MenuItem value="download" sx={menuItemStyling} onClick={()=>setDownloadUpdateRecommendationModal(true)}>Download and Update</MenuItem>
               </Select>
                  {selectedCardType === CardType.CSV_UPLOAD ? renderCsvUploadModalFromActionsDropdown() : <UploadDownloadCardSelection
                    open={downloadUpdateRecommendationModal}
                    onClose={() => {
                      setActions("");
                      setDownloadUpdateRecommendationModal(false)
                    }}
                    onSelect={onCardSelect}
                    setActions={setActions}
                    rangeState={rangeState}
                  />}
                  <Snackbar open={uploadedSucessfulSnackbar} autoHideDuration={3000} onClose={()=>setUploadedSucessSnackbar(false)}  
                  anchorOrigin={{
                     vertical: "bottom",
                     horizontal: "center"
                   }}
                   >
                      <Alert
                        severity="success"
                        variant="filled"
                        iconMapping={{
                          success: <SuccessUploadIcon/>,
                        }}
                        sx={{ 
                          width: '413px',
                          padding:'0px 16px',
                          borderRadius:'8px',
                          background:'#35915E',
                          fontFamily: 'Inter',
                          fontSize: 14,
                          fontWeight: 600,
                          lineHeight: '22px',
                          letterSpacing: '0.0125em',
                          display:'flex',
                          justifyContent:'center',
                          alignItems:'center',
                          gap:'8px',
                          "&.MuiAlert-message":{
                            padding:0
                          }
                        }}
                        
                       >
                         Recommendations have been updated successfully
                      </Alert>
                    </Snackbar>
               </>
              : <Dropdown
                  onSelect={(target: any) => { }}
                  show={showDownload}
                  id="download-dropdown"
                  className="mr-3 hoverable dropleft position-relative dropdown-download-parent"
                  align={{ lg: "end" }}
                >
                  <Dropdown.Toggle
                    className="dropdown-download"
                    disabled={loading}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDownload(!showDownload);
                    }}
                  >
                    <Download />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="py-2 text-truncate border-bottom"
                      eventKey={"date"}
                      key={"date"}
                      style={{
                        backgroundColor: "#E9E9E9",
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "12px !important",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        downloadCSV();
                        mixPanelAdEvent("Export Recommendations Clicked", {
                          module_name: getModuleName(history.location.pathname),
                          brand_id: selectedBrand?.id,
                          brand_name: selectedBrand?.name,
                          brand_type: selectedBrand?.brandType,
                          agency_id: selectedBrand?.agencyId,
                          agency_name: selectedBrand?.agency_name,
                          ai_group_id: selectedGroup?.id,
                          ai_group_name: selectedGroup?.name,
                          ad_account_id:
                            history.location.pathname === "/singlePlatform"
                              ? selectedAdAccount?.id || null
                              : "ALL",
                          ad_account_name:
                            history.location.pathname === "/singlePlatform"
                              ? selectedAdAccount?.name || null
                              : "ALL",
                          platform_name:
                            history.location.pathname === "/singlePlatform"
                              ? selectedPlatform?.name || null
                              : "ALL",
                        });
                      }}
                    >
                      Export Recommendations
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>}
                
              </div>
            </div>
          )}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div
          style={{
            overflowX: "scroll",
          }}
          className={`pyxis-table-container ${tableContainerClass}`}
        >
          <Table
            responsive={responsiveType ? "md" : ""}
            className={tableClass}
            style={{ maxHeight: maxHeight }}
          >
            <thead style={{zIndex: 1}}>
              <tr>
                {type === "actionImpact"
                  ? tableHeader?.map((element: any) => {
                      if (element.isEnabled) {
                        return (
                          <th className="t_header10" key={element?.id}>
                            {element.name}
                            {element.isSortable && element.sortKey && (
                              <img
                                src={sortOrder}
                                className="sort_style"
                                alt="sort_order"
                                onClick={() => requestSort(element.sortKey)}
                              />
                            )}
                            {element.tableData && (
                              <p className="tableData">{element.tableData}</p>
                            )}
                          </th>
                        );
                      }
                    })
                  : tableHeader?.map((element: any) => (
                      <th className="t_header10" key={element?.id}>
                        {element.name}
                        {element.isSortable && element.sortKey && (
                          <img
                            src={sortOrder}
                            className="sort_style"
                            alt="sort_order"
                            onClick={() => requestSort(element.sortKey)}
                          />
                        )}
                        {element.tableData && (
                          <p className="tableData">{element.tableData}</p>
                        )}
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {type === "actionImpact" &&
                (history.location.pathname === "/singlePlatform" ||
                  history.location.pathname === "/actionImpactAnalysis" ||
                  history.location.pathname === "/") &&
                tableData &&
                tableData.map((element: any, index: number) => (
                  <tr key={element?.id}>
                    {tableKey?.map((keyword: any) => {
                      return keyword === "status" ? (
                        <td key={element?.id}>
                          {getStatus(
                            (element.status.toLowerCase().includes("waiting") ||
                              element.status
                                .toLowerCase()
                                .includes("outlier rejected")) &&
                              !isSuperAdmin
                              ? "Recommended"
                              : element.status,
                            isSuperAdmin,
                            superAdminEmails.includes(user?.email),
                            handleUpdateStatus,
                            element,
                            index,
                            sendMixPanelEventForRetryCTAFailedAction,
                            sendMixPanelEventForUserApprovesOutlierAction,
                            sendMixPanelEventForUsersRejectOutlierAction,
                            brandRoles,
                            selectedPlatform
                          )}
                        </td>
                      ) : keyword === "recommendation_time" ? (
                        <td key={element?.id}>
                          {((element.status.toLowerCase().includes("failed") &&
                            isSuperAdmin) ||
                            (element.status.toLowerCase().includes("waiting") &&
                              superAdminEmails.includes(user?.email))) && (
                            <CheckBox
                              value={
                                element.status.toLowerCase().includes("waiting")
                                  ? checkedData.approve[element?.id]
                                  : checkedData.retry[element?.id]
                              }
                              onChange={() => {
                                handleTableCheckbox(element);
                              }}
                            />
                          )}
                          {element.recommendation_time
                            ? moment
                                .tz(element.recommendation_time, "UTC")
                                .clone()
                                .tz(timezone)
                                .format("MMM DD,YYYY HH:mm:ss") +
                              " " +
                              timezone
                            : "-"}
                        </td>
                      ) : keyword === "recommendation_date" ? (
                        <td key={element?.id}>
                          {element.recommendation_date
                            ? moment
                                .tz(element.recommendation_date, "UTC")
                                .clone()
                                .tz(timezone)
                                .format("MMM DD,YYYY HH:mm:ss") +
                              " " +
                              timezone
                            : "-"}
                        </td>
                      ) : keyword === "action_execution_time" ? (
                        <td key={element?.id}>
                          {element.action_execution_time
                            ? moment
                                .tz(element.action_execution_time, "UTC")
                                .clone()
                                .tz(timezone)
                                .format("MMM DD,YYYY HH:mm:ss") +
                              " " +
                              timezone
                            : "-"}
                        </td>
                      ) : keyword === "item_changed" ? (
                        <td>
                          {" "}
                          {
                            <>
                              <div>
                                Account: {element.account_name}(
                                {element.platform_name})
                              </div>
                              <div className="secondary-text">
                                ID: {element.account_id}
                              </div>
                            </>
                          }{" "}
                        </td>
                      ) : keyword === "item_to_change" ? (
                        <td>
                          {Object.keys(element.parent).length > 0 && (
                            <Popover
                              id="mouse-over-popover"
                              sx={{
                                pointerEvents: "none",
                                "& .MuiPaper-root": {
                                  boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.05)",
                                },
                              }}
                              open={openedPopoverId === element?.id}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                            >
                              <div className="popover-style">
                                <div>
                                  <div>
                                    <span className="sub-header">
                                      {element.parent.concept_type}
                                    </span>
                                    :&nbsp;
                                    <span className="value">
                                      {element.parent.concept_name}
                                    </span>
                                  </div>
                                  <div>
                                    <span>ID:&nbsp;</span>
                                    <span className="secondary-text">
                                      {element.parent.concept_id}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <span className="sub-header">
                                      {element.concept_type}
                                    </span>
                                    :&nbsp;
                                    <span className="value">
                                      {element.concept_name}
                                    </span>
                                  </div>
                                  <div>
                                    <span>ID:&nbsp;</span>
                                    <span className="secondary-text">
                                      {element.concept_id}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Popover>
                          )}
                          <div
                            aria-owns={open ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={(e) =>
                              handlePopoverOpen(element?.id, e)
                            }
                            onMouseLeave={handlePopoverClose}
                            style={{ width: "200px" }}
                          >
                            <div
                              style={{
                                overflowWrap: "anywhere",
                                width: "100%",
                              }}
                            >
                              {element.concept_type}: {element.concept_name}
                            </div>
                            <div className="secondary-text">
                              ID: {element.concept_id}
                            </div>
                          </div>
                        </td>
                      ) : ["recommended_action", "executed_action"].includes(keyword) && element.concept_type === "ASSET_GROUP" ? (
                        <td>
                          {element[keyword] ? (
                            <Stack>
                              <span>{element[keyword]}</span>
                              <Button
                                size='small'
                                onClick={openAssetGroupViewDetailsModal({
                                  details: element[`${keyword}_details`],
                                  itemToChange: `${element.concept_type} : ${element.concept_name}`,
                                })}
                                sx={{
                                  textTransform: "capitalize",
                                  color: "#0869FB",
                                  p: 0,
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  lineHeight: "20px",
                                  letterSpacing: "0.01em",
                                  textAlign: "left",
                                  width: "max-content"
                                }}
                              >
                                View Details
                              </Button>
                            </Stack>
                          ) : "-"}
                        </td>
                      ) : (
                        <td>{element[keyword] ? element[keyword] : "-"}</td>
                      );
                    })}
                  </tr>
                ))}

              {type === "campaign" &&
                data &&
                data.map((element: any, index: number) => (
                  <tr className="tableData" key={element?.id}>
                    <td>{index + 1}</td>
                    <td>{element.id}</td>
                    <td>{element.name}</td>
                  </tr>
                ))}
              {(showTableTopBar && tableData && tableData.length === 0) ||
              (data && data.length === 0) ? (
                <tr>
                  <td colSpan={8} className="borderless-table">
                    <NoData />
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </Table>
          {loadMoreToggle && (
            <div>
              <Loader />
            </div>
          )}
          {showLoadMore && tableData.length ? (
            <ButtonUI
              className={buttonDisabled ? "disabled" : "loadMore"}
              disabled={buttonDisabled}
              onClick={loadMoreData}
            >
              {" "}
              Load more...
            </ButtonUI>
          ) : (
            ""
          )}
        </div>
      )}
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={20000}
        onClose={() => setShowSuccessSnackbar(false)}
      >
        <div
          style={{
            padding: "20px 16px",
            borderRadius: "4px",
            background: "#FFF",
          }}
        >
          <div style={{ display: "flex", gap: "16px" }}>
            <GreenTick style={{ height: "24px", width: "24px" }} />
            <div style={{ width: "296px" }}>
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                {showSuccessSnackbar.length} action queued for execution
              </p>
            </div>
            <Close
              style={{ height: "16px", width: "16px", cursor: "pointer" }}
              onClick={() => setShowSuccessSnackbar(false)}
            />
          </div>
          <div style={{ width: "296px", marginLeft: "40px" }}>
            <p
              style={{ fontSize: "14px", fontWeight: "400", color: "#5C6674" }}
            >
              Execution might take up to a minute. Please refresh the page to
              see the latest status of recommendations
              {showSuccessSnackbar?.type == "retry"
                ? " before retrying again"
                : ""}
              .
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#0869FB",
                cursor: "pointer",
                marginBottom: "0px",
              }}
              onClick={() => setShowSuccessSnackbar(false)}
            >
              Understood
            </p>
          </div>
        </div>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={
          (Object.keys(checkedData.approve).length ||
          Object.keys(checkedData.retry).length
            ? true
            : false) && showAcionSnackbar
        }
        autoHideDuration={20000}
        onClose={() => setShowSuccessSnackbar(false)}
      >
        <div
          style={{
            display: "flex",
            gap: "16px",
            padding: "8px 16px",
            borderRadius: "8px",
            background: "#1A202B",
            alignItems: "center",
          }}
        >
          <CloseWhite
            onClick={() => {
              setCheckedData({ approve: {}, retry: {} });
              setShowAcionSnackbar(false);
            }}
            style={{ cursor: "pointer" }}
          />
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#FFFFFF",
              margin: "0px",
            }}
          >
            {Object.keys(checkedData.approve).length +
              Object.keys(checkedData.retry).length}{" "}
            Item
            {Object.keys(checkedData.approve).length +
              Object.keys(checkedData.retry).length >
            1
              ? "s"
              : ""}{" "}
            selected
          </p>
          <hr
            style={{
              height: "24px",
              width: "1px",
              background: "#FFFFFF",
              margin: "0px",
            }}
          />
          {Object.keys(checkedData.approve).length ? (
            <button
              style={{
                padding: "4px 8px",
                border: "1.5px solid #E0E3E5",
                borderRadius: "6px",
                fontSize: "12px",
                fontWeight: "500",
                color: "#394455",
                background: "#FFF",
                display: "flex",
                gap: "4px",
                height: "fit-content",
                alignItems: "center",
              }}
              onClick={() => {
                handleUpdateStatus(
                  [
                    ...Object.keys(checkedData.approve).map(
                      (i) => checkedData.approve[i]
                    ),
                  ],
                  "approve"
                );
                sendMixPanelEventForUserApprovesOutlierAction(
                  [
                    ...Object.keys(checkedData.approve).map(
                      (i) => checkedData.approve[i]
                    ),
                  ],
                  true
                );
              }}
            >
              <CheckBlack />
              {`Approve (${Object.keys(checkedData.approve).length})`}
            </button>
          ) : (
            <></>
          )}
          {Object.keys(checkedData.approve).length ? (
            <button
              style={{
                padding: "4px 8px",
                border: "1.5px solid #E0E3E5",
                borderRadius: "6px",
                fontSize: "12px",
                fontWeight: "500",
                color: "#394455",
                background: "#FFF",
                display: "flex",
                gap: "4px",
                height: "fit-content",
                alignItems: "center",
              }}
              onClick={() => {
                handleUpdateStatus(
                  [
                    ...Object.keys(checkedData.approve).map(
                      (i) => checkedData.approve[i]
                    ),
                  ],
                  "reject"
                );
                sendMixPanelEventForUsersRejectOutlierAction(
                  [
                    ...Object.keys(checkedData.approve).map(
                      (i) => checkedData.approve[i]
                    ),
                  ],
                  true
                );
              }}
            >
              <CheckBlack />
              {`Reject (${Object.keys(checkedData.approve).length})`}
            </button>
          ) : (
            <></>
          )}
          {Object.keys(checkedData.retry).length ? (
            <button
              style={{
                padding: "4px 8px",
                border: "1.5px solid #E0E3E5",
                borderRadius: "6px",
                fontSize: "12px",
                fontWeight: "500",
                color: "#394455",
                background: "#FFF",
                display: "flex",
                gap: "4px",
                height: "fit-content",
                alignItems: "center",
              }}
              onClick={() => {
                handleUpdateStatus(
                  [
                    ...Object.keys(checkedData.retry).map(
                      (i) => checkedData.retry[i]
                    ),
                  ],
                  "retry"
                );
                sendMixPanelEventForRetryCTAFailedAction(
                  [
                    ...Object.keys(checkedData.retry).map(
                      (i) => checkedData.retry[i]
                    ),
                  ],
                  true
                );
              }}
            >
              <Retry />
              {`Retry (${Object.keys(checkedData.retry).length})`}
            </button>
          ) : (
            <></>
          )}
        </div>
      </Snackbar>

      {isAssetGroupViewDetailsModalOpen && (
        <AssetGroupViewDetailsModal
          isOpen={true}
          onClose={closeAssetGroupViewDetailsModal}
          {...assetGroupViewDetailsModalData}
        />
      )}
    </>
  );
};

export default PyxisTable;

const getStatus = (
  status: string,
  isSuperAdmin: boolean,
  isSuperAdminAllowed: any,
  handleUpdateStatus: any,
  element: any,
  elementIndex: number,
  sendMixPanelEventForRetryCTAFailedAction: any,
  sendMixPanelEventForUserApprovesOutlierAction: any,
  sendMixPanelEventForUsersRejectOutlierAction: any,
  brandRoles: any,
  selectedPlatform:any
) => {
  const isClickDisable = !isAdminRole(brandRoles);
  if (status.toLowerCase().includes("failed")) {
    return (
      <div style={{ width: "120px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "500",
              color: "#E91B16",
              marginBottom: "10px",
            }}
          >
            Failed
          </p>
          {isSuperAdmin && !selectedPlatform?.name?.includes("DV360") && (
            <RolesWrapper disabled={getRolesHierarchy(brandRoles) !== "admin"}>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  height: "fit-content",
                  cursor: isClickDisable ? "not-allowed" : "pointer",
                  fillOpacity: isClickDisable ? 0.5 : 1,
                }}
                onClick={() => {
                  if (!isClickDisable) {
                    sendMixPanelEventForRetryCTAFailedAction(element, false);
                    handleUpdateStatus([element], "", elementIndex, "retry");
                  }
                }}
              >
                <Retry />
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#394455",
                    marginBottom: "0px",
                    cursor: isClickDisable ? "not-allowed" : "pointer",
                    opacity: isClickDisable ? 0.5 : 1,
                  }}
                >
                  Retry
                </p>
              </div>
            </RolesWrapper>
          )}
        </div>
        {isSuperAdmin && (
          <p
            style={{
              fontSize: "10px",
              fontWeight: "400",
              color: "#5C6674",
              marginBottom: "0px",
              wordBreak: "break-all",
            }}
          >
            {element.message}
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div style={{ width: "120px" }}>
        <p
          style={{
            fontSize: "12px",
            fontWeight: "500",
            color: `${
              status.toLowerCase().includes("waiting") ? "#C18C06" : "#000"
            }`,
            marginBottom: "10px",
          }}
        >
          {status}
        </p>
        {/* {status.toLowerCase().includes("completed") && isSuperAdmin && <p style={{fontSize:"10px",fontWeight:"400", color:"#5C6674", marginBottom:"0px", wordBreak:"break-all"}}>{element.message}</p>} */}
        {status.toLowerCase().includes("waiting") && (
          <div style={{ display: "flex", gap: "6px" }}>
            <RolesWrapper disabled={getRolesHierarchy(brandRoles) !== "admin"}>
              <button
                style={{
                  padding: "4px 8px",
                  border: "1.5px solid #E0E3E5",
                  borderRadius: "4px",
                  fontSize: "10px",
                  fontWeight: "400",
                  color: "#394455",
                }}
                onClick={() => {
                  sendMixPanelEventForUserApprovesOutlierAction(element, false);
                  handleUpdateStatus([element], "", elementIndex, "approve");
                }}
              >
                Approve
              </button>
            </RolesWrapper>
            <RolesWrapper disabled={getRolesHierarchy(brandRoles) !== "admin"}>
              <button
                style={{
                  padding: "4px 8px",
                  border: "1.5px solid #E0E3E5",
                  borderRadius: "4px",
                  fontSize: "10px",
                  fontWeight: "400",
                  color: "#394455",
                }}
                onClick={() => {
                  handleUpdateStatus([element], "", elementIndex, "reject");
                  sendMixPanelEventForUsersRejectOutlierAction(element, false);
                }}
              >
                Reject
              </button>
            </RolesWrapper>
          </div>
        )}
      </div>
    );
  }
};

//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import info from "../../assets/svg/smallinfo.svg";
import PyxisTable from "../../components/common/PyxisTable/PyxisTable";
import ModalContainer from "../../components/Modal/ModalContainer";
import ModalWrapper from "../../components/Modal/ModalWrapper";
import {
  getRecommendation,
  rejectRecommendation,
  setRecommendation,
  setSettingPostspinner,
} from "../../store/actions/campaign";
import { ActionImpactState } from "../../store/types/actionImpact";
import { getPlatformName, isAdminRole } from "utils/commonFunctions";
import { initialState } from "../../store/types/auth";
import { getCurrencySymbol } from "../../utils/commonFunctions";
import useSortableData from "../../utils/useSortableData";
import "./index.scss";
import { CampaignState } from "../../store/types/campaign";
import { AdAccountState } from "../../store/types/adaccount";
import { getPaginatedRecommendations } from "../../store/actions/actionImpact";
import { calcualteTimeOffset } from "utils/date.util";
import { Box, Skeleton } from "@mui/material";
import { StyledText } from "components/shared";
import CustomizedSwitch from "components/shared/Switch/CustomizedSwitch";
import CreativeRotation from "./CreativeRotation/CreativeRotation";
import { StyledToggleBox } from "./styled";
import { ActionDebuggerHelperInitState } from "store/types/ActionDebugger/ActionDebuggerHelper";
import { dispatch } from "d3";
import { crReducerReset } from "store/actions/ActionDebugger/CreativeRotation/creativeRotation.actions";
import { useActionImpact } from "./CreativeRotation/hooks/useActionImpact";
import moment from "moment";
import { mixPanelAdEvent } from "utils/mixpanel";
import { useAuth0 } from "@auth0/auth0-react";
import { useManageAgencyUserRolesState } from "Pages/ManageAgency/UserRoles/useManageAgencyUserRolesState";

const TableTitle = ({
  creativeRotationToggle,
  setCreativeRotationToggle,
}: any) => {
  const target2 = React.useRef(null);
  const { selectedAdAccount } = useSelector(
    (state: { ActionDebuggerHelperReducer: ActionDebuggerHelperInitState }) =>
      state.ActionDebuggerHelperReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;
  const { user } = useAuth0();
  const {
    selectedBrand,
    selectedGroup,
    selectedPlatform,
    selectedAdAccount: { id, name },
  } = adaccount;
  const dispatch = useDispatch();
  useActionImpact({ setCreativeRotationToggle });

  const recommendationsTooltip = (props?: any) => (
    <div {...props} className="custom-tooltip-layout tooltip">
      <div className="tootltip-wrapper">
        <div className="tooltip-header">Action Recommendations</div>
        This shows AI-recommended actions to be taken on individual elements
        like ad sets and campaigns to optimize their performance.
      </div>
    </div>
  );

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <h4 className="campaigns_header fs-6 p-0 pt-4 ml-4 d-flex pb-2">
        Action Recommendations
        <div className="position-relative tooltip-container">
          <i ref={target2}>
            <img className="ml-2" src={info} alt="info_style" />
          </i>
          {recommendationsTooltip()}
        </div>
      </h4>
      {selectedPlatform?.name?.toLowerCase()?.includes("facebook") &&
        (selectedAdAccount?.selectedAdAcc?.elementId === undefined ? (
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "0.5em",
              width: "18.75em",
              margin: "0.5em 1.25em 1.5625em 0em",
              height: "2.5em",
            }}
          />
        ) : (
          <StyledToggleBox
            m="0.5em 1.25em 1.5625em 0em"
            p="0.2em 0.75em"
            sx={{
              background: "#F0F3FF",
              borderRadius: "0.5em",
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledText color="#0869FB" fontSize="1em" lineHeight="24px">
              {" "}
              View Creative Recommendations
            </StyledText>
            <CustomizedSwitch
              checked={creativeRotationToggle}
              disabled={
                !selectedPlatform?.name?.toLowerCase().includes("facebook")
              }
              onClick={(e: any) => {
                let temp = creativeRotationToggle;
                setCreativeRotationToggle((prev) => !prev);
                if (creativeRotationToggle) {
                  dispatch({
                    type: CANCEL_BATCH_DATA_DETAILS,
                  });
                  dispatch({
                    type: CANCEL_PREVIEW_DATA,
                  });
                  dispatch(crReducerReset(""));
                }
                mixPanelAdEvent("View Creative Recommendations Toggle", {
                  username: user?.email,
                  agency_id: selectedBrand?.agencyId,
                  agency_name: selectedBrand?.agency_name,
                  brand_id: selectedBrand?.id,
                  brand_name: selectedBrand?.name,
                  brand_type: selectedBrand?.brandType,
                  ad_account_id: id,
                  ad_account_name: name,
                  ai_group_name: selectedGroup?.name,
                  platform_name: getPlatformName(selectedPlatform?.name),
                  status: !temp ? "ON" : "OFF",
                });
              }}
            />
          </StyledToggleBox>
        ))}
    </Box>
  );
};

const ActionImpactTable: React.FC = () => {
  const actionImpact = useSelector(
    (state: { actionImpact: ActionImpactState }) => state.actionImpact
  );
  const { linkClicks } = actionImpact;
  const [modal, setModal] = React.useState(null);
  const [activeCustomHeader, setActiveCustomHeader] = React.useState("");
  const [dateTime, _] = React.useState(linkClicks);
  const campaign = useSelector(
    (state: { campaign: CampaignState }) => state.campaign
  );
  const { settingPostspinner, recommendation } = campaign;
  const { accountDetails, allowRejectRecommendation, user } = useSelector(
    (state: { auth: initialState }) => state.auth
  );
  const { items, requestSort } = useSortableData(recommendation || []);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;

  const { selectedAdSet, selectedAdAccount } = adaccount;
  const [sortConfig, setSortConfig] = React.useState<any>({});

  const { selectedGroup } = adaccount;

  const actionImpactTableHeader = React.useMemo(
    () => [
      {
        name: "Recommendation",
        isSortable: true,
        sortKey: "recommendation_time",
        isEnabled: true,
      },
      {
        name: "Item to Change",
        isSortable: true,
        isEnabled: true,
        sortKey: "item_to_change",
      },
      {
        name: "Recommended Action",
        isSortable: true,
        sortKey: "recommended_action",
        isEnabled: true,
        type: "analytics",
        tableData:
          !!accountDetails?.currency &&
          "(in " + (getCurrencySymbol(accountDetails?.currency) || "") + ")",
      },
      {
        name: "Action Basis",
        isSortable: true,
        isEnabled: true,
        type: "analytics",
        sortKey: "action_basis",
      },
      {
        name: "Confidence Score",
        isSortable: true,
        isEnabled: true,
        type: "analytics",
        sortKey: "confidence_score",
      },
      {
        name: "Action Execution Time",
        isSortable: true,
        isEnabled: true,
        sortKey: "action_execution_time",
        type: "setup",
      },
      {
        name: "Phase",
        isSortable: true,
        isEnabled: true,
        sortKey: "phase",
      },
      {
        name: "Executed Action",
        isSortable: true,
        isEnabled: true,
        sortKey: "executed_action",
        type: "analytics",
        tableData:
          !!accountDetails?.currency &&
          "(in " + (getCurrencySymbol(accountDetails?.currency) || "") + ")",
      },
      {
        name: "Status",
        isSortable: true,
        isEnabled: true,
        sortKey: "status",
      },
      {
        name: "Message",
        isSortable: true,
        isEnabled: false,
        sortKey: "message",
      },
      {
        name: "Campaign Name",
        isSortable: true,
        isEnabled: false,
        sortKey: "campaign_name",
      },
      {
        name: "Campaign Id",
        isSortable: true,
        isEnabled: false,
        sortKey: "campaign_id",
      },
      {
        name: "Concept Name",
        isSortable: true,
        isEnabled: false,
        sortKey: "concept_name",
      },
      {
        name: "Concept Type",
        isSortable: true,
        isEnabled: false,
        sortKey: "concept_type",
      },
      {
        name: "FB Ad Account ID",
        isSortable: true,
        isEnabled: false,
        sortKey: "fb_ad_account_id",
      },
      {
        name: "CPR",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "cpr",
      },
      {
        name: "CPR Lookback",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "cpr_lookback",
      },
      {
        name: "Spend",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "spend",
      },
      {
        name: "Spend Lookback",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "spend_lookback",
      },
      {
        name: "Results",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "results",
      },
      {
        name: "Results Lookback",
        isSortable: true,
        isEnabled: false,
        type: "analytics",
        sortKey: "results_lookback",
      },
      {
        name: "Lookback Period",
        isSortable: true,
        isEnabled: false,
        sortKey: "lookback_period",
      },
      {
        name: "Aggregation Concept Name",
        isSortable: true,
        isEnabled: false,
        sortKey: "aggregation_concept_name",
      },
      {
        name: "Updated Daily Budget",
        isSortable: true,
        isEnabled: false,
        sortKey: "updated_daily_budget",
      },
      {
        name: "Updated Bid Amount",
        isSortable: true,
        isEnabled: false,
        sortKey: "updated_bid_amount",
      },
    ],
    [accountDetails?.currency, accountDetails?.timezone_offset_hours_utc]
  );

  const [updatedTableHeader, setUpdatedTableHeader] = React.useState(
    actionImpactTableHeader
  );
  const [dateRange, setDateRange] = React.useState({
    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });
  const [loadMoreData, setLoadMoreData] = React.useState([]);
  const [pagination, setPagination] = React.useState<any>(1);
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);
  const [showLoadMore, setShowLoadMore] = React.useState<boolean>(true);
  const [sortRecommendations, setSortRecommendations] =
    React.useState<boolean>(true);

  const dispatch = useDispatch();
  // const { isSuperAdmin } = useManageAgencyUserRolesState();
  // const isSuperAdmin = useSelector((state)=>state.userInfoReducer?.userInfo?.roles?.includes('super-admin'))
  const isSuperAdmin = isAdminRole(brandRoles);

  useEffect(() => {
    if (dateRange.end && selectedAdAccount?.elementId) {
      dispatch(setRecommendation([]));
      const activeHeader = !!activeCustomHeader
        ? activeCustomHeader
        : actionImpactTableHeader
          .filter((elem: any) => elem.isEnabled)
          .map((element: any) => element.sortKey)
          .toString();
      dispatch(setSettingPostspinner(true));
      dispatch(
        getPaginatedRecommendations(
          {
            elementId: selectedAdAccount.elementId,
            dates: dateRange,
            user,
            activeHeader,
            pageNumber: 1,
            sort_by: "",
            sort_order: "",
            isSuperAdmin,
          },
          (response: any, error: boolean) => {
            if (!error) {
              setSortRecommendations(false);
              dispatch(setRecommendation(response.data.results));
              setPagination(1);
              setButtonDisabled(false);
              setLoadMoreData(response.data);
            } else {
              console.log(error);
            }
            dispatch(setSettingPostspinner(false));
          }
        )
      );
    } else {
      dispatch(setRecommendation([]));
    }
  }, [dateRange, selectedAdAccount, isSuperAdmin]);

  const loadSortedRecommendations = (key: string, activeHeader) => {
    let direction = "ASC";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ASC"
    ) {
      direction = "DESC";
    }
    setSortConfig({ key, direction });

    dispatch(
      getPaginatedRecommendations(
        {
          elementId: selectedAdAccount.elementId,
          dates: Object.keys(dateRange).length ? dateRange : linkClicks,
          user,
          activeHeader,
          pageNumber: 1,
          sort_by: key,
          sort_order: direction,
          isSuperAdmin,
        },
        (response: any, error: boolean) => {
          if (!error) {
            setSortRecommendations(false);
            dispatch(setRecommendation(response.data.results));
            setPagination(1);
            setButtonDisabled(false);
            setLoadMoreData(response.data);
          } else {
            console.log(error);
          }
        }
      )
    );
  };

  const handleRejectRecommendation = React.useCallback(
    (setLoading) => {
      setLoading(true);
      const data = items[modal?.index];
      dispatch(
        rejectRecommendation(
          { id: data.id, concept_type: data.concept_type, user },
          (response: any, error: boolean) => {
            if (!error) {
              dispatch(
                setRecommendation(
                  recommendation.map((r) => {
                    if (data.id === r.id) {
                      r.status = "Rejected";
                    }
                    return r;
                  })
                )
              );
              setLoading(false);
            } else {
              console.log(error);
            }
            setModal(null);
          }
        )
      );
    },
    [items, modal?.index]
  );

  const updateTableData = (activeHeader) => {
    setActiveCustomHeader(activeHeader);
    linkClicks && dispatch(setSettingPostspinner(true));
    linkClicks &&
      dispatch(
        getPaginatedRecommendations(
          {
            elementId: selectedAdAccount.elementId,
            dates: dateRange.end ? dateRange : linkClicks,
            user,
            activeHeader,
            pageNumber: 1,
            sort_by: "",
            sort_order: "",
            isSuperAdmin,
          },
          (response: any, error: boolean) => {
            if (!error) {
              dispatch(setRecommendation(response.data.results));
              setPagination(1);
              setButtonDisabled(false);
              setSortRecommendations(false);
              setLoadMoreData(response.data);
            } else {
              console.log(error);
            }
            dispatch(setSettingPostspinner(false));
          }
        )
      );
  };

  const loadMoreRecommendations = (page: any, activeHeader) => {
    dispatch(
      getPaginatedRecommendations(
        {
          elementId: selectedAdAccount.elementId,
          dates: dateRange.end ? dateRange : linkClicks,
          user,
          activeHeader,
          pageNumber: page,
          sort_by: sortConfig.key || "",
          sort_order: sortConfig.direction || "",
          isSuperAdmin,
        },
        (response: any, error: boolean) => {
          if (!error) {
            setSortRecommendations(true);
            setLoadMoreData(response.data);
            if (page === 1) {
              dispatch(setRecommendation(response.data?.results));
            } else {
              dispatch(
                setRecommendation([
                  ...recommendation,
                  ...response.data?.results,
                ])
              );
            }
          } else {
            console.log(error);
          }
        }
      )
    );
  };

  useEffect(() => {
    setUpdatedTableHeader(actionImpactTableHeader);
  }, [actionImpactTableHeader]);

  const [creativeRotationToggle, setCreativeRotationToggle] = useState<
    boolean | undefined
  >(false);

  return (
    <div className="action_recommendations" style={{ flexGrow: 1 }}>
      {TableTitle({ creativeRotationToggle, setCreativeRotationToggle })}
      {creativeRotationToggle ? (
        <CreativeRotation />
      ) : (
        <PyxisTable
          // tableTitle={TableTitle}
          type={"actionImpact"}
          responsiveType={"md"}
          showTableTopBar={false}
          maxHeight={"300px"}
          linkClicks={dateRange.end ? dateRange : linkClicks}
          setDateRange={setDateRange}
          tableContainerClass={
            accountDetails?.platform === "facebook"
              ? "facebook-action-recommendation-table"
              : "google-action-recommendation-table"
          }
          tableClass={`mt-4 ml-4 overflow-scroll pyxis-table`}
          tableHeader={updatedTableHeader}
          data={(recommendation && items && items.length > 0 && items) || []}
          updateTableData={updateTableData}
          loadMore={loadMoreRecommendations}
          loadMoreRecommendations={loadMoreData}
          setButtonDisabled={setButtonDisabled}
          buttonDisabled={buttonDisabled}
          setPagination={setPagination}
          pagination={pagination}
          showLoadMore={showLoadMore}
          sortRecommendations={sortRecommendations}
          setUpdatedTableHeader={setUpdatedTableHeader}
          requestSort={loadSortedRecommendations}
          loading={settingPostspinner}
          sortConfig={sortConfig}
          isSuperAdmin={isSuperAdmin}
          handleAction={
            !allowRejectRecommendation
              ? (index: number) => setModal({ index })
              : null
          }
        />
      )}
      {modal && (
        <ModalWrapper>
          <ModalContainer
            title="Reject Recommendation"
            cancelText="Close"
            submitText={"Reject"}
            handleSubmit={handleRejectRecommendation}
            handleClose={() => setModal(false)}
          >
            <p>
              Are you sure you want to cancel this recommendation from execution
              on ad account? Rejecting this recommendation might impact your
              performance.
            </p>
          </ModalContainer>
        </ModalWrapper>
      )}
    </div>
  );
};

export default ActionImpactTable;

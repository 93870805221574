import { useState, useEffect, useCallback } from "react";
import { AdSettingsTypes } from "../Editor.types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateVariantsState,
  GENERATE_VARIANT_METADATA_STATE_VARIANT,
  UPDATE_AI_GROUP,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
} from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { generateConfigurePayload } from "../../../utils/form.utils";
import { initialState } from "../../../../../../store/types/auth";
import { updateConfigureState } from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { checkAdType } from "components/CreativeAI/Facebook/utils/common.utils";
import { AdAccountState } from "store/types/adaccount";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";

export const useEditor = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm();
  const inputData = {};
  const {
    createSpec,
    selectedAd,
    variantList,
    extraFields: { selectedVariantIndex },
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const { BUSINESS_UNIT_BY_ID_DATA, LEVERS } = useSelector((state: any) => ({
    BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
    LEVERS: state.MainReducer.defaultLevers,
  }));
  const { adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const [textFields, setTextFields] = useState<AdSettingsTypes[]>([]);
  const [isUpdateAISettingChecked, setIsUpdateAISettingChecked] =
    useState(false);
  const [isGeneratingRecommendation, setIsGeneratingRecommendation] =
    useState(true);
  const dispatch = useDispatch();
  const onAdSettingChange = (event: any, index: number) => {
    const localTextFields = [...textFields];
    const textFiled = { ...localTextFields[index] };
    textFiled.value = event.target.value;
    localTextFields[index] = textFiled;
    setTextFields(localTextFields);
    dispatch({
      type: UPDATE_RECOMMENDATION_SELECTION_STATE,
      payload: {
        isChanged: true,
      },
    });
  };
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  // const { selectedAiGroup } = useSelector((state: any) => state.aiGroupReducer);
  const { aiGroupList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  useEffect(() => {
    watch();
  }, []);

  const getDisplayUrlAndUrl = useCallback(() => {
    const { asset_feed_spec, object_story_spec } = createSpec;
    const { video_data, link_data } = object_story_spec;
    if (link_data) {
      return {
        displayUrl: link_data?.caption ?? "",
        url: createSpec?.object_story_spec?.link_data?.link ?? "",
      };
    } else if (video_data) {
      return {
        displayUrl: video_data?.call_to_action?.value?.link_caption ?? "",
        url: video_data?.call_to_action?.value?.link ?? "",
      };
    } else if (asset_feed_spec) {
      const { link_urls } = asset_feed_spec;
      return {
        displayUrl: link_urls?.[0]?.display_url,
        url: link_urls?.[0]?.website_url,
      };
    }
    return { displayUrl: "", url: "" };
  }, [createSpec]);

  useEffect(() => {
    const index = selectedVariantIndex !== undefined ? selectedVariantIndex : 0;
    const variant = variantList[index];
    setTextFields([
      {
        label: "New Ad Name",
        value: variant.id
          ? variant?.payload?.settings?.variantName
          : selectedAd.name + "_CAI_cv" + variantList.length,
        tooltipText: "The link your users will see on the bottom of your ad.",
      },
      {
        label: "Display URL",
        value: variant.id
          ? variant?.payload?.settings?.displayUrl
          : getDisplayUrlAndUrl().displayUrl ?? "",
        tooltipText: "The link your users will see on the bottom of your ad.",
      },
      {
        label: "URL",
        value: variant.id
          ? variant?.payload?.settings?.url
          : getDisplayUrlAndUrl().url ?? "",
        tooltipText:
          "The link you want to redirect your audience to on clicking the ad",
      },
      {
        label: "FB Page",
        value: variant.id
          ? variant?.payload?.settings?.fbPage
          : createSpec?.object_story_spec?.page_id ?? "",
        tooltipText:
          "The name/id of the Facebook Page associated with your ad, if applicable",
        readOnly: true,
      },
      {
        label: "Instagram Page",
        value: variant.id
          ? variant?.payload?.settings?.instagramPage
          : createSpec?.object_story_spec?.instagram_actor_id ?? "",
        tooltipText:
          "The name/id of the Instagram Page associated with your ad, if applicable",
        readOnly: true,
      },
      // {
      //   label: "Pixel",
      //   value: variant.id
      //     ? variant?.payload?.settings?.pixel
      //     : selectedAd?.tracking_specs?.length &&
      //       selectedAd?.tracking_specs[0]?.fb_pixel?.length
      //     ? selectedAd?.tracking_specs[0]?.fb_pixel[0]
      //     : "",
      //   tooltipText:
      //     "The name/id of the Pixel associated with your ad, if applicable",
      //   readOnly: true,
      //   type: "Select",
      //   options: ["Livspace", "Livspace 2", "Livspace 3"],
      // },
      // {
      //   label: "App Event",
      //   tooltipText:
      //     "Add events to your app to view analytics, measure ad performance and build audiences for ad targeting",
      //   readOnly: true,
      //   placeholder: "Enter the link events",
      // },
      // {
      //   label: "Offline Event",
      //   tooltipText:
      //     "Upload offline sales and interaction data to measure ad conversions. Then build audiences for ad targeting based on these transactions",
      //   readOnly: true,
      //   placeholder: "Enter the offline events",
      // },
      // {
      //   label: "View Tags",
      //   tooltipText: "Testing tool tip",
      //   readOnly: true,
      //   placeholder: "Enter the view tags",
      // },
    ]);
  }, [selectedVariantIndex]);

  const onGenerate = (data: any, setIsConfiguring: any) => {
    dispatch(updateConfigureState(data));
    if (isUpdateAISettingChecked) {
      const {
        businessUnit,
        businessUnitDetail,
        status,
        primary_text: primaryText,
        ...rest
      } = data;
      if (rest?.image) {
        delete rest?.image;
      }
      if (rest?.video) {
        delete rest?.video;
      }
      dispatch({
        type: UPDATE_AI_GROUP,
        payload: {
          user: auth.user,
          elementData: {
            ...rest,
            primaryText,
            elementId: [aiGroupList?.selectedAIGroup?.Elements?.[0]?.id],
          },
        },
      });
    }
    dispatch({
      type: "GENERATE_VARIANT_METADATA",
      payload: {
        user: auth.user,
        data: generateConfigurePayload(
          {
            ...data,
            businessUnit: BUSINESS_UNIT_BY_ID_DATA.businessUnit,
            businessUnitDetail: BUSINESS_UNIT_BY_ID_DATA.businessUnitDetail,
          },
          LEVERS,
          "generate",
          checkAdType(createSpec),
          selectedBrand?.id,
          selectedAd?.id,
          adAccountsSelection?.selectedAdAccountId
        ),
        finalAction: GENERATE_VARIANT_METADATA_STATE_VARIANT,
      },
      callback: () => {
        setIsGeneratingRecommendation(true);
        setIsUpdateAISettingChecked(false);
        setIsConfiguring(false);
      },
    });
  };

  const onUpdateAISettingCheckClick = (data: any) => {
    setIsUpdateAISettingChecked(!isUpdateAISettingChecked);
  };
  return {
    textFields,
    onAdSettingChange,
    register,
    handleSubmit,
    inputData,
    onGenerate,
    errors,
    getValues,
    control,
    setValue,
    reset,
    watch,
    isUpdateAISettingChecked,
    onUpdateAISettingCheckClick,
    setError,
    clearErrors,
    isGeneratingRecommendation,
    setIsGeneratingRecommendation,
  };
};

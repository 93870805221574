import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import Loader from "../../../assets/Gif/3dot_loading.gif";
import { ReactComponent as CopyClipboardIcon } from "../../../assets/svg/copy_clipboard.svg";
import { CustomizedSnackBars } from "../AlertSnackBar/AlertSnackBar";
import { StyledClipBoardBox } from "./styled";

export default function CopyClipboard({
  isLoading,
  text,
  mixPanelReviewLinkCopied,
}: {
  isLoading: boolean;
  text: string;
  mixPanelReviewLinkCopied: any;
}) {
  const [showToast, setShowToast] = useState(false);
  const handleSnackBarClose = () => {
    setShowToast(false);
  };
  return (
    <>
      <StyledClipBoardBox>
        <Box
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "90%",
          }}
        >
          {text}
          {isLoading && (
            <Box
              component="img"
              src={Loader}
              sx={{
                height: "24px",
                width: "24px",
                display: "inline",
                paddingLeft: "4px",
              }}
            />
          )}
        </Box>
        <IconButton
          disabled={isLoading}
          onClick={() => {
            navigator.clipboard.writeText(text);
            mixPanelReviewLinkCopied();
            setShowToast(true);
          }}
        >
          <CopyClipboardIcon />
        </IconButton>
      </StyledClipBoardBox>
      <CustomizedSnackBars
        handleClose={handleSnackBarClose}
        open={showToast}
        msg="Link copied to clipboard"
      />
    </>
  );
}

import { useQuery } from "../hooks";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { CROSS_PLATFORM_URL } from "utils/path";
import HttpService from "HttpService/HttpService";
import {
  enableMasterAccess,
  getEligibleAdAccounts,
  linkTokenToAdAccounts,
  getTokenUID,
} from "../../../store/actions/adaccount";
import { initialState } from "../../../store/types/auth";

type ListProp = {
  adAccountId: string;
  hasPermission: boolean;
  adAccountName: string;
  alreadyConnectedBySelf: boolean;
  alreadyConnectedtoAnotherBrand: boolean;
  isChecked?: boolean;
};

export default function useSelectAccount() {
  const [email, setEmail] = useState<string>("");
  const [state, token] = useQuery();
  const [list, setList] = useState<ListProp[]>([]);
  const [filteredList, setFilteredList] = useState<ListProp[]>([]);
  const [loader, setLoader] = useState(false);
  const [checkAll, setCheckAll] = useState("");
  const [checked, setChecked] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [modal, setModal] = useState<any>(null);
  const [tokenUID, setTokenUID] = useState<string>("");
  const [platformId, setPlatformId] = useState<any>({});
  const [noAccounts, setNoAccounts] = useState<boolean>(false);
  const auth = useSelector((state: { auth: initialState }) => state.auth);

  const handleChanage = (value: number) => {
    let newList = [...filteredList];
    newList[value].isChecked = filteredList[value].isChecked ? false : true;
    setFilteredList(newList);
  };
  useEffect(() => {
    const _checked = [];
    let count = 0;
    for (const account of filteredList) {
      if (account.isChecked) {
        _checked.push(account.adAccountId);
        count++;
      }
    }

    if (count === 0) {
      setCheckAll("");
    } else if (count === filteredList.length) {
      setCheckAll("all");
    } else {
      setCheckAll("intermediate");
    }
    setChecked(_checked);
  }, [filteredList]);

  const handleCheckAll = useCallback(
    (isChecked: boolean) => {
      const _list = [...filteredList];
      for (const item of _list) {
        if (item.alreadyConnectedtoAnotherBrand || !item.hasPermission) {
          item.isChecked = false;
        } else {
          item.isChecked = item.alreadyConnectedBySelf || isChecked;
        }
      }
      setFilteredList(_list);
    },
    [filteredList]
  );
  const handleInputChanage = (search: string) => {
    if (search) {
      const value = search.toLowerCase();
      const newList: ListProp[] = list.filter(
        (adacc) =>
          adacc.adAccountId.includes(value) ||
          adacc.adAccountName.toLowerCase().includes(value)
      );
      setFilteredList(newList);
    } else {
      setFilteredList(list);
    }
  };

  useEffect(() => {
    if (state.brand_id && token) {
      setLoader(true);
      dispatch(
        getTokenUID(
          {
            brandId: state.brand_id,
            platform: state.platform,
            oauthCode: token,
            user: auth?.user,
          },
          (response: any, error: boolean) => {
            if (!error) {
              if (response?.data?.tokenId) {
                setTokenUID(response.data.tokenId);
              } else {
                setModal({ key: "error" });
              }
            } else {
              setModal({ key: "error" });
            }
            setLoader(false);
          }
        )
      );
    }
  }, [state, token]);

  useEffect(() => {
    HttpService.get(CROSS_PLATFORM_URL, "/v1/api/parent-platform")
      .then((res: any) => {
        if (res?.data?.data) {
          const _platformId: any = {};
          res.data.data?.forEach((item: any) => {
            _platformId[item.name.toLowerCase()] = item.id;
          });
          setPlatformId(_platformId);
        }
      })
      .catch((err: any) => {
        setModal({ key: "error" });
      });
  }, []);

  useEffect(() => {
    if (tokenUID) {
      setLoader(true);
      dispatch(
        getEligibleAdAccounts(
          { brandId: state.brand_id, tokenUID, platform: state.platform },
          (response: any, error: boolean) => {
            if (!error && response?.data?.adAccounts) {
              if (response?.data?.adAccounts.length === 0) {
                setNoAccounts(true);
              } else {
                setList(response.data.adAccounts);
                setEmail(response.data?.email || "");
              }
            } else {
              setModal({ key: "error" });
            }
            setLoader(false);
          }
        )
      );
    }
  }, [state, tokenUID]);

  useEffect(() => {
    const filtered = list.map((item) => {
      item.isChecked =
        item.alreadyConnectedBySelf &&
        !item.alreadyConnectedtoAnotherBrand &&
        item.hasPermission;
      return item;
    });
    setFilteredList(filtered);
  }, [list]);

  const onSubmit = () => {
    setModal(null);
    const params = {
      brandId: state.brand_id,
      platform: state.platform,
      parentPlatformId: platformId[state.platform],
      adAccountIds: checked,
    };
    setLoader(true);
    dispatch(
      linkTokenToAdAccounts(
        { params, tokenUID },
        (response: any, error: boolean) => {
          setLoader(false);
          if (!error) {
            // setModal({ key: `connectAdaccountPopup` });
            setModal({
              key: `${
                state.platform === "pinterest ads"
                  ? "success"
                  : "connectAdaccountPopup"
              }`,
            });
          } else {
            setModal({ key: "error" });
          }
        }
      )
    );
  };

  const masterAccess = () => {
    const params = {
      platform: state.platform,
      adAccountIds: checked,
    };
    setLoader(true);
    dispatch(
      enableMasterAccess(
        { params, tokenUID },
        (response: any, error: boolean) => {
          setLoader(false);
          if (!error) {
            setModal({ key: "emailPopup" });
          } else {
            setModal({ key: "error" });
          }
        }
      )
    );
  };

  const onNextClick = () => {
    setModal({ key: "success" });
  };

  const FooterComponentforConfirm = () => {
    return (
      <div className="modal-footer d-flex justify-content-between">
        <span onClick={onNextClick} className="text-md skip-link">
          Skip
        </span>
        <button
          type="button"
          className="modal-button primary text-md"
          onClick={masterAccess}
        >
          Confirm
        </button>
      </div>
    );
  };

  const ErrorFooter = () => {
    return (
      <div className="modal-footer d-flex justify-content-end">
        <button
          type="button"
          className="modal-button primary"
          onClick={() => {
            window.location.href = "/singlePlatform" + window.location.search;
          }}
        >
          Close
        </button>
      </div>
    );
  };

  const FooterComponentforSuccess = () => {
    return (
      <div className="modal-footer d-flex justify-content-between">
        <a href="/accounts-manager" className="footer-connect-acc-link">
          View connected accounts
        </a>
        <div>
          <button
            type="button"
            className="success-buttons primary-reverse"
            onClick={() => {
              window.location.href = `/social-login?brand_id=${state.brand_id}&source=${state.source}`;
            }}
          >
            Add More
          </button>
          <button
            type="button"
            className="success-buttons primary"
            onClick={() => {
              switch (state.source) {
                case "plugin":
                  window.close();
                  break;
                default:
                  window.location.href =
                    "/singlePlatform" + window.location.search;
              }
            }}
          >
            Close Tab
          </button>
        </div>
      </div>
    );
  };

  const confirmationHeader = (platform: string) => {
    switch (platform) {
      case "google":
        return "Add to Manager Account";
      case "linkedin":
        return "Add user to Ad Account";
      case "facebook":
        return "Add to Business Manager";
    }
  };

  return {
    state,
    filteredList,
    loader,
    checkAll,
    modal,
    list,
    setModal,
    setCheckAll,
    onNextClick,
    handleChanage,
    confirmationHeader,
    handleInputChanage,
    FooterComponentforConfirm,
    onSubmit,
    FooterComponentforSuccess,
    handleCheckAll,
    ErrorFooter,
    noAccounts,
    checked,
    email,
    platformId,
    tokenUID,
  };
}

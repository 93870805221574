import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import BrandRow from "../common/BrandRow";
import BrandAdAccountRow from "../common/BrandAdAccountRow";
import Header from "../Components/Header";
import TilesRow from "../Components/TilesRow";
import { getAllBrandsDetails } from "../../../store/actions/SmbDashboard/brand";
import { Loader } from "../../../components/shared";
import {
  setSelectedBrand,
  resetSelectedBrand,
} from "../../../store/actions/SmbDashboard/selectedBrand";
import { getAgenciesSmb } from "../../../store/actions/SmbDashboard/agencies";
import { getAllPlatforms } from "store/actions/SmbDashboard/common";
import EditBrand from "../EditBrand";
import SuccessModel from "../../../common/SuccessModal/index";
import { AdAccountState } from "../../../store/types/adaccount";
import { setActiveBrand } from "../../../store/actions/adaccount";
import { setSelectedAgency } from "../../../store/actions/common";
import {
  getAdAccountMetrics,
  resetAdAccountsData,
} from "store/actions/SmbDashboard/adAccount";
import InfiniteScroll from "react-infinite-scroll-component";
import { mixPanelAdEvent } from "utils/mixpanel";

function AdAccountSummary() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const agencyId = params.get("agency-id");

  const adAccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brands } = adAccount;
  const authState = useSelector((state: any) => state.auth);
  const { user } = authState;
  const brandState: any = useSelector((state: any) => state.smb.brand);
  const agenciesState: any = useSelector((state: any) => state.smb.agencies);
  const commonState: any = useSelector((state: any) => state.smb.common);
  const [paginatedData, updatePaginatedData] = useState<any>([]);
  const [searchedPaginatedData, updateSearchedPaginatedData] =
    useState<any>(paginatedData);

  useEffect(() => {
    console.log("brandStatebrandStatebrandState", brandState);
    updatePaginatedData(
      Object?.keys(brandState?.data || {})?.slice(0, 10) || []
    );
  }, [brandState]);
  const [showOptimizer, setShowOptimizer] = useState(null);
  const [showPlatform, setShowPlatform] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTileKey, setActiveTileKey] = useState("total_brands");

  const allBrands = useSelector(
    (state: any) => state?.smb?.adAccount?.adAccountMetricsData || {}
  );

  useEffect(() => {
    if (searchTerm) {
      updateSearchedPaginatedData(
        Object.keys(brandState.data).filter((item) =>
          brandState.data[item].details.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      );
    } else {
      updateSearchedPaginatedData(paginatedData);
    }
  }, [paginatedData, searchTerm]);
  useEffect(() => {
    if (agenciesState.data.length === 0) {
      dispatch(getAgenciesSmb({ user, agencyId }));
    } else if (agencyId) {
      const selectedAgency = agenciesState.data.find(
        (agency: any) => agency.id === agencyId
      );
      dispatch(setSelectedAgency(selectedAgency));
    }
    if (!commonState.platforms) dispatch(getAllPlatforms());
  }, []);

  useEffect(() => {
    if (agenciesState.selected) {
      dispatch(
        getAllBrandsDetails({
          agencyId: agenciesState.selected.id,
          adAccountSummaryScreen: true,
        })
      );
      history.push(window.location.search);
    }
    return () => {
      // cancel request - requestId
    };
  }, [agenciesState]);

  useEffect(() => {
    const brandIds =
      brandState && brandState.data ? Object.values(brandState.data) : [];
    if (brandIds.length > 0) {
      // setLoading(true);
      dispatch(
        getAdAccountMetrics({
          brandIds,
          selectedMonth: commonState.calendar.selectedMonth,
          selectedYear: commonState.calendar.selectedYear,
        })
      );
    }
    return () => {
      dispatch(resetAdAccountsData());
      // cancel request - requestId
    };
  }, [commonState.calendar, brandState]);

  const handleBrandClick = (event: any, brandId: any, name: string) => {
    if (
      !event?.target?.classList.contains("handle-click") &&
      !showOptimizer &&
      !showPlatform
    ) {
      dispatch(
        setSelectedBrand({
          id: brandId,
          name,
        })
      );
      let brand: any = brands.find((brand: any) => brand.id === brandId);
      dispatch(resetSelectedBrand());
      dispatch(setActiveBrand(brand));
      mixPanelAdEvent("SMB Brand Summary Clicked", {
        brand_name: name,
        brand_id: brandId,
        agency_name: agenciesState.selected.name,
        agency_id: agenciesState.selected.id,
        dashboard_view: "Ad Account Summary View",
      });
      history.push(
        `/smb-dashboard/brand?${
          agencyId ? "agency-id=" + agencyId + "&" : ""
        }brand_id=${brandId}`
      );
    }
  };
  const [editBrandOpen, setEditBrandOpen] = useState(null);
  const [success, setSuccess] = useState(false);

  return (
    <Box className="font-inter">
      <Header
        type="Agency"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <Box
        sx={{
          position: "relative",
          minHeight: "calc(100vh - 15vh)",
          background: "#FFFFFF",
          borderRadius: "9px",
          p: 2,
          m: 2,
        }}
      >
        <TilesRow
          type="Ad Account"
          activeTileKey={activeTileKey}
          setActiveTileKey={setActiveTileKey}
        />
        {brandState?.isLoading ? (
          <Box sx={{ position: "absolute", top: "50%", left: "50%" }}>
            <Loader />
          </Box>
        ) : false ? (
          Object.keys(brandState.data || []).map(
            (brandId: any) =>
              brandState.data[brandId].details.name
                .toLowerCase()
                .startsWith(searchTerm.toLowerCase()) && <></>
          )
        ) : (
          <Box
            id="scrollableDiv"
            sx={{
              height: "90vh",
              overflow: "auto",
              // display: "flex",
            }}
          >
            <InfiniteScroll
              dataLength={paginatedData.length}
              next={() => {
                let brandIds = Object.keys(brandState?.data);
                if (brandIds.length - paginatedData.length < 10) {
                  updatePaginatedData([
                    ...paginatedData,
                    ...brandIds.slice(
                      paginatedData.length,
                      paginatedData.length +
                        brandIds.length -
                        paginatedData.length
                    ),
                  ]);
                } else {
                  updatePaginatedData([
                    ...paginatedData,
                    ...brandIds.slice(
                      paginatedData.length,
                      paginatedData.length + 10
                    ),
                  ]);
                }
              }}
              // style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
              // inverse={true} //
              hasMore={
                !(paginatedData.length === Object.keys(brandState.data).length)
              }
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              {searchedPaginatedData?.map((brandId: any) => (
                <BrandAdAccountRow
                  key={brandId}
                  brandId={brandId}
                  brandName={brandState?.data[brandId]?.details?.name}
                  onClick={(e: any) =>
                    handleBrandClick(
                      e,
                      brandId,
                      brandState.data[brandId]?.details?.name
                    )
                  }
                  brandData={allBrands[brandId] ? allBrands[brandId] : null}
                  showOptimizer={showOptimizer}
                  setShowOptimizer={setShowOptimizer}
                  showPlatform={showPlatform}
                  setShowPlatform={setShowPlatform}
                  setEditBrandOpen={setEditBrandOpen}
                  activeTileKey={activeTileKey}
                />
              ))}
            </InfiniteScroll>
          </Box>
        )}
      </Box>
      {editBrandOpen && (
        <EditBrand
          setOpen={setEditBrandOpen}
          open={editBrandOpen}
          setSuccess={setSuccess}
        />
      )}
      {success && (
        <SuccessModel
          setOpen={setSuccess}
          open={success}
          heading={`The Brand Updated Successfully`}
          onSuccess={() => {
            setSuccess(false);
          }}
        />
      )}
    </Box>
  );
}

export default AdAccountSummary;

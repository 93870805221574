import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Button, Stack } from "@mui/material";
import SearchComponent from "../common/SearchComponent";
import MonthPicker from "./MonthPicker";
import { ReactComponent as Back } from "../assets/backIcon.svg";
import SelectText from "../common/SelectField";
import { setSelectedAgencySmb } from "../../../store/actions/SmbDashboard/agencies";
import {
  resetBrandMetrics,
  resetBrandDetails,
} from "../../../store/actions/SmbDashboard/brand";
import { setSelectedMonthYear } from "../../../store/actions/SmbDashboard/common";
import ManageAgencyButton from "Pages/ManageAgency/ManageAgencyButton";
import ButtonComponent from "../common/ButtonComponent";
import { mixPanelAdEvent } from "utils/mixpanel";
import last from "lodash/last";
import {
  getRolesHierarchy,
  isBrandAdminRole,
  getAdminRoleHierarchy,
} from "utils/commonFunctions";

function Header({ type, searchTerm, setSearchTerm, brandName }: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const agenciesState = useSelector((state: any) => state.smb.agencies);
  const commonState = useSelector((state: any) => state.smb.common);

  const brandState: any = useSelector((state: any) => state.smb.brand);
  const selectedBrandState = useSelector(
    (state: any) => state.smb.selectedBrand
  );
  const userState = useSelector((state: any) => state.userInfoReducer.userInfo);
  const adaccountState = useSelector((state: any) => state.adaccount);
  const { brandRoles } = adaccountState;

  const location = useLocation();

  const showManageAgencyButton = useMemo(() => {
    return (
      history.location.pathname.includes("/agency") &&
      getAdminRoleHierarchy(brandRoles) !== "brand"
    );
  }, [brandRoles]);

  return (
    <>
      <Box
        className="bg-white border d-flex align-items-center justify-content-between"
        sx={{
          padding: 2,
          margin: 2,
          borderRadius: 2,
          height: "9vh",
        }}
      >
        <Stack className="left" direction="row" spacing={2} alignItems="center">
          {type === "Agency" ? (
            <Typography variant="h6">Select Agency</Typography>
          ) : (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="text"
                style={{
                  background: "transparent",
                  minWidth: "fit-content",
                }}
                onClick={() => {
                  const params = new URLSearchParams(location.search);
                  const agencyId =
                    agenciesState?.selected.id || params.get("agency-id");
                  if (brandState && brandState.data) {
                    Object.keys(brandState.data).forEach((brandId: any) => {
                      dispatch(
                        resetBrandMetrics({
                          brandId,
                        })
                      );
                    });
                  }
                  history.push(
                    `/smb-dashboard/agency` + window.location.search
                  );
                }}
              >
                <Back />
              </Button>
              <Typography variant="h6">Brand Summary</Typography>
            </Box>
          )}
          {type === "Agency" ? (
            <SelectText
              selectedItem={agenciesState?.selected}
              setSelectedItem={(selectedAgency: any) => {
                dispatch(setSelectedAgencySmb(selectedAgency));
                const params = new URLSearchParams(location.search);

                const brandId =
                  selectedBrandState?.id || params.get("brand_id");
                history.push(
                  `?agency-id=${selectedAgency.id}&brand_id=${brandId}`
                );
              }}
              valueKey="id"
              displayKey="name"
              sx={{
                width: "8.25rem",
                height: "2.5rem",
                borderRadius: 2,
                fontWeight: 600,
                "&:hover": {
                  "&& fieldset": {
                    border: "1px solid #000",
                    opacity: 0.2,
                  },
                },
              }}
              size="small"
              items={
                agenciesState && agenciesState.data ? agenciesState.data : []
              }
            />
          ) : (
            <Box
              sx={{
                p: 1,
                borderRadius: "8px",
                border: "1px solid #E5E5E5",
                width: "16rem",
              }}
            >
              {brandName || "Loading..."}
            </Box>
          )}
        </Stack>
        <Stack
          className="right"
          direction="row"
          spacing={6}
          alignItems="center"
          justifyContent="end"
        >
          {history.location.pathname.includes("brand") && (
            <ButtonComponent
              variant="contained"
              label="Audit Manager"
              sx={{
                width: "10rem",
                background: "#0869FB",
                "&:hover": {
                  backgroundColor: "#0869FB",
                },
              }}
              onClick={() => {
                window.location.replace(
                  `${process.env.REACT_APP_BASE_URL_PIXIS2}/audit/reports?brandId=${adaccountState.selectedBrand.id}`
                );
              }}
            />
          )}
          {showManageAgencyButton && <ManageAgencyButton />}
          <SearchComponent
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            placeholder={`Search ${type === "Brand" ? "Ai Groups" : "Brands"}`}
          />
          <Box>
            <MonthPicker
              currentMonth={commonState.calendar.selectedMonth}
              currentYear={commonState.calendar.selectedYear}
              onChange={(selectedMonth: number, selectedYear: number) => {
                mixPanelAdEvent("SMB Month Clicked", {
                  agency_name: agenciesState?.selected?.name,
                  agency_id: agenciesState?.selected?.id,
                  dashboard_view: {
                    brand: "Brand Summary View",
                    agency: "Agency Summary View",
                    adAccount: "Ad Account Summary View",
                  }[last(location.pathname.split("/")) as string],
                });
                dispatch(
                  setSelectedMonthYear({
                    selectedMonth,
                    selectedYear,
                  })
                );
                if (type === "Agency") {
                  Object.keys(brandState.data).forEach((brandId: any) => {
                    dispatch(
                      resetBrandMetrics({
                        brandId,
                      })
                    );
                  });
                } else {
                  const params = new URLSearchParams(location.search);
                  const brandId =
                    selectedBrandState?.id || params.get("brand_id");
                  dispatch(
                    resetBrandMetrics({
                      brandId,
                    })
                  );
                }
              }}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default Header;
